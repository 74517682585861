export const typeOfEntity = [
  {
    _id: {
      $oid: "677cc6698d94966b664ea65c",
    },
    identifier: "eba_CT:x12",
    listIdentifier: "LIST0101040",
    titleIdentifier: "DESC0101040",
    option: "Credit institutions",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea65d",
    },
    identifier: "eba_CT:x599",
    listIdentifier: "LIST0101040",
    titleIdentifier: "DESC0101040",
    option: "Investment firms",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea65e",
    },
    identifier: "eba_CT:x643",
    listIdentifier: "LIST0101040",
    titleIdentifier: "DESC0101040",
    option: "Central counterparties (CCPs",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea65f",
    },
    identifier: "eba_CT:x639",
    listIdentifier: "LIST0101040",
    titleIdentifier: "DESC0101040",
    option: "Asset management companies",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea660",
    },
    identifier: "eba_CT:x301",
    listIdentifier: "LIST0101040",
    titleIdentifier: "DESC0101040",
    option: "account information service providers",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea661",
    },
    identifier: "eba_CT:x302",
    listIdentifier: "LIST0101040",
    titleIdentifier: "DESC0101040",
    option: "electronic money institutions",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea662",
    },
    identifier: "eba_CT:x303",
    listIdentifier: "LIST0101040",
    titleIdentifier: "DESC0101040",
    option: "crypto-asset service providers",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea663",
    },
    identifier: "eba_CT:x304",
    listIdentifier: "LIST0101040",
    titleIdentifier: "DESC0101040",
    option: "central security depository",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea664",
    },
    identifier: "eba_CT:x305",
    listIdentifier: "LIST0101040",
    titleIdentifier: "DESC0101040",
    option: "trading venues",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea665",
    },
    identifier: "eba_CT:x306",
    listIdentifier: "LIST0101040",
    titleIdentifier: "DESC0101040",
    option: "trade repositories",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea666",
    },
    identifier: "eba_CT:x300",
    listIdentifier: "LIST0101040",
    titleIdentifier: "DESC0101040",
    option: "payment institution",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea667",
    },
    identifier: "eba_CT:x316",
    listIdentifier: "LIST0101040",
    titleIdentifier: "DESC0101040",
    option: "other financial entity",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea668",
    },
    identifier: "eba_CT:x315",
    listIdentifier: "LIST0101040",
    titleIdentifier: "DESC0101040",
    option: "securitisation repository",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea669",
    },
    identifier: "eba_CT:x314",
    listIdentifier: "LIST0101040",
    titleIdentifier: "DESC0101040",
    option: "crowdfunding service providers",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea66a",
    },
    identifier: "eba_CT:x313",
    listIdentifier: "LIST0101040",
    titleIdentifier: "DESC0101040",
    option: "administrator of critical benchmarks",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea66b",
    },
    identifier: "eba_CT:x312",
    listIdentifier: "LIST0101040",
    titleIdentifier: "DESC0101040",
    option: "credit rating agency",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea66c",
    },
    identifier: "eba_CT:x311",
    listIdentifier: "LIST0101040",
    titleIdentifier: "DESC0101040",
    option: "institutions for occupational retirement provision",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea66d",
    },
    identifier: "eba_CT:x320",
    listIdentifier: "LIST0101040",
    titleIdentifier: "DESC0101040",
    option:
      "insurance intermediaries, reinsurance intermediaries and ancillary insurance intermediaries",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea66e",
    },
    identifier: "eba_CT:x309",
    listIdentifier: "LIST0101040",
    titleIdentifier: "DESC0101040",
    option: "insurance and reinsurance undertakings",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea66f",
    },
    identifier: "eba_CT:x308",
    listIdentifier: "LIST0101040",
    titleIdentifier: "DESC0101040",
    option: "data reporting service providers",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea670",
    },
    identifier: "eba_CT:x307",
    listIdentifier: "LIST0101040",
    titleIdentifier: "DESC0101040",
    option: "managers of alternative investment funds",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea671",
    },
    identifier: "eba_CT:x318",
    listIdentifier: "LIST0101040",
    titleIdentifier: "DESC0101040",
    option: "Non-financial entity: Other than ICT intra-group service provider",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea672",
    },
    identifier: "eba_CT:x317",
    listIdentifier: "LIST0101040",
    titleIdentifier: "DESC0101040",
    option: "Non-financial entity: ICT intra-group service provider",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea673",
    },
    identifier: "eba_CT:x310",
    listIdentifier: "LIST0101040",
    titleIdentifier: "DESC0101040",
    option: "issuers of asset-referenced tokens",
  },
];

export const typeOfCA = [
  {
    identifier: "eba_CO:x1",
    listIdentifier: "LIST0201020",
    titleIdentifier: "DESC0201020",
    option: "Standalone arrangement",
  },
  {
    identifier: "eba_CO:x2",
    listIdentifier: "LIST0201020",
    titleIdentifier: "DESC0201020",
    option: "Overarching arrangement",
  },
  {
    identifier: "eba_CO:x3",
    listIdentifier: "LIST0201020",
    titleIdentifier: "DESC0201020",
    option: "Subsequent or associated arrangement",
  },
];

export const typeOfICTServices = [
  {
    _id: {
      $oid: "677cc6698d94966b664ea647",
    },
    identifier: "eba_TA:S01",
    listIdentifier: "LISTANNEXIII",
    titleIdentifier: "DESCANNEXIII",
    option: "ICT project management",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea648",
    },
    identifier: "eba_TA:S02",
    listIdentifier: "LISTANNEXIII",
    titleIdentifier: "DESCANNEXIII",
    option: "ICT Development",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea649",
    },
    identifier: "eba_TA:S03",
    listIdentifier: "LISTANNEXIII",
    titleIdentifier: "DESCANNEXIII",
    option: "ICT help desk and first level support",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea64a",
    },
    identifier: "eba_TA:S04",
    listIdentifier: "LISTANNEXIII",
    titleIdentifier: "DESCANNEXIII",
    option: "ICT security management services",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea64b",
    },
    identifier: "eba_TA:S05",
    listIdentifier: "LISTANNEXIII",
    titleIdentifier: "DESCANNEXIII",
    option: "Provision of data",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea64c",
    },
    identifier: "eba_TA:S06",
    listIdentifier: "LISTANNEXIII",
    titleIdentifier: "DESCANNEXIII",
    option: "Data analysis",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea64d",
    },
    identifier: "eba_TA:S07",
    listIdentifier: "LISTANNEXIII",
    titleIdentifier: "DESCANNEXIII",
    option: "ICT, facilities and hosting services (excluding Cloud services)",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea64e",
    },
    identifier: "eba_TA:S08",
    listIdentifier: "LISTANNEXIII",
    titleIdentifier: "DESCANNEXIII",
    option: "Computation",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea64f",
    },
    identifier: "eba_TA:S09",
    listIdentifier: "LISTANNEXIII",
    titleIdentifier: "DESCANNEXIII",
    option: "Non-Cloud Data storage",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea650",
    },
    identifier: "eba_TA:S10",
    listIdentifier: "LISTANNEXIII",
    titleIdentifier: "DESCANNEXIII",
    option: "Telecom carrier",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea651",
    },
    identifier: "eba_TA:S11",
    listIdentifier: "LISTANNEXIII",
    titleIdentifier: "DESCANNEXIII",
    option: "Network infrastructure",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea652",
    },
    identifier: "eba_TA:S12",
    listIdentifier: "LISTANNEXIII",
    titleIdentifier: "DESCANNEXIII",
    option: "Hardware and physical devices",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea653",
    },
    identifier: "eba_TA:S13",
    listIdentifier: "LISTANNEXIII",
    titleIdentifier: "DESCANNEXIII",
    option: "Software licencing (excluding SaaS)",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea654",
    },
    identifier: "eba_TA:S14",
    listIdentifier: "LISTANNEXIII",
    titleIdentifier: "DESCANNEXIII",
    option: "ICT operation management (including maintenance)",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea655",
    },
    identifier: "eba_TA:S15",
    listIdentifier: "LISTANNEXIII",
    titleIdentifier: "DESCANNEXIII",
    option: "ICT Consulting",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea656",
    },
    identifier: "eba_TA:S16",
    listIdentifier: "LISTANNEXIII",
    titleIdentifier: "DESCANNEXIII",
    option: "ICT Risk management",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea657",
    },
    identifier: "eba_TA:S17",
    listIdentifier: "LISTANNEXIII",
    titleIdentifier: "DESCANNEXIII",
    option: "Cloud services: IaaS",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea658",
    },
    identifier: "eba_TA:S18",
    listIdentifier: "LISTANNEXIII",
    titleIdentifier: "DESCANNEXIII",
    option: "Cloud services: PaaS",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea659",
    },
    identifier: "eba_TA:S19",
    listIdentifier: "LISTANNEXIII",
    titleIdentifier: "DESCANNEXIII",
    option: "Cloud services: SaaS",
  },
];

export const reasonOfTermination = [
  {
    identifier: "eba_CO:x4",
    listIdentifier: "LIST0202090",
    titleIdentifier: "DESC0202090",
    option: "Termination not for cause: Expired and not renewd",
  },
  {
    identifier: "eba_CO:x5",
    listIdentifier: "LIST0202090",
    titleIdentifier: "DESC0202090",
    option:
      "Termination for cause: Provider in breach of applicable law, regulations or contractual provisions",
  },
  {
    identifier: "eba_CO:x6",
    listIdentifier: "LIST0202090",
    titleIdentifier: "DESC0202090",
    option:
      "Termination for cause: Identified impediments of the provider capable of altering the supported function",
  },
  {
    identifier: "eba_CO:x7",
    listIdentifier: "LIST0202090",
    titleIdentifier: "DESC0202090",
    option:
      "Termination for cause: provider's weaknesses regarding the management and security of sensitive data or information",
  },
  {
    identifier: "eba_CO:x8",
    listIdentifier: "LIST0202090",
    titleIdentifier: "DESC0202090",
    option: "Termination: as requested by the competent authority",
  },
  {
    identifier: "eba_CO:x9",
    listIdentifier: "LIST0202090",
    titleIdentifier: "DESC0202090",
    option: "Other reasons for termination",
  },
];

export const storageOfData = [
  {
    identifier: "eba_BT:x28",
    listIdentifier: "LISTBINARY",
    titleIdentifier: "DESCBINARY",
    option: "Yes",
  },
  {
    identifier: "eba_BT:x29",
    listIdentifier: "LISTBINARY",
    titleIdentifier: "DESCBINARY",
    option: "No",
  },
];

export const sensitivenessOfTheDataStored = [
  {
    identifier: "eba_ZZ:x791",
    listIdentifier: "LIST0202170",
    titleIdentifier: "DESC0202170",
    option: "Low",
  },
  {
    identifier: "eba_ZZ:x792",
    listIdentifier: "LIST0202170",
    titleIdentifier: "DESC0202170",
    option: "Medium",
  },
  {
    identifier: "eba_ZZ:x793",
    listIdentifier: "LIST0202170",
    titleIdentifier: "DESC0202170",
    option: "High",
  },
];

export const levelOfReliance = [
  {
    identifier: "eba_ZZ:x794",
    listIdentifier: "LIST0202180",
    titleIdentifier: "DESC02020180",
    option: "Not significant",
  },
  {
    identifier: "eba_ZZ:x795",
    listIdentifier: "LIST0202180",
    titleIdentifier: "DESC02020180",
    option: "Low reliance",
  },
  {
    identifier: "eba_ZZ:x796",
    listIdentifier: "LIST0202180",
    titleIdentifier: "DESC02020180",
    option: "Material reliance",
  },
  {
    identifier: "eba_ZZ:x797",
    listIdentifier: "LIST0202180",
    titleIdentifier: "DESC02020180",
    option: "Full reliance",
  },
];

export const licensedActivity = [
  {
    _id: {
      $oid: "677cc6698d94966b664ea68d",
    },
    identifier: "eba_TA:x185",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Non-Life Insurance: Classes 1 and 2: ‘Accident and Health Insurance’",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea68e",
    },
    identifier: "eba_TA:x186",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Non-Life Insurance: Classes 1 (fourth indent), 3, 7 and 10: ‘Motor Insurance’",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea68f",
    },
    identifier: "eba_TA:x187",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Non-Life Insurance: Classes 1 (fourth indent), 4, 6, 7 and 12: ‘Marine and Transport Insurance’",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea690",
    },
    identifier: "eba_TA:x188",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Non-Life Insurance: Classes 1 (fourth indent), 5, 7 and 11: ‘Aviation Insurance’",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea691",
    },
    identifier: "eba_TA:x189",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Non-Life Insurance: Classes 8 and 9: ‘Insurance against Fire and other Damage to Property’",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea692",
    },
    identifier: "eba_TA:x190",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Non-Life Insurance: Classes 10, 11, 12 and 13: ‘Liability Insurance’",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea693",
    },
    identifier: "eba_TA:x191",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Non-Life Insurance: Classes 14 and 15: ‘Credit and Suretyship Insurance’",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea694",
    },
    identifier: "eba_TA:x192",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Non-Life Insurance: All classes, at the choice of the Member States, which shall notify the other Member States and the Commission of their choice",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea695",
    },
    identifier: "eba_TA:x193",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Life Insurance: The life insurance referred to in points (a)(i), (ii) and (iii) of Article 2(3) excluding those referred to in II and III",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea696",
    },
    identifier: "eba_TA:x194",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Life Insurance: Marriage assurance, birth assurance",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea697",
    },
    identifier: "eba_TA:x195",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Life Insurance: The insurance referred to in points (a)(i) and (ii) of Article 2(3), which are linked to investment funds",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea698",
    },
    identifier: "eba_TA:x196",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Life Insurance: Permanent health insurance, referred to in point (a)(iv) of Article 2(3)",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea699",
    },
    identifier: "eba_TA:x197",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Life Insurance: Tontines, referred to in point (b)(i) of Article 2(3)",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea69a",
    },
    identifier: "eba_TA:x198",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Life Insurance: Capital redemption operations, referred to in point (b)(ii) of Article 2(3)",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea69b",
    },
    identifier: "eba_TA:x199",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Life Insurance: Management of group pension funds, referred to in point (b)(iii) and (iv) of Article 2(3)",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea69c",
    },
    identifier: "eba_TA:x200",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Life Insurance: The operations referred to in point (b)(v) of Article 2(3)",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea69d",
    },
    identifier: "eba_TA:x201",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Life Insurance: The operations referred to in Article 2(3)(c) ",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea69e",
    },
    identifier: "eba_TA:x163",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Lending activities",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea69f",
    },
    identifier: "eba_TA:x164",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Financial leasing",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6a0",
    },
    identifier: "eba_TA:x165",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Issuing and administering other means of payment",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6a1",
    },
    identifier: "eba_TA:x166",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Guarantees and commitments",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6a2",
    },
    identifier: "eba_TA:x167",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Guarantees and commitments related to securities lending and borrowing, within the meaning of point 6 of Annex I to Directive 2013/36/EU",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6a3",
    },
    identifier: "eba_TA:x168",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Trading for own account or for account of customers",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6a4",
    },
    identifier: "eba_TA:x169",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Participation in securities issues and the provision of services relating to such issues ",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6a5",
    },
    identifier: "eba_TA:x28",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Payment services",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6a6",
    },
    identifier: "eba_TA:x170",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Advisory services",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6a7",
    },
    identifier: "eba_TA:x171",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Money broking",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6a8",
    },
    identifier: "eba_TA:x172",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Portfolio management and advice",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6a9",
    },
    identifier: "eba_TA:x173",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Dealing on own account",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6aa",
    },
    identifier: "eba_TA:x174",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Safekeeping and administration of securities",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6ab",
    },
    identifier: "eba_TA:x175",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Safekeeping and administration of financial instruments for the account of clients",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6ac",
    },
    identifier: "eba_TA:x176",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "safe-keeping and administration in relation to shares or units of collective investment undertakings",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6ad",
    },
    identifier: "eba_TA:x177",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "non-core services (safekeeping and administration in relation to units of collective investment undertakings)",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6ae",
    },
    identifier: "eba_TA:x178",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Safe custody services",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6af",
    },
    identifier: "eba_TA:x179",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Credit reference services",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6b0",
    },
    identifier: "eba_TA:x180",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Issuing electronic money",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6b1",
    },
    identifier: "eba_TA:x181",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "reception and transmission of orders for crypto-assets on behalf of clients",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6b2",
    },
    identifier: "eba_TA:x182",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Portfolio management on crypto-assets",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6b3",
    },
    identifier: "eba_TA:x183",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "management of portfolios of investments (AIFMD)",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6b4",
    },
    identifier: "eba_TA:x184",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "management of portfolios of investments (UCITSD)",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6b5",
    },
    identifier: "eba_TA:x202",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "insurance distribution",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6b6",
    },
    identifier: "eba_TA:x203",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "reinsurance distribution",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6b7",
    },
    identifier: "eba_TA:x204",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Investment services related to the underlying of the derivatives",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6b8",
    },
    identifier: "eba_TA:x205",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Retirement-benefit related operations and activities arising therefrom",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6b9",
    },
    identifier: "eba_TA:x206",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "issuance of credit ratings",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6ba",
    },
    identifier: "eba_TA:x207",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "administering the arrangements for determining a benchmark",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6bb",
    },
    identifier: "eba_TA:x208",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "collecting, analysing or processing input data for the purpose of determining a benchmark",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6bc",
    },
    identifier: "eba_TA:x209",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "determining a benchmark through the application of a formula or other method of calculation or by an assessment of input data provided for that purpose",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6bd",
    },
    identifier: "eba_TA:x210",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "publication of benchmark",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6be",
    },
    identifier: "eba_TA:x211",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Provision of crowdfunding services",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6bf",
    },
    identifier: "eba_TA:x212",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "ancillary non-securitisation services",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6c0",
    },
    identifier: "eba_TA:x213",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "ancillary securitisation services",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6c1",
    },
    identifier: "eba_TA:x214",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "collection and maintenance of the records of derivatives (non-SFTs)",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6c2",
    },
    identifier: "eba_TA:x215",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "collection and maintenance of the records of SFTs",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6c3",
    },
    identifier: "eba_TA:x216",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "collection and maintenance of the records of securitisations",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6c4",
    },
    identifier: "eba_TA:x217",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "activity as approved publication arrangement",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6c5",
    },
    identifier: "eba_TA:x218",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "activity as consolidated tape provider",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6c6",
    },
    identifier: "eba_TA:x219",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "activity as approved reporting mechanism",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6c7",
    },
    identifier: "eba_TA:x220",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Services enabling cash to be placed on a payment account as well as all the operations required for operating a payment account ",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6c8",
    },
    identifier: "eba_TA:x221",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Services enabling cash withdrawals from a payment account as well as all the operations required for operating a payment account ",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6c9",
    },
    identifier: "eba_TA:x222",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Execution of payment transactions, including transfers of funds on a payment account with the user’s payment service provider or with another payment service provider",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6ca",
    },
    identifier: "eba_TA:x223",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Execution of payment transactions where the funds are covered by a credit line for a payment service user",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6cb",
    },
    identifier: "eba_TA:x224",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Issuing of payment instruments and/or acquiring of payment transactions ",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6cc",
    },
    identifier: "eba_TA:x225",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Money remittance ",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6cd",
    },
    identifier: "eba_TA:x226",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Payment initiation services ",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6ce",
    },
    identifier: "eba_TA:x227",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Account information services",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6cf",
    },
    identifier: "eba_TA:x228",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Providing custody and administration of crypto-assets on behalf of clients",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6d0",
    },
    identifier: "eba_TA:x229",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "operation of a trading platform for crypto-assets",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6d1",
    },
    identifier: "eba_TA:x230",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Operation of a Regulated Market",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea6d2",
    },
    identifier: "eba_TA:x231",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "exchange of crypto-assets for funds",
  },
  {
    identifier: "eba_TA:x232",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "exchange of crypto-assets for other crypto-assets",
  },
  {
    identifier: "eba_TA:x233",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "execution of orders for crypto-assets on behalf of clients",
  },
  {
    identifier: "eba_TA:x234",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "placing of crypto-assets",
  },
  {
    identifier: "eba_TA:x235",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "providing advice on crypto-assets",
  },
  {
    identifier: "eba_TA:x236",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "providing transfer services for crypto-assets on behalf of clients",
  },
  {
    identifier: "eba_TA:x237",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "issuance of asset-referenced tokens",
  },
  {
    identifier: "eba_TA:x238",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "notary service",
  },
  {
    identifier: "eba_TA:x239",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "central maintenance service",
  },
  {
    identifier: "eba_TA:x240",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "settlement service",
  },
  {
    identifier: "eba_TA:x241",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Organising a securities lending mechanism, as agent among participants of a securities settlement system",
  },
  {
    identifier: "eba_TA:x242",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "collateral management services",
  },
  {
    identifier: "eba_TA:x243",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "general collateral management services ",
  },
  {
    identifier: "eba_TA:x244",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Establishing CSD links, providing, maintaining or operating securities accounts in relation to the settlement service, collateral management, other ancillary services",
  },
  {
    identifier: "eba_TA:x245",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Settlement matching, instruction routing, trade confirmation, trade verification",
  },
  {
    identifier: "eba_TA:x246",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Services related to shareholders’ registers",
  },
  {
    identifier: "eba_TA:x247",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Supporting the processing of corporate actions, including tax, general meetings and information services",
  },
  {
    identifier: "eba_TA:x248",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "New issue services, including allocation and management of ISIN codes and similar codes",
  },
  {
    identifier: "eba_TA:x249",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Instruction routing and processing, fee collection and processing and related reporting",
  },
  {
    identifier: "eba_TA:x250",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Providing regulatory reporting",
  },
  {
    identifier: "eba_TA:x251",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Providing information, data and statistics to market/census bureaus or other governmental or inter-governmental entities",
  },
  {
    identifier: "eba_TA:x252",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Providing IT services",
  },
  {
    identifier: "eba_TA:x253",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Providing cash accounts to, and accepting deposits from, participants in a securities settlement system and holders of securities accounts, within the meaning of point 1 of Annex I to Directive 2013/36/EU",
  },
  {
    identifier: "eba_TA:x254",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Providing cash credit for reimbursement no later than the following business day, cash lending to pre-finance corporate actions and lending securities to holders of securities accounts, within the meaning of point 2 of Annex I to Directive 2013/36/EU",
  },
  {
    identifier: "eba_TA:x255",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      " Payment services involving processing of cash and foreign exchange transactions, within the meaning of point 4 of Annex I to Directive 2013/36/EU",
  },
  {
    identifier: "eba_TA:x256",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Treasury activities involving foreign exchange and transferable securities related to managing participants’ long balances",
  },
  {
    identifier: "eba_TA:x257",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Any other NCA-permitted non-banking-type ancillary services not specified in Annex of Regulation (EU) No 909/2014 (CSDR) - Section B",
  },
  {
    identifier: "eba_TA:x258",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Any other NCA-permitted Banking-type ancillary services not specified in Annex of Regulation (EU) No 909/2014 (CSDR) - Section C",
  },
  {
    identifier: "eba_TA:x259",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "interposition between counterparties",
  },
  {
    identifier: "eba_TA:x260",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "risk management",
  },
  {
    identifier: "eba_TA:x261",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "legal and fund management accounting services",
  },
  {
    identifier: "eba_TA:x262",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "customer inquiries",
  },
  {
    identifier: "eba_TA:x263",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "valuation and pricing, including tax returns",
  },
  {
    identifier: "eba_TA:x264",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "regulatory compliance monitoring",
  },
  {
    identifier: "eba_TA:x265",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "maintenance of unit-/shareholder register",
  },
  {
    identifier: "eba_TA:x266",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "unit/shares issues and redemptions",
  },
  {
    identifier: "eba_TA:x267",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "maintenance of unit-holder register",
  },
  {
    identifier: "eba_TA:x268",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "unit issues and redemptions",
  },
  {
    identifier: "eba_TA:x269",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "contract settlements (including certificate dispatch)",
  },
  {
    identifier: "eba_TA:x270",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "distribution of income",
  },
  {
    identifier: "eba_TA:x271",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "record keeping",
  },
  {
    identifier: "eba_TA:x272",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Marketing",
  },
  {
    identifier: "eba_TA:x273",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "services necessary to meet the fiduciary duties of the AIFM",
  },
  {
    identifier: "eba_TA:x274",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "investment advice concerning one or more of the instruments listed in Annex I, Section C to Directive 2004/39/EC",
  },
  {
    identifier: "eba_TA:x275",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Ancillary services",
  },
  {
    identifier: "eba_TA:x104",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Foreign exchange services",
  },
  {
    identifier: "eba_TA:x133",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Reception and transmission of orders",
  },
  {
    identifier: "eba_TA:x134",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Execution of orders on behalf of clients",
  },
  {
    identifier: "eba_TA:x136",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Portfolio management",
  },
  {
    identifier: "eba_TA:x137",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Investment advice",
  },
  {
    identifier: "eba_TA:x138",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Underwriting of financial instruments and/or placing of financial instruments on a firm commitment basis",
  },
  {
    identifier: "eba_TA:x139",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Placing of financial instruments without a firm commitment basis",
  },
  {
    identifier: "eba_TA:x140",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Operation of an MTF",
  },
  {
    identifier: "eba_TA:x141",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Operation of an OTF",
  },
  {
    identifier: "eba_TA:x143",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Granting credits or loans to investors",
  },
  {
    identifier: "eba_TA:x144",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option:
      "Advice to undertakings on capital structure, industrial strategy and related matters and advice and services relating to mergers and the purchase of undertakings",
  },
  {
    identifier: "eba_TA:x146",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Investment research and financial analysis",
  },
  {
    identifier: "eba_TA:x147",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Services related to underwriting",
  },
  {
    identifier: "eba_TA:x162",
    listIdentifier: "LIST0601020",
    titleIdentifier: "DESC0601020",
    option: "Taking deposits and other repayable funds",
  },
];

export const criticalityOrImportanceAssessment = [
  {
    identifier: "eba_BT:x28",
    listIdentifier: "LIST0601050",
    titleIdentifier: "DESC0601050",
    option: "Yes",
  },
  {
    identifier: "eba_BT:x29",
    listIdentifier: "LIST0601050",
    titleIdentifier: "DESC0601050",
    option: "No",
  },
  {
    identifier: "eba_BT:x21",
    listIdentifier: "LIST0601050",
    titleIdentifier: "DESC0601050",
    option: "Assessment not performed",
  },
];

export const impactOfDiscontinuingTheFunction = [
  {
    identifier: "eba_ZZ:x791",
    listIdentifier: "LIST0601100",
    titleIdentifier: "DESC0601100",
    option: "Low",
  },
  {
    identifier: "eba_ZZ:x792",
    listIdentifier: "LIST0601100",
    titleIdentifier: "DESC0601100",
    option: "Medium",
  },
  {
    identifier: "eba_ZZ:x793",
    listIdentifier: "LIST0601100",
    titleIdentifier: "DESC0601100",
    option: "High",
  },
  {
    identifier: "eba_ZZ:x799",
    listIdentifier: "LIST0601100",
    titleIdentifier: "DESC0601100",
    option: "Assessment not performed",
  },
];

export const hierarchyOfTheEntity = [
  {
    _id: {
      $oid: "677cc6698d94966b664ea674",
    },
    identifier: "eba_RP:x53",
    listIdentifier: "LIST0102050",
    titleIdentifier: "DESC0102050",
    option: "Ultimate parent",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea675",
    },
    identifier: "eba_RP:x551",
    listIdentifier: "LIST0102050",
    titleIdentifier: "DESC0102050",
    option: "Parent other than ultimate parent",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea676",
    },
    identifier: "eba_RP:x56",
    listIdentifier: "LIST0102050",
    titleIdentifier: "DESC0102050",
    option: "Subsidiary",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea677",
    },
    identifier: "eba_RP:x21",
    listIdentifier: "LIST0102050",
    titleIdentifier: "DESC0102050",
    option: "Entities other than entities of the group",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea678",
    },
    identifier: "eba_RP:x210",
    listIdentifier: "LIST0102050",
    titleIdentifier: "DESC0102050",
    option: "Outsourcing",
  },
];

export const typeOfPersonOfTheThirdPartyServiceProvider = [
  {
    _id: {
      $oid: "677cc6698d94966b664ea68b",
    },
    identifier: "eba_CT:x212",
    listIdentifier: "LIST0501040",
    titleIdentifier: "DESC0501040",
    option: "Legal person, excluding individual acting in a business capacity",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea68c",
    },
    identifier: "eba_CT:x213",
    listIdentifier: "LIST0501040",
    titleIdentifier: "DESC0501040",
    option: "Individual acting in a business capacity",
  },
];

export const natureOfTheEntity = [
  {
    _id: {
      $oid: "677cc6698d94966b664ea689",
    },
    identifier: "eba_ZZ:x838",
    listIdentifier: "LIST0401030",
    titleIdentifier: "DESC0401030",
    option: "branch of a financial entity",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea68a",
    },
    identifier: "eba_ZZ:x839",
    listIdentifier: "LIST0401030",
    titleIdentifier: "DESC0401030",
    option: "not a branch",
  },
];

export const substitutabilityOfTheICT = [
  {
    _id: {
      $oid: "677cc6698d94966b664ea714",
    },
    identifier: "eba_ZZ:x959",
    listIdentifier: "LIST0701050",
    titleIdentifier: "DESC0701050",
    option: "Not substitutable",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea715",
    },
    identifier: "eba_ZZ:x962",
    listIdentifier: "LIST0701050",
    titleIdentifier: "DESC0701050",
    option: "Easily substitutable",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea716",
    },
    identifier: "eba_ZZ:x961",
    listIdentifier: "LIST0701050",
    titleIdentifier: "DESC0701050",
    option: "Medium complexity in terms of substitutability",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea717",
    },
    identifier: "eba_ZZ:x960",
    listIdentifier: "LIST0701050",
    titleIdentifier: "DESC0701050",
    option: "Highly complex substitutability",
  },
];

export const reasonIfTheICTNotSubstitutable = [
  {
    _id: {
      $oid: "677cc6698d94966b664ea718",
    },
    identifier: "eba_ZZ:x963",
    listIdentifier: "LIST0701060",
    titleIdentifier: "DESC0701060",
    option: "Lack of real alternatives",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea719",
    },
    identifier: "eba_ZZ:x964",
    listIdentifier: "LIST0701060",
    titleIdentifier: "DESC0701060",
    option: "Difficulties in migrating or reintegrating",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea71a",
    },
    identifier: "eba_ZZ:x965",
    listIdentifier: "LIST0701060",
    titleIdentifier: "DESC0701060",
    option:
      "Lack of real alternatives and difficulties in migrating or reintegrating",
  },
];

export const possibilityOfReintegration = [
  {
    _id: {
      $oid: "677cc6698d94966b664ea71b",
    },
    identifier: "eba_ZZ:x798",
    listIdentifier: "LIST0701090",
    titleIdentifier: "DESC0701090",
    option: "Easy",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea71c",
    },
    identifier: "eba_ZZ:x966",
    listIdentifier: "LIST0701090",
    titleIdentifier: "DESC0701090",
    option: "Difficult",
  },
  {
    _id: {
      $oid: "677cc6698d94966b664ea71d",
    },
    identifier: "eba_ZZ:x967",
    listIdentifier: "LIST0701090",
    titleIdentifier: "DESC0701090",
    option: "Highly complex",
  },
];
