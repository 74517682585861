import { Box, Button, IconButton } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import DynamicTableFull from "components/UI/dynamicTable/DynamicTableFull";
import Stack from "components/UI/Stack/Stack";
import SearchTextField from "components/UI/textfield/searchTextField";
import EntityDrawer from "components/widgets/v2/OrgDetails/EntityDrawer";
import FunctionRegistrationDrawer from "components/widgets/v2/OrgDetails/FunctionRegistrationDrawer";
import { usePaginationSearch } from "customHooks/usePaginationSearch";
import { useEffect } from "react";
import { FiDownload, FiPlus, FiUpload } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";
import { useSelector } from "react-redux";
import { gql_get_roi_functions } from "redux/actions/vendorActions/vendorAction";
import { gql_get_entity_list } from "redux/actions/vendorActions/vendorOrgAction";

const listHead = [
  { name: "Function Name" },
  { name: "Function Identifier" },
  { name: "Criticality" },
  { name: "Last Assessment Date" },
  { name: "Impact of Discontinuing" },
  { name: "LEI of the Financial Entity" },
  { name: "Licenced Activity" },
  { name: "Reasons for Criticality" },
  { name: "Recovery Point" },
  { name: "Recovery Time" },
  //   { name: "Recovery Time" },
];

const rowOrder = [
  { type: "raw", data: "Function_name" },
  { type: "raw", data: "Function_identifier" },
  { type: "raw", data: "Criticality_or_importance_assessment.option" },
  {
    type: "raw",
    data: "Date_of_the_last_assessment_of_criticality_or_importance",
  },
  { type: "raw", data: "Impact_of_discontinuing_the_function.option" },
  { type: "raw", data: "LEI_of_the_financial_entity" },
  { type: "raw", data: "Licensed_activity.option" },
  { type: "raw", data: "Reasons_for_criticality_or_importance" },
  { type: "raw", data: "Recovery_point_objective_of_the_function" },
  { type: "raw", data: "Recovery_time_objective_of_the_function" },
  //   {
  //     type: "comp",
  //     data: (
  //       <IconButton
  //         aria-label="CustomAction"
  //         size="small"
  //         color="primary"
  //         onClick={(row) => console.log("Row data:", row)}
  //       >
  //         <IoIosArrowForward />
  //       </IconButton>
  //     ),
  //   },
];

const FunctionRegistration = () => {
  const {
    // states and data
    openDrawer,
    keyword,
    page,
    limit,
    // funcs
    toggleDrawer,
    handleSearch,
    handlePageChange,
  } = usePaginationSearch();

  const { roiFunctions } = useSelector((state) => state.brmData);
  const { bankId } = useSelector((state) => state?.login);

  useEffect(() => {
    gql_get_roi_functions(bankId);
  }, []);

  return (
    <Box position={"relative"} height={"70vh"}>
      {/* Search and Buttons */}
      <Stack direction="row" spacing={10} justifyContent="flex-end" mb={2}>
        <SearchTextField
          searchClick={handleSearch}
          style={{ minWidth: "30em" }}
        />
        {/* <Button color="primary" startIcon={<FiDownload />}>
          Download Sheet
        </Button>
        <Button color="primary" startIcon={<FiUpload />}>
          Upload Sheet
        </Button> */}
        <Button
          variant="contained"
          color="primary"
          startIcon={<FiPlus />}
          onClick={toggleDrawer}
        >
          Add Function
        </Button>
      </Stack>

      {/* Entry List Table */}
      <DynamicTableFull
        tableHead={listHead}
        tableData={roiFunctions}
        tableRowOrder={rowOrder}
        maxHeight="50vh"
      />

      <Stack mt={5} justifyContent="flex-end">
        <Pagination
          // count={Math.ceil(entity_list?.total_count / limit)}
          count={1}
          variant="outlined"
          color="primary"
          page={page}
          onChange={handlePageChange}
        />
      </Stack>

      {/* Drawer */}

      <FunctionRegistrationDrawer open={openDrawer} onClose={toggleDrawer} />
    </Box>
  );
};
export default FunctionRegistration;
