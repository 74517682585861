import { useState } from "react";
import ROILeft from "./ROILeft";
import ROIRight from "./ROIRight";
import Stack from "components/UI/Stack/Stack";
import { CardWrapper } from "components/widgets/riskAssessment/brmDataLeft/brmDataLeft";
import NoData from "components/widgets/NoData/NoData";

const ROIScreens = () => {
  const [selectedROI, setSelectedROI] = useState(null);

  return (
    <Stack spacing={20}>
      <CardWrapper style={{ width: "20%", height: "70vh" }}>
        <ROILeft selectedROI={selectedROI} setSelectedROI={setSelectedROI} />
      </CardWrapper>

      <CardWrapper style={{ width: "80%", height: "70vh" }}>
        {/* If selectedROI is not available */}
        {!selectedROI ? (
          <NoData height="60vh" message={"Please select an ROI"} />
        ) : (
          <ROIRight selectedROI={selectedROI} />
        )}
      </CardWrapper>
    </Stack>
  );
};
export default ROIScreens;
