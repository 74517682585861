import React from "react";
import { Page, Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    padding: 10,
    fontFamily: "Helvetica",
  },
  section: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 20,
    gap: 10,
  },
  column: {
    flex: 1,
    // marginHorizontal: 10,
    // border: "1px solid #ccc",
    borderRadius: 5,
    padding: 5,
    backgroundColor: "#f9f9f9",
  },
  header: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    backgroundColor: "#e9f1ff",
    padding: 5,
    marginBottom: 10,
  },
  score: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#222",
    textAlign: "center",
  },
  scoreDescription: {
    fontSize: 8,
    textAlign: "center",
    marginBottom: 10,
  },
  table: {
    marginTop: 10,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid #ddd",
    padding: 5,
  },
  tableCell: {
    // flex: 1,
    fontSize: 8,
  },
  tableCellLabel: {
    fontWeight: "bold",
    width: "120px",
  },

  mainHeader: {
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    // backgroundColor: "#e9f1ff",
    padding: 5,
    marginBottom: 10,
  },
});

function VendorIntakePdf({ vendorMaterialityScores }) {
  console.log(vendorMaterialityScores);

  const nameFormat = (name) => {
    if (name === "vendorCategorization") {
      return "Vendor Categorization";
    } else if (name === "vendorMateriality") {
      return "Vendor Materiality";
    } else if (name === "vendorPerformance") {
      return "Vendor Performance";
    }
  };

  const defaultData = {
    vendorCategorization: {
      comment: "",
      score: 0,
    },
    vendorMateriality: {
      comment: "",
      score: 0,
    },
    vendorPerformance: {
      comment: "",
      score: 0,
    },
  };

  const scoresDefaultData = {
    vendorCategorization: {
      "Importance and Impact of Vendor Services": 0,
      "Financial and Operational Impact": 0,
      "Risk of Service Disruption": 0,
      "Cost Considerations": 0,
      "Vendor Alternatives": 0,
      "Compliance and Contractual Agreements": 0,
    },
    vendorMateriality: {
      Customer: 0,
      Regulatory: 0,
      Exposure: 0,
      Concentration: 0,
      "Brand Reputation": 0,
      "Criticality  (over-riding factors)": 0,
    },
    vendorPerformance: {
      "Adherence to Bank's Instructions": 0,
      "Vendor Performance Review": 0,
      "Vendor Service Evaluation": 0,
      "Technical / Product Support": 0,
      "Compliance with On Boarding Terms & Conditions": 0,
    },
  };
  return (
    <Page style={styles.page}>
      <View>
        <Text style={styles.mainHeader}>Vendor Intake/Scoping</Text>
      </View>

      <View>
        <Text style={styles.header}>Overall Score</Text>
      </View>

      <View style={styles.section}>
        {Object?.entries(vendorMaterialityScores ?? defaultData)
          ?.filter(([key, value]) => key !== "__typename")
          ?.map(([key, value], index) => (
            <View style={styles.column}>
              <Text style={styles.header}>{nameFormat(key)}</Text>
              <Text style={styles.score}>
                {!!value?.score ? value?.score : "-"} /100
              </Text>
              <Text style={styles.scoreDescription}>
                The score reflects the vendor's performance and can guide them
                in making informed decisions.
              </Text>
              <View style={styles.table}>
                {(value?.sectionWiseScore || scoresDefaultData) &&
                  Object.entries(
                    value?.sectionWiseScore ?? scoresDefaultData[key]
                  )?.map(([section, score], i) => (
                    <View style={styles.tableRow}>
                      <Text style={[styles.tableCell, styles.tableCellLabel]}>
                        {section}
                      </Text>
                      <Text style={styles.tableCell}>{score}</Text>
                    </View>
                  ))}

                {/* Add more rows as needed */}
              </View>
            </View>
          ))}
      </View>
    </Page>
  );
}

export default VendorIntakePdf;
