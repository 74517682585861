import { Box, Typography } from "@material-ui/core";
import { SET_LINKAGE_SURVEY_SECTIONS } from "constants/brmConstants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_linkage_survey_sections,
  gql_get_linkage_surveys,
} from "redux/actions/vendorActions/vendorAction";

const ROILeft = ({ selectedROI, setSelectedROI }) => {
  const dispatch = useDispatch();

  const { linkageSurveys, linkageSurveySections } = useSelector(
    (state) => state?.brmData
  );

  const surveyId = linkageSurveys?.find((survey) =>
    survey?.name?.toLowerCase()?.includes("register of information")
  )?._id;

  useEffect(() => {
    if (surveyId) {
      dispatch(gql_get_linkage_survey_sections(surveyId));
    } else {
      dispatch(gql_get_linkage_surveys());
    }

    return () => {
      dispatch({ type: SET_LINKAGE_SURVEY_SECTIONS, payload: [] });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyId]);

  return (
    <div className="scroll" style={{ marginTop: 0, height: "62.5vh" }}>
      {linkageSurveySections?.map((item) => (
        <Box
          key={item._id}
          onClick={() => setSelectedROI(item)}
          style={{
            padding: "12px 16px",
            margin: "4px 0",
            cursor: "pointer",
            borderRadius: "4px",
            backgroundColor:
              selectedROI?._id === item._id ? "#EBF1F8" : "transparent",
          }}
        >
          <Typography
            style={{
              fontWeight: selectedROI?._id === item._id ? "bold" : "normal",
            }}
          >
            {item.title}
          </Typography>
        </Box>
      ))}
    </div>
  );
};
export default ROILeft;
