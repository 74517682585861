import React, { useEffect } from "react";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Button,
  Box,
  Chip,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import CustomButton from "../../../UI/button/button";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_active_contract_file_data,
  gql_get_dora_compliance_files_for_contracting,
  gql_set_contract_file_as_active,
} from "../../../../redux/actions/esgDiagnosisActions/esgDiagnosisActions";
import "components/widgets/tprmRisk/trpmRiskReport/print.css";

const complianceData = [
  // {
  //   status: "Partially Compliant",
  //   title: "GDPR Compliance",
  //   subtitle: "Digital Operational Resilience Act Compliance",
  //   assessmentStatus: "Awaiting final risk assessment report",
  //   documents: [
  //     {
  //       title: "Incident Response Procedure",
  //       status: "Compliant",
  //       viewLink: "/view/incident-response-procedure",
  //     },
  //     {
  //       title: "Operational Risk Management Plan",
  //       status: "Non Compliant",
  //       viewLink: "/view/operational-risk-management-plan",
  //     },
  //     {
  //       title: "Third-Party Risk Management Report",
  //       status: "Compliant",
  //       viewLink: "/view/third-party-risk-management-report",
  //     },
  //   ],
  // },
  {
    status: "Partially Compliant",
    title: "Incident Policy",
    subtitle: "Digital Operational Resilience Act Compliance",
    assessmentStatus: "Awaiting final risk assessment report",
    documents: [
      {
        title: "Incident Response Procedure",
        status: "Compliant",
        viewLink: "/view/incident-response-procedure",
      },
      {
        title: "Operational Risk Management Plan",
        status: "Non Compliant",
        viewLink: "/view/operational-risk-management-plan",
      },
      {
        title: "Third-Party Risk Management Report",
        status: "Compliant",
        viewLink: "/view/third-party-risk-management-report",
      },
    ],
  },
  {
    status: "Non Compliant",
    title: "TSPS Policy",
    subtitle: "Digital Operational Resilience Act Compliance",
    assessmentStatus: "Awaiting final risk assessment report",
  },
  {
    status: "Compliant",
    title: "IS Policy",
    subtitle: "Digital Operational Resilience Act Compliance",
    assessmentStatus: "Awaiting final risk assessment report",
  },
];

const ComplianceList = ({
  handleSelectedDoc,
  type,
  complianceFiles,
  doraOverallScores,
}) => {
  const dispatch = useDispatch();
  const { vendorBasicDetails } = useSelector((state) => state?.brmData);

  const storedVendorIsin = localStorage.getItem("vendorIsin");

  const siverity = (type, status) => {
    if (type === "bg") {
      if (status === "Partially Compliant") {
        return "#ffeeba";
      } else if (status === "Compliant" || status === "Pass") {
        return "#BFE2CD";
      } else {
        return "#EDCAC6";
      }
    } else {
      if (status === "Partially Compliant") {
        return "#856404";
      } else if (status === "Compliant" || status === "Pass") {
        return "#154328";
      } else {
        return "#52231D";
      }
    }
  };

  const nameFormat = (name) => {
    if (name === "incidentPolicy") {
      return "Incident Policy";
    } else if (name === "tspsPolicy") {
      return "TPSP Policy";
    } else if (name === "isPolicy") {
      return "IS Policy";
    } else if (name === "contractFiles") {
      return "Contract";
    }
  };

  const status = {
    incidentPolicy: {
      status: doraOverallScores?.incidentPolicyComment,
    },
    tspsPolicy: {
      status: doraOverallScores?.tpspPolicyComment,
    },
    isPolicy: {
      status: doraOverallScores?.isPolicyComment,
    },
    contractFiles: {
      status: doraOverallScores?.contractComment,
    },
  };

  const handleActiveFile = async (docId) => {
    await dispatch(
      gql_set_contract_file_as_active(docId, vendorBasicDetails?.isin)
    );

    dispatch(
      gql_get_dora_compliance_files_for_contracting(
        vendorBasicDetails?.isin || storedVendorIsin,
        type
      )
    );

    dispatch(
      gql_get_active_contract_file_data(
        vendorBasicDetails?.isin || storedVendorIsin,
        type
      )
    );
  };
  return (
    <Box className="hide-on-print">
      <Typography
        variant="body2"
        style={{ fontWeight: 600, marginBottom: "10px", marginLeft: "10px" }}
      >
        Compliance List
      </Typography>

      {complianceFiles &&
        Object.entries(complianceFiles ?? {})?.map(([key, value]) => (
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Box>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 600,
                      marginBottom: "10px",
                    }}
                  >
                    {nameFormat(key)}
                    <Chip
                      label={status[key]?.status}
                      style={{
                        backgroundColor: siverity("bg", status[key]?.status),
                        color: siverity("text", status[key]?.status),
                        marginLeft: "10px",
                      }}
                      size="small"
                    />
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      color: "#999",
                    }}
                  >
                    {/* {item?.subtitle} */}
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  style={{
                    color: "#999",
                  }}
                >
                  {/* {item?.assessmentStatus} */}
                </Typography>
              </Box>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Box display="flex" flexDirection="column" width="100%">
                {value?.map((doc) => (
                  <Box display="flex" alignItems="center" mt={2}>
                    <InsertDriveFileIcon
                      color={doc?.isActive ? "error" : "disabled"}
                    />
                    <Typography
                      variant="body1"
                      style={{ marginLeft: "8px", flexGrow: 1 }}
                    >
                      {doc?.file?.name}
                    </Typography>
                    <Chip
                      // icon={
                      //   <CheckCircleOutlineIcon
                      //     style={{ color: siverity("text", doc?.status) }}
                      //   />
                      // }
                      label={
                        doc?.status === "Pass" ? "Compliant" : "Non-compliant"
                      }
                      style={{
                        backgroundColor: siverity("bg", doc?.status),
                        color: siverity("text", doc?.status),
                      }}
                      size="small"
                    />
                    <CustomButton
                      color="primary"
                      style={{ marginLeft: "8px" }}
                      onClick={() =>
                        handleSelectedDoc(
                          doc?._id,
                          doc?.file?.url,
                          doc?.file?.name
                        )
                      }
                    >
                      View
                    </CustomButton>
                    {type === "Policy" || (
                      <CustomButton
                        color="primary"
                        variant="outlined"
                        style={{ marginLeft: "8px" }}
                        onClick={() => handleActiveFile(doc?._id)}
                        disabled={doc?.isActive ? true : false}
                      >
                        Set as active
                      </CustomButton>
                    )}
                  </Box>
                ))}
              </Box>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
    </Box>
  );
};

export default ComplianceList;
