import { gql } from "@apollo/client";

export const GET_ALL_VENDORS = gql`
  query VendorsList($pagination: PaginationInput!, $type: String) {
    vendorsList(pagination: $pagination, type: $type) {
      count
      vendors {
        vendoriq_id
        _id
        name
        active
        updatedAt
        address {
          country
          state
        }
        isin
        status
        wazuhGroup
        count
        assignedBy {
          _id
          name
          profileImg {
            name
            type
            url
          }
        }
        assignedOn
      }
    }
  }
`;

// export const GET_VENDOR = gql``;

export const CREATE_VENDOR = gql`
  mutation CreateVendor(
    $name: String!
    $type: String!
    $website: String!
    $isin: String!
    $operation: String
    $employeesCount: String!
    $sector: SectorInput!
    $address: AddressInput!
    $revenue: [RevenueInput!]!
    $turnover: [TurnoverInput!]
  ) {
    createVendor(
      createVendorInput: {
        name: $name
        type: $type
        website: $website
        isin: $isin
        operation: $operation
        employeesCount: $employeesCount
        sector: $sector
        address: $address
        revenue: $revenue
        turnover: $turnover
      }
    ) {
      _id
    }
  }
`;
export const GQL_CREATE_ROI = gql`
  mutation SubmitROI($submitROIDataInput: SubmitROIDataInput!) {
    submitROI(submitROIDataInput: $submitROIDataInput) {
      message
      success
    }
  }
`;

export const UPDATE_VENDOR = gql`
  mutation UpdateVendor($id: String!, $updateVendorInput: UpdateVendorInput!) {
    updateVendor(id: $id, updateVendorInput: $updateVendorInput) {
      _id
      createdAt
      createdBy
      isin
      employeesCount
      logo {
        name
        type
        url
      }
      name
      updatedAt
      updatedBy
      rtDetails {
        annualExpenseOrEstimatedCostOfTheContractualArrangementForThePastYear
        approvalFromTheManagementBody
        budgetOfTheContractualArrangementForTheUpcomingYear
        contractualArrangementReferenceNumber
        countryOfTheFinancialEntity
        countryOfTheIssuanceOfTheOtherCodeToIdentifyTheICTThirdPartyServiceProvider
        currency
        dateOfLastUpdate
        leiOfTheFinancialEntity
        nameOfTheFinancialEntity
        otherCodeToIdentifyTheICTThirdPartyServiceProvider
        overarchingContractualArrangementReferenceNumber
        typeOfContractualArrangement
        typeOfEntityMaintainingRegisterOfInformation
        typeOfOtherCodeToIdentifyTheICTThirdPartyServiceProvider
      }
    }
  }
`;

export const GET_ALL_STAKEHOLDERS = gql`
  query StakeholderList($vendorId: String!, $type: String) {
    stakeholderList(vendorId: $vendorId, type: $type) {
      _id
      email
      name
      designation
      category
    }
  }
`;

export const ADD_NEW_STAKEHOLDERS = gql`
  mutation AddStakeholder(
    $email: String!
    $name: String!
    $vendorId: ID!
    $category: String!
    $designation: String!
  ) {
    addStakeholder(
      addStakeholderInput: {
        email: $email
        name: $name
        vendorId: $vendorId
        category: $category
        designation: $designation
      }
    ) {
      message
      success
    }
  }
`;

export const GET_VENDOR_BASIC_DETAILS = gql`
  query Vendor($vendorId: String!) {
    vendor(id: $vendorId) {
      _id
      isin
      kypId
      vendoriq_id
      address {
        _id
        country
        district
        landmark
        location
        pincode
        state
      }
      revenue {
        revenue
        currency
        year
        figures
      }
      turnover {
        turnover
        currency
        year
        figures
      }
      sector {
        sector {
          _id
          sector
        }
        industry {
          _id
          industry
        }
      }
      name
      operation
      website
      employeesCount
      logo {
        name
        type
        url
      }
      wazuhGroup
    }
  }
`;

export const REQUEST_FILES_STAKEHOLDERS = gql`
  mutation RequestFiles(
    $stakeholders: [ID!]!
    $vendorId: ID!
    $docs: [VendorDocumentInput!]!
  ) {
    requestFiles(
      requestFilesInput: {
        docs: $docs
        stakeholders: $stakeholders
        vendorId: $vendorId
      }
    ) {
      message
      success
    }
  }
`;

export const GET_BANK_ADMIN_SURVEYS_FOR_LANDING_PAGE = gql`
  query Surveys($vendorId: String, $pagination: PaginationInput!) {
    surveys(vendorId: $vendorId, pagination: $pagination) {
      count
      surveys {
        _id
        name
        publishedVersion
        status
        createdOn
        latestVersion
        isShared
        createdBy {
          name
        }
      }
    }
  }
`;

export const GET_BANK_ADMIN_SURVEYS = gql`
  query Surveys($vendorId: String, $type: String) {
    surveys(vendorId: $vendorId, type: $type) {
      count
      surveys {
        _id
        name
        publishedVersion
        type
      }
    }
  }
`;
// export const GET_BANK_ADMIN_SURVEYS = gql`
//   query Surveys($bankId: String!, $version: Float, $vendorId: String) {
//     surveys(bankId: $bankId, vendorId: $vendorId) {
//       _id
//       name
//       publishedVersion
//       status
//       createdOn
//       latestVersion
//       isShared
//       createdBy {
//         name
//       }
//       sections(version: $version) {
//         noOfSections
//         sections {
//           _id
//           active
//           default
//           description
//           isPublished
//           order
//           publishedBy
//           publishedOn
//           title
//           version
//           questions {
//             _id
//             active
//             category
//             categorySuggestion
//             editedAt
//             editedBy
//             order
//             question
//             questionType
//             version
//             weightage
//             answers {
//               option
//               weightage
//             }
//           }
//         }
//       }
//     }
//   }
// `;

export const GET_BANK_ADMIN_SINGLE_SURVEY = gql`
  query Survey($id: String!, $version: Float, $vendorId: String) {
    survey(id: $id, vendorId: $vendorId) {
      _id
      createdOn
      default
      details
      latestVersion
      isShared
      modifiedBy
      modifiedOn
      name
      publishedVersion
      status
      sections(version: $version) {
        noOfSections
        sections {
          _id
          active
          default
          description
          isPublished
          noOfQuestions
          order
          publishedBy
          publishedOn
          title
          version
          questions {
            _id
            active
            category
            categorySuggestion
            editedAt
            editedBy
            order
            question
            questionType
            status
            version
            weightage
            tableHeaders {
              title
              type
            }
            answers {
              option
              weightage
            }
            showComment
          }
        }
      }
    }
  }
`;

export const SHARE_BANK_ADMIN_SURVEY = gql`
  mutation ShareSurvey($shareSurveyInput: ShareSurveyInput!) {
    shareSurvey(shareSurveyInput: $shareSurveyInput) {
      message
      success
    }
  }
`;

export const GQL_UPDATE_SURVEY = gql`
  mutation UpdateSurvey($updateSurveyInput: UpdateSurveyInput!) {
    updateSurvey(updateSurveyInput: $updateSurveyInput) {
      _id
    }
  }
`;

export const GQL_GET_VA_COMPLETED_SURVEY_FOR_BA = gql`
  mutation GetSharedSurveyVendors($surveyId: String!, $version: Int!) {
    getSharedSurveyVendors(
      getSharedSurveyVendors: { surveyId: $surveyId, version: $version }
    ) {
      _id
      noOfStakeholders
      sharedOn
      vendor {
        name
        _id
      }
    }
  }
`;

export const GQL_GET_VENDOR_UPLOADED_FILES = gql`
  query VendorFiles($vendorId: ID!) {
    vendorFiles(vendorId: $vendorId) {
      _id
      fileName
      fileType
      fileUrl
      requestId
      type
      uploadedBy {
        _id
        name
      }
      vendorId
      year
      createdAt
      status
    }
  }
`;

export const GQL_GET_VENDOR_UPLOADED_FILES_STATUS = gql`
  query VendorFiles($vendorId: ID!) {
    vendorFiles(vendorId: $vendorId) {
      _id
      status
    }
  }
`;

export const GET_VENDOR_NAME_FOR_VA = gql`
  query Vendor($vendorId: String!) {
    vendor(id: $vendorId) {
      _id
      isin
      name
      logo {
        name
        type
        url
      }
    }
  }
`;

export const GET_SINGLE_SURVEY_QUESTION_COUNT = gql`
  query Survey($id: String!, $version: Float, $vendorId: String) {
    survey(id: $id, vendorId: $vendorId) {
      _id
      sections(version: $version) {
        sections {
          noOfQuestions
        }
      }
    }
  }
`;

export const GQL_GET_VENDOR_UPLOADED_FILES_IN_SURVEY = gql`
  query SurveyDocs($vendorId: String!) {
    surveyDocs(vendorId: $vendorId) {
      uploadedAt
      uploadedBy {
        name
        profileImg {
          name
          type
          url
        }
      }
      document {
        name
        type
        url
      }
    }
  }
`;

export const GQL_GET_VENDORS_BY_ISIN = gql`
  query SearchVendors($query: String!) {
    searchVendors(query: $query) {
      _id
      isin
      name
      logo {
        name
        type
        url
      }
      address {
        _id
        country
        district
        landmark
        location
        phone
        pincode
        state
      }
      employeesCount
      operation
      revenue {
        currency
        figures
        revenue
        year
      }
      sector {
        industry {
          industry
          _id
          industryCode
        }
        sector {
          _id
          sector
          sectorCode
        }
      }
      turnover {
        currency
        figures
        turnover
        year
      }
      type
      website
    }
  }
`;
export const GQL_GET_SURVEYS_BY_NAME = gql`
  query SearchSurveys($bankId: String!, $query: String!) {
    searchSurveys(bankId: $bankId, query: $query) {
      _id
      createdOn
      name
      publishedVersion
      status
      createdBy {
        name
      }
    }
  }
`;

export const GQL_GET_VENDORS_BY_NAME = gql`
  query SearchVendors(
    $query: String!
    $filters: VendorSearchFilter
    $limit: Int
  ) {
    searchVendors(query: $query, filters: $filters, limit: $limit) {
      _id
      active
      name
      updatedAt
      address {
        country
      }
      status
    }
  }
`;

export const GQL_GET_LINKAGE_SURVEY = gql`
  query LinkageSurveys {
    linkageSurveys {
      _id
      details
      name
      order
    }
  }
`;

export const GQL_GET_LINKAGE_SURVEY_SECTIONS = gql`
  query LinkageSurveySections(
    $surveyId: String!
    $entityRegistration: Boolean
    $vendorRegistration: Boolean
  ) {
    linkageSurveySections(
      surveyId: $surveyId
      entityRegistration: $entityRegistration
      vendorRegistration: $vendorRegistration
    ) {
      _id
      order
      title
    }
  }
`;

export const GQL_GET_LINKAGE_SURVEY_QUESTIONS = gql`
  query LinkageSurveyQuestions($input: GetLinkageQuestionsInput!) {
    linkageSurveyQuestions(input: $input) {
      _id
      code
      answer
      instruction
      linkageAnswer
      linkageId
      mandatory
      name
      order
      type
      weight
      options {
        comment
        description
        documentUpload
        endDate
        justification
        justificationText
        option
        startDate
        weightage
      }
    }
  }
`;

export const GQL_GET_ROI_DATA_TABLE = gql`
  query ROIDataTable($sectionId: String!) {
    ROIDataTable(sectionId: $sectionId) {
      tableData {
        ... on LinkageSurveyAnswer {
          _id
          answer
          bankId
          vendorId
          questionId {
            _id
            type
          }
        }
        ... on Vendor {
          _id
          name
          type
        }
        ... on Bank {
          _id
          name
        }
        ... on ROIFunction {
          _id
          bankId
        }
      }
      tableHeaders {
        _id
        code
        instruction
        linkageId
        mandatory
        name
        options {
          option
        }
        order
        sectionId
        type
      }
    }
  }
`;

export const GQL_SUBMIT_LINKAGE_QUESTIONS = gql`
  mutation SubmitLinkageAnswer($input: [SubmitLinkageAnswerInput!]!) {
    submitLinkageAnswer(input: $input) {
      message
      success
    }
  }
`;

export const GQL_CREATE_ROI_ENTITY = gql`
  mutation RegisterEntityUnderROI($regEntityInput: EntityRegistrationInput!) {
    registerEntityUnderROI(regEntityInput: $regEntityInput) {
      message
      success
    }
  }
`;

export const GQL_CREATE_ROI_FUNCTIONS = gql`
  mutation CreateROIFunction($createROIFunction: CreateROIFunction!) {
    createROIFunction(createROIFunction: $createROIFunction) {
      message
      success
    }
  }
`;

export const GQL_FIND_ENTITY = gql`
  query SearchLEIOfEntity($bankId: String!, $query: String) {
    searchLEIOfEntity(bankId: $bankId, query: $query) {
      LEI_of_the_entity
      Name_of_the_entity
      _id
    }
  }
`;

export const GQL_FIND_FUNCTION = gql`
  query SearchROIFunction2($bankId: String!, $query: String) {
    searchROIFunction(bankId: $bankId, query: $query) {
      Function_identifier
      Function_name
      _id
    }
  }
`;
export const GQL_FIND_THIRD_PARTYY_SERVICE_PROVIDERS = gql`
  query SearchThirdPartyServiceProviders($bankId: String!, $query: String) {
    searchThirdPartyServiceProviders(bankId: $bankId, query: $query) {
      Identification_code_of_the_third_party_service_provider
      Legal_name_of_the_third_party_service_provider
      Type_of_code_of_the_third_party_service_provider
      _id
    }
  }
`;
export const GQL_FIND_CONTRACT_NUMBER = gql`
  query SearchROIContracts($bankId: String!, $query: String) {
    searchROIContracts(bankId: $bankId, query: $query) {
      Contractual_arrangement_reference_number
      _id
      Type_of_contractual_arrangement {
        _id
        identifier
        listIdentifier
        option
        titleIdentifier
      }
    }
  }
`;

export const GQL_CREATE_ROI_CONTRACTS = gql`
  mutation CreateROIContract($createROIContractInput: CreateROIContractInput!) {
    createROIContract(createROIContractInput: $createROIContractInput) {
      message
      success
    }
  }
`;

export const GQL_CREATE_ROI_VENDOR = gql`
  mutation RegisterVendorUnderROI($regVendorInput: VendorRegistrationInput!) {
    registerVendorUnderROI(regVendorInput: $regVendorInput) {
      message
      success
    }
  }
`;

export const GQL_GET_ROI_FUNCTIONS = gql`
  query GetROIFunctions($bankId: String!) {
    getROIFunctions(bankId: $bankId) {
      Date_of_the_last_assessment_of_criticality_or_importance
      Function_identifier
      Function_name
      LEI_of_the_financial_entity
      Reasons_for_criticality_or_importance
      Recovery_point_objective_of_the_function
      Recovery_time_objective_of_the_function
      _id
      bankId
      Criticality_or_importance_assessment {
        _id
        identifier
        listIdentifier
        option
        titleIdentifier
      }
      Impact_of_discontinuing_the_function {
        _id
        identifier
        listIdentifier
        option
        titleIdentifier
      }
      Licensed_activity {
        _id
        identifier
        listIdentifier
        option
        titleIdentifier
      }
    }
  }
`;

export const GQL_GET_ROI_CONTRACTS = gql`
  query GetROIContracts($bankId: String!) {
    getROIContracts(bankId: $bankId) {
      Annual_expense_or_estimated_cost_of_the_contractual_arrangement_for_the_past_year
      Contractual_arrangement_reference_number
      End_date_of_the_contractual_arrangement
      Function_identifier
      Identification_code_of_the_third_party_service_provider
      LEI_of_the_financial_entity_making_use_of_the_ICT_service
      LEI_of_the_intra_group_entity_providing_ICT_service
      Notice_period_for_the_ICT_third_party_service_provider
      Notice_period_for_the_financial_entity
      Overarching_contractual_arrangement_reference_number
      Start_date_of_the_contractual_arrangement
      Type_of_code_to_identify_the_third_party_service_provider
      bankId
      Country_of_provision_of_the_ICT_services {
        _id
        identifier
        listIdentifier
        option
        titleIdentifier
      }
      Country_of_the_governing_law_of_the_contractual_arrangement {
        _id
        identifier
        listIdentifier
        option
        titleIdentifier
      }
      Currency_of_the_amount_reported {
        _id
        identifier
        listIdentifier
        option
        titleIdentifier
      }
      Level_of_reliance_on_the_ICT_service_supporting_the_critical_or_important_function {
        _id
        identifier
        listIdentifier
        option
        titleIdentifier
      }
      Location_of_management_of_the_data_processing {
        _id
        identifier
        listIdentifier
        option
        titleIdentifier
      }
      Location_of_the_data_at_rest_storage {
        _id
        identifier
        listIdentifier
        option
        titleIdentifier
      }
      Reason_of_the_termination_or_ending_of_the_contractual_arrangement {
        _id
        identifier
        listIdentifier
        option
        titleIdentifier
      }
      Sensitiveness_of_the_data_stored_by_the_ICT_third_party_service_provider {
        _id
        identifier
        listIdentifier
        option
        titleIdentifier
      }
      Storage_of_data {
        _id
        identifier
        listIdentifier
        option
        titleIdentifier
      }
      Type_of_ICT_services {
        _id
        identifier
        listIdentifier
        option
        titleIdentifier
      }
      Type_of_contractual_arrangement {
        _id
        identifier
        listIdentifier
        option
        titleIdentifier
      }
    }
  }
`;

export const GQL_GET_VENDOR_MATERIALITY_SCORE = gql`
  query VendorMaterialityScores($vendorId: String!) {
    vendorMaterialityScores(vendorId: $vendorId) {
      vendorCategorization {
        _id
        comment
        score
        sectionWiseScore
      }
      vendorMateriality {
        _id
        comment
        score
        sectionWiseScore
      }
      vendorPerformance {
        _id
        comment
        score
        sectionWiseScore
      }
    }
  }
`;

export const GQL_SEND_TO_MAGPIE = gql`
  mutation SendToMagpie($sendToMagpieInput: SendToMagpieInput!) {
    sendToMagpie(sendToMagpieInput: $sendToMagpieInput) {
      message
      success
    }
  }
`;

export const GQL_GET_VENDOR_IQ_COMPANIES = gql`
  query SearchVendorIQCompanies(
    $params: SearchVendorIQCompany!
    $pagination: PaginationInput
  ) {
    searchVendorIQCompanies(params: $params, pagination: $pagination)
  }
`;

export const GQL_GET_VENDOR_IQ_DATA = gql`
  query VendorIQData($vendoriq_id: String!) {
    vendorIQData(vendoriq_id: $vendoriq_id)
  }
`;

export const GQL_SET_VENDOR_IQ_ID = gql`
  mutation SetVendorIQId($vendorId: String!, $vendoriq_id: String!) {
    setVendorIQId(vendorId: $vendorId, vendoriq_id: $vendoriq_id) {
      message
      success
    }
  }
`;

export const GQL_GET_KYP_COMPANIES = gql`
  query KypCompanies($params: KypSearchParams!) {
    kypCompanies(params: $params)
  }
`;

export const GQL_SET_KYP_ID = gql`
  mutation SetKypId($company: SearchKypCompany!, $vendorId: String!) {
    setKypId(company: $company, vendorId: $vendorId) {
      message
      success
    }
  }
`;

export const GQL_GET_KYP_DATA = gql`
  query KypData($kypId: String!) {
    kypData(kypId: $kypId)
  }
`;

export const GQL_DASHBOARD_ALERT = gql`
  query GetAlert($getAlertInput: GetAlertInput!) {
    getAlert(getAlertInput: $getAlertInput) {
      _id
      active
      bankId
      emailsToAlert
      pause

      threshold
      vendorId
      widgetName
    }
  }
`;

export const GQL_CREATE_DASHBOARD_ALERT = gql`
  mutation CreateAlert($createAlertInput: CreateAlertInput!) {
    createAlert(createAlertInput: $createAlertInput) {
      message
      success
    }
  }
`;

export const GQL_UPDATE_DASHBOARD_ALERT = gql`
  mutation UpdateAlert($updateAlertInput: UpdateAlertInput!) {
    updateAlert(updateAlertInput: $updateAlertInput) {
      message
      success
    }
  }
`;

// ------------------ Intrim Built -----------------------

export const GQL_SURVEY_LIST_DATAFORM = gql`
  query SurveyListCategorized($vendorId: String!) {
    surveyListCategorized(vendorId: $vendorId) {
      dueDiligenceSurveys {
        _id
        lastSubmissionDate
        name
        status
        type
        isShared
        publishedVersion
      }
      riskAssessmentSurveys {
        _id
        lastSubmissionDate
        name
        status
        type
        isShared
        publishedVersion
      }
      vendorScopingSurveys {
        _id
        lastSubmissionDate
        name
        status
        type
        isShared
        publishedVersion
      }
      terminationSurveys {
        _id
        lastSubmissionDate
        name
        status
        type
        isShared
        publishedVersion
      }
      others {
        _id
        isShared
        lastSubmissionDate
        name
        publishedVersion
        status
        type
      }
    }
  }
`;

export const GQL_GET_SURVEY_RESPONSE_FREQUENCIES = gql`
  query SurveyResponseFrequencies(
    $surveyResponseFrequenciesInput: SurveyResponseFrequenciesInput!
  ) {
    surveyResponseFrequencies(
      surveyResponseFrequenciesInput: $surveyResponseFrequenciesInput
    ) {
      daily {
        _id
        endDate
        startDate
        type
        isCompleted
        surveyName
      }
      monthly {
        _id
        endDate
        isCompleted
        startDate
        surveyName
        type
      }
      halfYearly {
        _id
        endDate
        isCompleted
        startDate
        surveyName
        type
      }
      quarterly {
        _id
        endDate
        isCompleted
        startDate
        surveyName
        type
      }
      yearly {
        _id
        endDate
        isCompleted
        startDate
        surveyName
        type
      }
    }
  }
`;
export const GQL_GET_RISK_REGISTER = gql`
  query Risks($vendorId: String!) {
    risks(vendorId: $vendorId) {
      _id
      bankId
      createdAt
      description
      enableCompensationControl
      finding
      risk
      riskCompensationStatement
      severity
      updatedAt
      vendorId
    }
  }
`;

export const GQL_GET_SURVEY_SCORES = gql`
  query SurveyScores($surveyId: String!, $vendorId: String!) {
    surveyScores(surveyId: $surveyId, vendorId: $vendorId)
  }
`;

export const GQL_GET_RISK_AUTO_POPULATE = gql`
  query GetRiskForQuestion($getRiskForQuestionInput: GetRiskForQuestionInput!) {
    getRiskForQuestion(getRiskForQuestionInput: $getRiskForQuestionInput)
  }
`;

export const GQL_GET_SUBMITTED_SURVEY_RESPONSES = gql`
  query GetSubmittedSurveyResponse(
    $getSubmittedSurveyResponseInput: GetSubmittedSurveyResponseInput!
  ) {
    getSubmittedSurveyResponse(
      getSubmittedSurveyResponseInput: $getSubmittedSurveyResponseInput
    ) {
      sectionDescription
      sectionId
      sectionTitle
      questions {
        order
        question
        questionId
        questionType
        weightage
        answers {
          comment
          description
          documentUpload
          endDate
          justification
          justificationText
          option
          riskCompensationStatement
          startDate
          weight
          weightage
          questionRisk {
            description
            enableCompensationControl
            finding
            risk
            severity
          }
          documentUrl {
            name
            type
            url
          }
        }
        approvedAnswer {
          comment
          description
          documentUpload
          endDate
          justification
          justificationText
          option
          riskCompensationStatement
          startDate
          weight
          weightage
          questionRisk {
            description
            enableCompensationControl
            finding
            risk
            severity
          }
          documentUrl {
            name
            type
            url
          }
        }
        tableHeaders {
          title
          type
        }
        approvedTableRows {
          comment
          description
          documentUpload
          endDate
          justification
          justificationText
          option
          riskCompensationStatement
          startDate
          weight
          weightage
          documentUrl {
            name
            type
            url
          }
          questionRisk {
            description
            enableCompensationControl
            finding
            risk
            severity
          }
        }
      }
    }
  }
`;

export const GQL_APPROVE_OR_REJECT = gql`
  mutation ApproveOrRejectSurvey(
    $approved: Boolean
    $comment: String
    $rejected: Boolean
    $submissionId: String!
  ) {
    approveOrRejectSurvey(
      approveOrRejectInput: {
        approved: $approved
        comment: $comment
        rejected: $rejected
        submissionId: $submissionId
      }
    ) {
      message
      success
    }
  }
`;

export const GQL_HISTORIC_DATA = gql`
  query GetHistoricData($vendorId: ID!, $widgetName: Widgets!) {
    getHistoricData(
      getHistoricData: { vendorId: $vendorId, widgetName: $widgetName }
    ) {
      _id
      bankId
      score
      vendorId
      widgetName
    }
  }
`;

export const GQL_GET_ROI_SECTION_DATA = gql`
  query GetROISectionData($bankId: String!, $section: String!) {
    getROISectionData(bankId: $bankId, section: $section) {
      ... on RT_01_02 {
        Date_of_deletion_in_the_Register_of_information
        Date_of_integration_in_the_Register_of_information
        Date_of_last_update
        LEI_of_the_direct_parent_undertaking_of_the_financial_entity
        LEI_of_the_entity
        Name_of_the_entity
        Value_of_total_assets
        _id
        bankId
        entityId
        sectionName
        Country_of_the_entity {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Currency {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Hierarchy_of_the_entity_within_the_group_where_applicable {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Type_of_entity {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
      }
      ... on RT_01_03 {
        Identification_code_of_the_branch
        LEI_of_the_financial_entity_head_office_of_the_branch
        Name_of_the_branch
        _id
        sectionName
        Country_of_the_branch {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
      }
      ... on RT_02_01 {
        Annual_expense_or_estimated_cost_of_the_contractual_arrangement_for_the_past_year
        Contractual_arrangement_reference_number
        Overarching_contractual_arrangement_reference_number
        _id
        bankId
        sectionName
        Type_of_contractual_arrangement {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Currency_of_the_amount_reported {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
      }
      ... on RT_01_01 {
        Competent_Authority
        Date_of_the_reporting
        LEI_of_the_entity_maintaining_the_register_of_information
        Name_of_the_entity
        _id
        sectionName
        Country_of_the_entity {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Type_of_entity {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
      }
      ... on RT_02_02 {
        Contractual_arrangement_reference_number
        End_date_of_the_contractual_arrangement
        Function_identifier
        Identification_code_of_the_third_party_service_provider
        LEI_of_the_financial_entity_making_use_of_the_ICT_service
        Notice_period_for_the_ICT_third_party_service_provider
        Notice_period_for_the_financial_entity
        Start_date_of_the_contractual_arrangement
        Type_of_code_to_identify_the_third_party_service_provider
        _id
        bankId
        sectionName
        Country_of_provision_of_the_ICT_services {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Level_of_reliance_on_the_ICT_service_supporting_the_critical_or_important_function {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Country_of_the_governing_law_of_the_contractual_arrangement {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Location_of_management_of_the_data_processing {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Location_of_the_data_at_rest_storage {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Reason_of_the_termination_or_ending_of_the_contractual_arrangement {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Sensitiveness_of_the_data_stored_by_the_ICT_third_party_service_provider {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Storage_of_data {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Type_of_ICT_services {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
      }
      ... on RT_02_03 {
        Contractual_arrangement_with_ICT_intra_group_service_provider
        Linked_contractual_arrangement_with_ICT_third_party_service_provider
        _id
        sectionName
      }
      ... on RT_03_01 {
        Contractual_arrangement_reference_number
        LEI_of_the_entity_signing_the_contractual_arrangement
        _id
        bankId
        sectionName
      }
      ... on RT_03_02 {
        Contractual_arrangement_reference_number
        Identification_code_of_the_third_party_service_provider
        Type_of_code_of_the_third_party_service_provider
        _id
        bankId
        sectionName
      }
      ... on RT_03_03 {
        Contractual_arrangement_reference_number
        LEI_of_the_intra_group_entity_providing_ICT_service
        _id
        bankId
        sectionName
      }
      ... on RT_04_01 {
        Contractual_arrangement_reference_number
        Identification_code_of_the_branch
        LEI_of_the_financial_entity
        Nature_of_the_entity_making_use_of_the_ICT_service {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        _id
        sectionName
      }
      ... on RT_05_01 {
        Additional_identification_code_of_the_third_party_service_provider
        Identification_code_of_the_third_party_service_provider
        Identification_code_of_the_third_party_service_providers_ultimate_parent_undertaking
        Legal_name_of_the_third_party_service_provider
        Name_of_the_ICT_third_party_service_provider_in_Latin_alphabet
        Total_annual_expense_or_estimated_cost_of_the_third_party_service_provider
        Type_of_additional_identification_code_of_the_third_party_service_provider
        Type_of_code_of_the_third_party_service_provider
        Type_of_code_of_the_third_party_service_providers_ultimate_parent_undertaking
        _id
        bankId
        sectionName
        vendorId
        Country_of_the_third_party_service_providers_headquarters {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Currency_of_the_amount_reported {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Type_of_person_of_the_third_party_service_provider {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
      }
      ... on RT_05_02 {
        Contractual_arrangement_reference_number
        Identification_code_of_the_recipient_of_sub_contracted_ICT_services
        Identification_code_of_the_third_party_service_provider
        Rank
        Type_of_code_of_the_third_party_service_provider
        _id
        sectionName
        Type_of_ICT_services {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Type_of_code_of_the_recipient_of_sub_contracted_ICT_services
      }
      ... on RT_06_01 {
        Date_of_the_last_assessment_of_criticality_or_importance
        Function_identifier
        Function_name
        LEI_of_the_financial_entity
        Reasons_for_criticality_or_importance
        Recovery_point_objective_of_the_function
        Recovery_time_objective_of_the_function
        _id
        bankId
        sectionName
        Criticality_or_importance_assessment {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Impact_of_discontinuing_the_function {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Licensed_activity {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
      }
      ... on RT_07_01 {
        Contractual_arrangement_reference_number
        Date_of_the_last_audit_on_the_ICT_third_party_service_provider
        Identification_code_of_the_third_party_service_provider
        Identification_of_alternative_ICT_TPP
        Type_of_code_of_the_third_party_service_provider
        _id
        sectionName
        Are_there_alternative_ICT_third_party_service_providers_identified {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Existence_of_an_exit_plan {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Impact_of_discontinuing_the_ICT_services {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Possibility_of_reintegration_of_the_contracted_ICT_service {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Reason_if_the_ICT_third_party_service_provider_is_considered_not_substitutable_or_difficult_to_be_substitutable {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Substitutability_of_the_ICT_third_party_service_provider {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Type_of_ICT_services {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
      }
      ... on RT_99_01 {
        _id
        sectionName
        Type_of_contractual_arrangement {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Sensitiveness_of_the_data_stored_by_the_ICT_third_party_service_provider {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Impact_of_discontinuing_the_function {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Substitutability_of_the_ICT_third_party_service_provider {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Possibility_of_reintegration_of_the_contracted_ICT_service {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
        Impact_of_discontinuing_the_ICT_services {
          _id
          identifier
          listIdentifier
          option
          titleIdentifier
        }
      }
    }
  }
`;

export const GQL_SUBMIT_ROI_DATA = gql`
  mutation SubmitROISectionData(
    $submitROISectionDataInput: SubmitROISectionDataInput!
  ) {
    submitROISectionData(
      submitROISectionDataInput: $submitROISectionDataInput
    ) {
      message
      success
    }
  }
`;
export const GQL_DELETE_ROI_ROW = gql`
  mutation DeleteROIData($_id: String!, $sectionName: String!) {
    deleteROIData(_id: $_id, sectionName: $sectionName) {
      message
      success
    }
  }
`;
