import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Switch,
  Typography,
} from "@material-ui/core";
import React from "react";
import CustomButton from "../../../UI/button/button";
import { Edit } from "@material-ui/icons";
import { useSelector } from "react-redux";

function Introduction({ classes }) {
  const { vendorBasicDetails } = useSelector((state) => state?.brmData);
  return (
    <CardContent>
      <Typography variant="body2">
        The purpose of this report is to provide a comprehensive evaluation of
        the vendor, {vendorBasicDetails?.name}, covering the entire lifecycle
        from the initial vendor intake and scoping to the eventual termination
        and offboarding process.
      </Typography>
      <br />
      <Typography variant="body2">
        This assessment encompasses key phases including Vendor Intake/Scoping,
        where the vendor's suitability and alignment with the bank's
        requirements were evaluated; Risk Assessment, to identify potential
        operational, security, and compliance risks; Due Diligence, ensuring the
        vendor meets legal, financial, and operational standards; Contracting,
        which formalized the terms of engagement; Onboarding, which facilitated
        smooth integration; Monitoring, to ensure ongoing compliance and
        performance management; and finally, Termination and Offboarding,
        detailing the controlled exit and continuity planning for the vendor.
      </Typography>
    </CardContent>
  );
}

export default Introduction;
