import React, { useEffect, useRef, useState } from "react";
import { Typography, Box, makeStyles, Button } from "@material-ui/core";
import { Add, Share } from "@material-ui/icons";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import CustomButton from "../../../UI/button/button";
import Introduction from "./Introduction";
import VendorBackground from "./VendorBackground";
import VendorContractDetails from "./VendorContractDetails";

import VendorIntake from "components/widgets/vendorDiagnosis_v2/vendorIntake";
import TprmRiskCard from "./TprmRiskCard";
import RiskAssessment from "components/widgets/vendorDiagnosis_v2/riskAssessment";
import DueDiligence from "components/widgets/vendorDiagnosis_v2/dueDiligence";
import Contracting from "components/widgets/vendorDiagnosis_v2/contracting";
import Monitoring from "components/widgets/vendorDiagnosis_v2/monitorning";
import TerminationAndOffboarding from "components/widgets/vendorDiagnosis_v2/terminationAndOffboarding";
import TprmRiskReportPdf from "../trpmRiskReportPdf/TprmRiskReportPdf";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import ScreenshotComponent from "../trpmRiskReportPdf/ScreenshotComponent";

import { Document, Page, Image, StyleSheet } from "@react-pdf/renderer";
import { useReactToPrint } from "react-to-print";
import "./print.css";
import TprmRiskContent from "../trpmRiskReportPdf/TprmRiskContent";

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  image: {
    width: "100%",
    height: "auto",
  },
});

const useStyles = makeStyles((theme) => ({
  switchBase: {
    color: "#fff",
    "&$checked": {
      color: "#32A05F",
    },
    "&$checked + $track": {
      backgroundColor: "#32A05F",
    },
  },
  checked: {},
  track: {},
  // button: {
  //   borderColor: "#3374B9",
  //   color: "#3374B9",
  //   "&:hover": {
  //     backgroundColor: "#e6f2ff",
  //     borderColor: "#3374B9",
  //   },
  // },
  chip: {
    backgroundColor: "#BFE2CD",
    color: "#195a11",
    // borderColor: "#195a11",
    // border: "1px solid",
    // "& .MuiChip-label": {
    //   color: "#195a11",
    // },
  },
}));

const TprmRiskReport = () => {
  const classes = useStyles();

  const { vendorMaterialityScores } = useSelector((state) => state.brmData);

  const [updateKey, setUpdateKey] = useState(0);

  const [capturedImage, setCapturedImage] = useState(null);

  const [showSwitch, setShowSwitch] = useState(true);

  const contentRef = useRef(null);

  const handleUpdate = () => {
    setUpdateKey((prevKey) => prevKey + 1); // Force re-render
  };

  const generatePdf = useReactToPrint({
    contentRef,
    documentTitle: "TPRM Report",
    onAfterPrint: () => setShowSwitch(true),
  });

  const [showReport, setShowReport] = useState({
    Introduction: true,
    "Vendor Background": true,
    "Vendor Contract Details": true,
    "Vendor Intake/Scoping": true,
    "Risk Assessment": false,
    "Due Diligence": false,
    Contracting: false,
    Monitoring: false,
    "Termination And Offboarding": false,
  });

  return (
    <Box>
      {/* Final Recommendation Header */}
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography variant="body1" gutterBottom style={{ fontWeight: 600 }}>
          Final Recommendation
        </Typography>
        <Box>
          <Button
            onClick={async () => {
              await setShowSwitch(false);
              generatePdf();
            }}
          >
            PDF
          </Button>
        </Box>
        {/* <Box display={"flex"} gridGap={"20px"}>
          <CustomButton
            size="small"
            color="primary"
            startIcon={<OpenInNewRoundedIcon />}
          >
            Export
          </CustomButton>

          <CustomButton size="small" color="primary" startIcon={<Share />}>
            Share
          </CustomButton>

          <CustomButton
            size="small"
            color="primary"
            variant="contained"
            startIcon={<Add />}
          >
            Add Section
          </CustomButton>
        </Box> */}
      </Box>

      <Box className="scroll">
        {/* <PDFDownloadLink
          document={
            <TprmRiskReportPdf
              data={updateKey}
              showReport={showReport}
              vendorMaterialityScores={vendorMaterialityScores}
            />
          }
          fileName="Tprm Report"
        >
          {({ loading }) =>
            loading ? "Loading..." : <Button>Download</Button>
          }
        </PDFDownloadLink> */}

        {/* <Button onClick={handleUpdate}>Update PDF</Button>
        <PDFViewer style={{ width: "100%", height: "40vh" }}>
          <TprmRiskReportPdf
            data={updateKey}
            showReport={showReport}
            vendorMaterialityScores={vendorMaterialityScores}
          />
        </PDFViewer> */}
        {/* Introduction Section */}
        {/* <Box>
          <ScreenshotComponent
            setCapturedImage={setCapturedImage}
            showReport={showReport}
          >
            {showReport["Introduction"] && <Introduction classes={classes} />}{" "}
            {showReport["Vendor Background"] && (
              <VendorBackground classes={classes} />
            )}
            {showReport["Vendor Contract Details"] && (
              <VendorContractDetails classes={classes} />
            )}
            {showReport["Vendor Intake/Scoping"] && (
              <VendorIntake isFrom={"tprmRiskReport"} />
            )}
            {showReport["Risk Assessment"] && (
              <RiskAssessment isFrom={"tprmRiskReport"} />
            )}
            {showReport["Due Diligence"] && (
              <DueDiligence isFrom={"tprmRiskReport"} />
            )}
            {showReport["Contracting"] && (
              <Contracting isFrom={"tprmRiskReport"} />
            )}
            {showReport["Monitoring"] && (
              <Monitoring isFrom={"tprmRiskReport"} />
            )}
            {showReport["Termination And Offboarding"] && (
              <TerminationAndOffboarding isFrom={"tprmRiskReport"} />
            )}
          </ScreenshotComponent>
          {capturedImage && (
            <PDFViewer style={{ width: "100%", height: "40vh" }}>
              <Document>
                <Page style={styles.page}>
                  <Image style={styles.image} src={capturedImage} />
                </Page>
              </Document>
            </PDFViewer>
          )}
        </Box> */}

        <Box ref={contentRef}>
          <Box style={{ pageBreakAfter: "always" }} className={"show-on-print"}>
            <TprmRiskContent showReport={showReport} />
          </Box>
          <TprmRiskCard
            heading={"Introduction"}
            setShowReport={setShowReport}
            showReport={showReport}
            showSwitch={showSwitch}
          >
            {showReport["Introduction"] && <Introduction classes={classes} />}
          </TprmRiskCard>

          {/* Vendor Background Section */}

          <Box>
            <TprmRiskCard
              heading={"Vendor Background"}
              setShowReport={setShowReport}
              showReport={showReport}
              showSwitch={showSwitch}
            >
              {showReport["Vendor Background"] && (
                <VendorBackground classes={classes} />
              )}
            </TprmRiskCard>
          </Box>

          {/* Vendor Contact Details */}
          <TprmRiskCard
            heading={"Vendor Contract Details"}
            setShowReport={setShowReport}
            showReport={showReport}
            showSwitch={showSwitch}
          >
            {showReport["Vendor Contract Details"] && (
              <VendorContractDetails classes={classes} />
            )}
          </TprmRiskCard>

          {/* Vendor Intake/Scoping */}
          {/* <TprmRiskCard
          heading={"Vendor Intake/Scoping"}
          setShowReport={setShowReport}
          showReport={showReport}
        >
          <Box ref={showSwitch}>
            {showReport["Vendor Intake/Scoping"] && (
              <VendorIntake isFrom={"tprmRiskReport"} />
            )}
          </Box>
        </TprmRiskCard> */}

          <Box
            style={{ pageBreakBefore: "always" }}
            className={
              showReport["Vendor Intake/Scoping"] ? "" : "hide-on-print"
            }
          >
            <TprmRiskCard
              heading={"Vendor Intake/Scoping"}
              setShowReport={setShowReport}
              showReport={showReport}
              showSwitch={showSwitch}
            >
              {showReport["Vendor Intake/Scoping"] && (
                <VendorIntake isFrom={"tprmRiskReport"} />
              )}
            </TprmRiskCard>
          </Box>

          {/* Risk Assessment */}

          <Box
            style={{ pageBreakBefore: "always" }}
            className={showReport["Risk Assessment"] ? "" : "hide-on-print"}
          >
            <TprmRiskCard
              heading={"Risk Assessment"}
              setShowReport={setShowReport}
              showReport={showReport}
              showSwitch={showSwitch}
            >
              {showReport["Risk Assessment"] && (
                <RiskAssessment isFrom={"tprmRiskReport"} />
              )}
            </TprmRiskCard>
          </Box>

          {/* Due Diligence */}
          <Box
            style={{ pageBreakBefore: "always" }}
            className={showReport["Due Diligence"] ? "" : "hide-on-print"}
          >
            <TprmRiskCard
              heading={"Due Diligence"}
              setShowReport={setShowReport}
              showReport={showReport}
              showSwitch={showSwitch}
            >
              {showReport["Due Diligence"] && (
                <DueDiligence isFrom={"tprmRiskReport"} />
              )}
            </TprmRiskCard>
          </Box>

          {/* Contracting */}
          <Box
            style={{ pageBreakBefore: "always" }}
            className={showReport["Contracting"] ? "" : "hide-on-print"}
          >
            <TprmRiskCard
              heading={"Contracting"}
              setShowReport={setShowReport}
              showReport={showReport}
              showSwitch={showSwitch}
            >
              {showReport["Contracting"] && (
                <Contracting isFrom={"tprmRiskReport"} />
              )}
            </TprmRiskCard>
          </Box>

          {/* Monitoring */}
          <Box
            style={{ pageBreakBefore: "always" }}
            className={showReport["Monitoring"] ? "" : "hide-on-print"}
          >
            <TprmRiskCard
              heading={"Monitoring"}
              setShowReport={setShowReport}
              showReport={showReport}
              showSwitch={showSwitch}
            >
              {showReport["Monitoring"] && (
                <Monitoring isFrom={"tprmRiskReport"} />
              )}
            </TprmRiskCard>
          </Box>

          {/* Termination And Offboarding */}
          <Box
            style={{ pageBreakBefore: "always" }}
            className={
              showReport["Termination And Offboarding"] ? "" : "hide-on-print"
            }
          >
            <TprmRiskCard
              heading={"Termination And Offboarding"}
              setShowReport={setShowReport}
              showReport={showReport}
              showSwitch={showSwitch}
            >
              {showReport["Termination And Offboarding"] && (
                <TerminationAndOffboarding isFrom={"tprmRiskReport"} />
              )}
            </TprmRiskCard>
          </Box>
        </Box>

        {/* Assessment Findings */}
        {/* <AssessmentFindings classes={classes} /> */}

        {/* Final Recommendation */}
        {/* <FinalRecommendation classes={classes} /> */}
      </Box>
    </Box>
  );
};

export default TprmRiskReport;
