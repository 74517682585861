export const roiCurrencyList = [
      {
        identifier: "eba_CU:AED",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "UAE Dirham",
      },
      {
        identifier: "eba_CU:AFN",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Afghani",
      },
      {
        identifier: "eba_CU:ALL",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Lek",
      },
      {
        identifier: "eba_CU:AMD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Armenian Dram",
      },
      {
        identifier: "eba_CU:ANG",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Netherlands Antillean Guilder",
      },
      {
        identifier: "eba_CU:AOA",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Kwanza",
      },
      {
        identifier: "eba_CU:ARS",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Argentine Peso",
      },
      {
        identifier: "eba_CU:AUD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Australian Dollar",
      },
      {
        identifier: "eba_CU:AWG",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Aruban Florin",
      },
      {
        identifier: "eba_CU:AZN",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Azerbaijanian Manat",
      },
      {
        identifier: "eba_CU:BAM",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Convertible Mark",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5ac",
        },
        identifier: "eba_CU:BBD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Barbados Dollar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5ad",
        },
        identifier: "eba_CU:BDT",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Taka",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5ae",
        },
        identifier: "eba_CU:BGN",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Bulgarian Lev",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5af",
        },
        identifier: "eba_CU:BHD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Bahraini Dinar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5b0",
        },
        identifier: "eba_CU:BIF",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Burundi Franc",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5b1",
        },
        identifier: "eba_CU:BMD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Bermudian Dollar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5b2",
        },
        identifier: "eba_CU:BND",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Brunei Dollar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5b3",
        },
        identifier: "eba_CU:BOB",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Boliviano",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5b4",
        },
        identifier: "eba_CU:BOV",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Mvdol",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5b5",
        },
        identifier: "eba_CU:BRL",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Brazilian Real",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5b6",
        },
        identifier: "eba_CU:BSD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Bahamian Dollar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5b7",
        },
        identifier: "eba_CU:BTN",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Ngultrum",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5b8",
        },
        identifier: "eba_CU:BWP",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Pula",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5b9",
        },
        identifier: "eba_CU:BYR",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Belarussian Ruble (2000 Series)",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5ba",
        },
        identifier: "eba_CU:BYN",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Belarusian Ruble",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5bb",
        },
        identifier: "eba_CU:BZD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Belize Dollar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5bc",
        },
        identifier: "eba_CU:CAD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Canadian Dollar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5bd",
        },
        identifier: "eba_CU:CDF",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Congolese Franc",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5be",
        },
        identifier: "eba_CU:CHE",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "WIR Euro",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5bf",
        },
        identifier: "eba_CU:CHF",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Swiss Franc",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5c0",
        },
        identifier: "eba_CU:CHW",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "WIR Franc",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5c1",
        },
        identifier: "eba_CU:CLF",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Unidades de fomento",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5c2",
        },
        identifier: "eba_CU:CLP",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Chilean Peso",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5c3",
        },
        identifier: "eba_CU:CNY",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Yuan Renminbi",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5c4",
        },
        identifier: "eba_CU:COP",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Colombian Peso",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5c5",
        },
        identifier: "eba_CU:COU",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Unidad de Valor Real",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5c6",
        },
        identifier: "eba_CU:CRC",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Costa Rican Colon",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5c7",
        },
        identifier: "eba_CU:CUC",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Peso Convertible",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5c8",
        },
        identifier: "eba_CU:CUP",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Cuban Peso",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5c9",
        },
        identifier: "eba_CU:CVE",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Cape Verde Escudo",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5ca",
        },
        identifier: "eba_CU:CZK",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Czech Koruna",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5cb",
        },
        identifier: "eba_CU:DJF",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Djibouti Franc",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5cc",
        },
        identifier: "eba_CU:DKK",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Danish Krone",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5cd",
        },
        identifier: "eba_CU:DOP",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Dominican Peso",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5ce",
        },
        identifier: "eba_CU:DZD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Algerian Dinar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5cf",
        },
        identifier: "eba_CU:EGP",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Egyptian Pound",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5d0",
        },
        identifier: "eba_CU:ERN",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Nakfa",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5d1",
        },
        identifier: "eba_CU:ETB",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Ethiopian Birr",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5d2",
        },
        identifier: "eba_CU:EUR",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Euro",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5d3",
        },
        identifier: "eba_CU:FJD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Fiji Dollar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5d4",
        },
        identifier: "eba_CU:FKP",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Falkland Islands Pound",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5d5",
        },
        identifier: "eba_CU:GBP",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Pound Sterling",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5d6",
        },
        identifier: "eba_CU:GEL",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Lari",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5d7",
        },
        identifier: "eba_CU:GHS",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Ghana Cedi",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5d8",
        },
        identifier: "eba_CU:GIP",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Gibraltar Pound",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5d9",
        },
        identifier: "eba_CU:GMD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Dalasi",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5da",
        },
        identifier: "eba_CU:GNF",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Guinea Franc",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5db",
        },
        identifier: "eba_CU:GTQ",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Quetzal",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5dc",
        },
        identifier: "eba_CU:GYD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Guyana Dollar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5dd",
        },
        identifier: "eba_CU:HKD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Hong Kong Dollar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5de",
        },
        identifier: "eba_CU:HNL",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Lempira",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5df",
        },
        identifier: "eba_CU:HTG",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Gourde",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5e0",
        },
        identifier: "eba_CU:HUF",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Forint",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5e1",
        },
        identifier: "eba_CU:IDR",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Rupiah",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5e2",
        },
        identifier: "eba_CU:ILS",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "New Israeli Sheqel",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5e3",
        },
        identifier: "eba_CU:INR",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Indian Rupee",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5e4",
        },
        identifier: "eba_CU:IQD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Iraqi Dinar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5e5",
        },
        identifier: "eba_CU:IRR",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Iranian Rial",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5e6",
        },
        identifier: "eba_CU:ISK",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Iceland Krona",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5e7",
        },
        identifier: "eba_CU:JMD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Jamaican Dollar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5e8",
        },
        identifier: "eba_CU:JOD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Jordanian Dinar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5e9",
        },
        identifier: "eba_CU:JPY",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Yen",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5ea",
        },
        identifier: "eba_CU:KES",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Kenyan Shilling",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5eb",
        },
        identifier: "eba_CU:KGS",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Som",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5ec",
        },
        identifier: "eba_CU:KHR",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Riel",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5ed",
        },
        identifier: "eba_CU:KMF",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Comoro Franc",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5ee",
        },
        identifier: "eba_CU:KPW",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "North Korean Won",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5ef",
        },
        identifier: "eba_CU:KRW",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Won",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5f0",
        },
        identifier: "eba_CU:KWD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Kuwaiti Dinar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5f1",
        },
        identifier: "eba_CU:KYD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Cayman Islands Dollar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5f2",
        },
        identifier: "eba_CU:KZT",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Tenge",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5f3",
        },
        identifier: "eba_CU:LAK",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Kip",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5f4",
        },
        identifier: "eba_CU:LBP",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Lebanese Pound",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5f5",
        },
        identifier: "eba_CU:LKR",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Sri Lanka Rupee",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5f6",
        },
        identifier: "eba_CU:LRD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Liberian Dollar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5f7",
        },
        identifier: "eba_CU:LSL",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Loti",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5f8",
        },
        identifier: "eba_CU:LTL",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Lithuanian Litas",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5f9",
        },
        identifier: "eba_CU:LVL",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Latvian Lats",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5fa",
        },
        identifier: "eba_CU:LYD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Libyan Dinar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5fb",
        },
        identifier: "eba_CU:MAD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Moroccan Dirham",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5fc",
        },
        identifier: "eba_CU:MDL",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Moldovan Leu",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5fd",
        },
        identifier: "eba_CU:MGA",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Malagasy Ariary",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5fe",
        },
        identifier: "eba_CU:MKD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Denar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea5ff",
        },
        identifier: "eba_CU:MMK",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Kyat",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea600",
        },
        identifier: "eba_CU:MNT",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Tugrik",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea601",
        },
        identifier: "eba_CU:MOP",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Pataca",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea602",
        },
        identifier: "eba_CU:MRO",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Ouguiya",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea603",
        },
        identifier: "eba_CU:MUR",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Mauritius Rupee",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea604",
        },
        identifier: "eba_CU:MVR",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Rufiyaa",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea605",
        },
        identifier: "eba_CU:MWK",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Kwacha",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea606",
        },
        identifier: "eba_CU:MXN",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Mexican Peso",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea607",
        },
        identifier: "eba_CU:MYR",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Malaysian Ringgit",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea608",
        },
        identifier: "eba_CU:MZN",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Mozambique Metical",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea609",
        },
        identifier: "eba_CU:NAD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Namibia Dollar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea60a",
        },
        identifier: "eba_CU:NGN",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Naira",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea60b",
        },
        identifier: "eba_CU:NIO",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Cordoba Oro",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea60c",
        },
        identifier: "eba_CU:NOK",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Norwegian Krone",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea60d",
        },
        identifier: "eba_CU:NPR",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Nepalese Rupee",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea60e",
        },
        identifier: "eba_CU:NZD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "New Zealand Dollar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea60f",
        },
        identifier: "eba_CU:OMR",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Rial Omani",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea610",
        },
        identifier: "eba_CU:PAB",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Balboa",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea611",
        },
        identifier: "eba_CU:PEN",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Nuevo Sol",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea612",
        },
        identifier: "eba_CU:PGK",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Kina",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea613",
        },
        identifier: "eba_CU:PHP",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Philippine Peso",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea614",
        },
        identifier: "eba_CU:PKR",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Pakistan Rupee",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea615",
        },
        identifier: "eba_CU:PLN",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Zloty",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea616",
        },
        identifier: "eba_CU:PYG",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Guarani",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea617",
        },
        identifier: "eba_CU:QAR",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Qatari Rial",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea618",
        },
        identifier: "eba_CU:RON",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "New Romanian Leu",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea619",
        },
        identifier: "eba_CU:RSD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Serbian Dinar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea61a",
        },
        identifier: "eba_CU:RUB",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Russian Ruble",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea61b",
        },
        identifier: "eba_CU:RWF",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Rwanda Franc",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea61c",
        },
        identifier: "eba_CU:SAR",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Saudi Riyal",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea61d",
        },
        identifier: "eba_CU:SBD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Solomon Islands Dollar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea61e",
        },
        identifier: "eba_CU:SCR",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Seychelles Rupee",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea61f",
        },
        identifier: "eba_CU:SDG",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Sudanese Pound",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea620",
        },
        identifier: "eba_CU:SEK",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Swedish Krona",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea621",
        },
        identifier: "eba_CU:SGD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Singapore Dollar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea622",
        },
        identifier: "eba_CU:SHP",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Saint Helena Pound",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea623",
        },
        identifier: "eba_CU:SLL",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Leone",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea624",
        },
        identifier: "eba_CU:SOS",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Somali Shilling",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea625",
        },
        identifier: "eba_CU:SRD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Surinam Dollar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea626",
        },
        identifier: "eba_CU:SSP",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "South Sudanese Pound",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea627",
        },
        identifier: "eba_CU:STD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Dobra",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea628",
        },
        identifier: "eba_CU:SVC",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "El Salvador Colon",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea629",
        },
        identifier: "eba_CU:SYP",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Syrian Pound",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea62a",
        },
        identifier: "eba_CU:SZL",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Lilangeni",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea62b",
        },
        identifier: "eba_CU:THB",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Baht",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea62c",
        },
        identifier: "eba_CU:TJS",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Somoni",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea62d",
        },
        identifier: "eba_CU:TMT",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Turkmenistan New Manat",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea62e",
        },
        identifier: "eba_CU:TND",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Tunisian Dinar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea62f",
        },
        identifier: "eba_CU:TOP",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Pa’anga",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea630",
        },
        identifier: "eba_CU:TRY",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Turkish Lira",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea631",
        },
        identifier: "eba_CU:TTD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Trinidad and Tobago Dollar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea632",
        },
        identifier: "eba_CU:TWD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "New Taiwan Dollar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea633",
        },
        identifier: "eba_CU:TZS",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Tanzanian Shilling",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea634",
        },
        identifier: "eba_CU:UAH",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Hryvnia",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea635",
        },
        identifier: "eba_CU:UGX",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Uganda Shilling",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea636",
        },
        identifier: "eba_CU:USD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "US Dollar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea637",
        },
        identifier: "eba_CU:UYI",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Uruguay Peso en Unidades Indexadas (URUIURUI)",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea638",
        },
        identifier: "eba_CU:UYU",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Peso Uruguayo",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea639",
        },
        identifier: "eba_CU:UZS",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Uzbekistan Sum",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea63a",
        },
        identifier: "eba_CU:VEF",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Bolivar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea63b",
        },
        identifier: "eba_CU:VND",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Dong",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea63c",
        },
        identifier: "eba_CU:VUV",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Vatu",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea63d",
        },
        identifier: "eba_CU:WST",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Tala",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea63e",
        },
        identifier: "eba_CU:XCD",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "East Caribbean Dollar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea63f",
        },
        identifier: "eba_CU:YER",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Yemeni Rial",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea640",
        },
        identifier: "eba_CU:ZAR",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Rand",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea641",
        },
        identifier: "eba_CU:ZMK",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Zambian Kwacha (replaced January 1, 2013)",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea642",
        },
        identifier: "eba_CU:ZMW",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Zambian Kwacha",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea643",
        },
        identifier: "eba_CU:ZWL",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Zimbabwe Dollar",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea644",
        },
        identifier: "eba_CU:x46",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Other Currency (open axis tables)",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea645",
        },
        identifier: "eba_CU:XPF",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "CFP Franc",
      },
      {
        _id: {
          $oid: "677cc6698d94966b664ea646",
        },
        identifier: "eba_CU:CNH",
        listIdentifier: "LISTCURRENCY",
        titleIdentifier: "DESCCURRENCY",
        option: "Off-shore Yuan Renminbi",
      },
    ]