import {
  Box,
  Button,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import DynamicTable, {
  textStyle,
} from "components/UI/dynamicTable/DynamicTable";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_get_roi_data_table,
  gql_get_roi_section_data,
  gql_submit_roi_data,
  gql_submit_roi_section_answers,
  exportAllRoiData,
  gql_find_function,
  gql_third_party_service_providers,
  gql_find_entity,
  gql_find_contract_number,
  gql_delete_roi_row,
} from "redux/actions/vendorActions/vendorAction";
import transformTableData from "./utility/transformTableData";
import renderROITypes from "./utility/renderROITypes";
import Stack from "components/UI/Stack/Stack";
import "./roi.css";
import CustomButton from "components/UI/button/button";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import CustomTextfield from "./../../../UI/textfield/textfield";
import moment from "moment";
import { roiSectionQuestions } from "./ROITableQuestions";
import { Autocomplete } from "@material-ui/lab";
import debounce from "lodash.debounce";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

export const useROIStyles = makeStyles((theme) => ({
  menuItem: {
    whiteSpace: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 800, // Set the maximum width for the dropdown items
    borderTop: "1px solid rgba(0,0,0,0.03)",
  },
  selectMenu: {
    maxHeight: "400px",
    "& ul": {
      overflowX: "auto", // Enable horizontal scroll
    },
  },
  divider: {
    backgroundColor: theme.palette.grey[300], // Light gray color for the divider
  },
}));

const ROIScreen = ({ selectedROI }) => {
  const classes = useROIStyles();
  const { roiSectionData } = useSelector((state) => state.brmData);
  const { bankId, token } = useSelector((state) => state?.login);
  const dispatch = useDispatch();
  const [roiRow, setRoiRow] = useState([]);

  const [roiSubmissionData, setRoiSubmissionData] = useState([]);

  const [showSuggestions, setShowSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState([]);
  const [searchedResults, setSearchedResults] = useState([]);

  useEffect(() => {
    setRoiRow(roiSectionData?.length > 0 ? roiSectionData : [{}]);
    setRoiSubmissionData(roiSectionData?.length > 0 ? roiSectionData : [{}]);
  }, [roiSectionData]);

  const handleAddRow = () => {
    const newRow = {};
    setRoiRow((prevRows) => [...prevRows, newRow]);
    setRoiSubmissionData((prevRows) => [...prevRows, newRow]);
  };

  const handleOptionChange = (value, fieldName, rowIndex) => {
    setRoiRow((prevRows) =>
      prevRows.map((row, i) =>
        i === rowIndex ? { ...row, [fieldName?.name]: value } : row
      )
    );

    setRoiSubmissionData((prevRows) =>
      prevRows.map((row, i) =>
        i === rowIndex ? { ...row, [fieldName?.name]: value } : row
      )
    );
  };

  // console.log(roiSubmissionData);

  const handleSave = () => {
    const selectedSection = selectedROI?.title?.replaceAll(".", "_");
    const formattedData = roiSubmissionData.map((item) => {
      const {
        bankId: idOfBank,
        sectionName,
        entityId,
        vendorId,
        __typename,
        ...rest
      } = item;

      // Deep clone rest to avoid mutating the original data
      const cleanedRest = JSON.parse(JSON.stringify(rest));

      // Iterate over keys in rest and remove _id from nested objects
      Object.keys(cleanedRest).forEach((key) => {
        if (typeof cleanedRest[key] === "object" && cleanedRest[key] !== null) {
          delete cleanedRest[key]._id; // Remove _id if it exists
        }
      });

      return { ...cleanedRest, bankId };
    });

    const submitROISectionDataInput = {
      sections: [selectedSection],
      [selectedSection]: formattedData,
    };
    // console.log(formattedData);
    dispatch(gql_submit_roi_data(submitROISectionDataInput));
  };

  // console.log(roiSubmissionData);

  useEffect(() => {
    const sectionTitle = selectedROI?.title.replaceAll(".", "_");

    gql_get_roi_section_data(bankId, sectionTitle);

    setRoiRow([{}]);

    setRoiSubmissionData([{}]);
  }, [selectedROI?.title]);

  const exportAll = async () => {
    dispatch(exportAllRoiData(bankId, token));
  };

  const fetchEntityResults = useCallback(
    debounce((searchQuery, question) => {
      if (question === "Contractual_arrangement_reference_number") {
        gql_find_contract_number(bankId, searchQuery, setSearchedResults);
      } else if (
        question === "Identification_code_of_the_third_party_service_provider"
      ) {
        gql_third_party_service_providers(
          bankId,
          searchQuery,
          setSearchedResults
        );
      } else if (
        question === "LEI_of_the_financial_entity_head_office_of_the_branch" ||
        question === "LEI_of_the_financial_entity"
      ) {
        gql_find_entity(bankId, setSearchedResults, searchQuery);
      } else if (question === "Function_identifier") {
        gql_find_function(bankId, searchQuery, setSearchedResults);
      }
    }, 500),
    []
  );

  const handleFindEntity = (entityValue, question) => {
    fetchEntityResults(entityValue, question);
  };

  const handleDeleteRow = (id, sectionName, rowIndex) => {
    if (id) {
      gql_delete_roi_row(id, sectionName, bankId, gql_get_roi_section_data);
    } else {
      const formatRoiRow = roiRow.filter((item, index) => index !== rowIndex);
      setRoiRow(formatRoiRow);
      setRoiSubmissionData(formatRoiRow);
    }
  };

  // console.log(roiSubmissionData);

  return (
    <Box position="relative" style={{ height: "65vh" }}>
      <Box display={"flex"} justifyContent={"flex-end"} mb={1}>
        <CustomButton
          onClick={exportAll}
          size="small"
          color="primary"
          variant="outlined"
          startIcon={<OpenInNewRoundedIcon />}
        >
          Export All
        </CustomButton>
      </Box>
      <Box className="force-scrollbar">
        <DynamicTable
          headings={
            [
              // "RT.01.01",
              "RT.01.03",
              "RT.02.03",
              "RT.04.01",
              "RT.05.02",
              "RT.07.01",
              "RT.99.01",
            ].includes(selectedROI?.title)
              ? [
                  "",
                  ...roiSectionQuestions[selectedROI?.title]?.title?.map(
                    (item) => item?.name?.replaceAll("_", " ")
                  ),
                ] || []
              : roiSectionQuestions[selectedROI?.title]?.title?.map((item) =>
                  item?.name?.replaceAll("_", " ")
                ) || []
          }
          maxHeight="55vh"
          showDes={{ show: true, identifier: "instruction" }}
          // style={{ overflowX: "auto", scrollbarWidth: "thin" }}
          scroll={"none"}
        >
          {roiRow?.map((row, rowIndex) => {
            const {
              _id,
              bankId: idOfBank,
              sectionName,
              entityId,
              vendorId,
              ...rest
            } = row || {};

            return (
              <TableRow key={_id} style={{ overflowY: "scroll" }}>
                {[
                  // "RT.01.01",
                  "RT.01.03",
                  "RT.02.03",
                  "RT.04.01",
                  "RT.05.02",
                  "RT.07.01",
                  "RT.99.01",
                ].includes(selectedROI?.title) && (
                  <TableCell>
                    <CustomButton
                      color="primary"
                      onClick={() =>
                        handleDeleteRow(_id, sectionName, rowIndex)
                      }
                    >
                      <DeleteOutlineIcon />
                    </CustomButton>
                  </TableCell>
                )}
                {roiSectionQuestions[selectedROI?.title]?.title?.map(
                  (item, colIndex) => {
                    const formatItem =
                      typeof rest[item?.name] === "object"
                        ? rest[item?.name]?.option
                        : rest[item?.name];

                    return (
                      <>
                        <TableCell key={item?.name}>
                          {item?.questionType === "DROPDOWN" ? (
                            <Box display={"flex"} gridGap={2}>
                              <Select
                                value={formatItem}
                                variant="outlined"
                                size="small"
                                // disabled={isFrom === "createVendor" ? false : !edit}
                                MenuProps={{
                                  classes: { paper: classes.selectMenu },
                                }}
                                style={{
                                  height: "40px",
                                  minWidth: "300px",
                                  maxWidth: "500px",
                                }}
                                error={!formatItem && item?.mandatory}
                              >
                                {item?.options?.map((optItem, i) => (
                                  <MenuItem
                                    key={i}
                                    value={optItem?.option}
                                    className={classes.menuItem}
                                    onClick={(e) => {
                                      const { _id, ...rest } = optItem;
                                      handleOptionChange(rest, item, rowIndex);
                                    }}
                                  >
                                    {optItem?.option}
                                  </MenuItem>
                                ))}
                              </Select>
                              {!formatItem && item?.mandatory && (
                                <Typography style={{ color: "#C45446" }}>
                                  *
                                </Typography>
                              )}
                            </Box>
                          ) : item?.questionType === "Date" ? (
                            <Box display={"flex"} gridGap={2}>
                              <CustomTextfield
                                variant="outlined"
                                size="small"
                                style={{
                                  minWidth: "300px",
                                  maxWidth: "500px",
                                }}
                                error={formatItem === "" && item?.mandatory}
                                onChange={(e) =>
                                  handleOptionChange(
                                    e.target.value,
                                    item,
                                    rowIndex
                                  )
                                }
                                type="date"
                                // inputProps={{
                                //   min: minDate,
                                // }}

                                value={
                                  formatItem?.includes("T")
                                    ? formatItem?.split("T")[0]
                                    : formatItem
                                }
                                className={classes.textField}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                              {!formatItem && item?.mandatory && (
                                <Typography style={{ color: "#C45446" }}>
                                  *
                                </Typography>
                              )}
                            </Box>
                          ) : item?.questionType === "NUMBER" ? (
                            <Box display={"flex"} gridGap={2}>
                              <CustomTextfield
                                error={!formatItem && item?.mandatory}
                                variant="outlined"
                                type="number"
                                size="small"
                                value={formatItem}
                                style={{
                                  minWidth: "300px",
                                  maxWidth: "500px",
                                }}
                                fullWidth
                                onChange={(e) =>
                                  handleOptionChange(
                                    parseInt(e.target.value),
                                    item,
                                    rowIndex
                                  )
                                }
                              />
                              {!formatItem && item?.mandatory && (
                                <Typography style={{ color: "#C45446" }}>
                                  *
                                </Typography>
                              )}
                            </Box>
                          ) : item?.questionType === "SEARCH" ? (
                            <Box display={"flex"} gridGap={2}>
                              <Autocomplete
                                style={{
                                  minWidth: "300px",
                                  maxWidth: "500px",
                                }}
                                defaultValue={{ code: formatItem }}
                                // inputValue={rest[item?.name]}
                                options={searchedResults?.map(
                                  (result) => result
                                )}
                                getOptionLabel={(option) => option?.code || ""}
                                renderOption={(option) => (
                                  <Typography
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "100%",
                                    }}
                                  >
                                    <Typography>
                                      {option?.code || ""}
                                    </Typography>
                                    <Typography
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "20px",
                                      }}
                                    >
                                      {option?.name ? (
                                        <>
                                          <Typography
                                            variant="body2"
                                            style={{
                                              marginLeft: "20px",
                                              color: "#999",
                                            }}
                                          >
                                            {option?.name || ""}
                                          </Typography>
                                          {/* {option?.logo?.url && (
                                        <img
                                          src={option?.logo?.url}
                                          alt={option?.name}
                                          style={{ height: 20, width: 20 }}
                                          />
                                          )} */}
                                        </>
                                      ) : (
                                        <Typography
                                          variant="body2"
                                          style={{
                                            marginLeft: "20px",
                                            color: "#999",
                                          }}
                                        >
                                          Does not exist
                                        </Typography>
                                      )}
                                    </Typography>
                                  </Typography>
                                )}
                                size="small"
                                // value={vendorDetails?.isin}
                                renderInput={(params) => (
                                  <CustomTextfield
                                    {...params}
                                    error={!formatItem && item?.mandatory}
                                    variant="outlined"
                                    onChange={(e) =>
                                      handleFindEntity(
                                        e.target.value,
                                        item?.name
                                      )
                                    }
                                    onFocus={(e) => {
                                      // if (e.target.value === "") {
                                      handleFindEntity(
                                        e.target.value,
                                        item?.name
                                      );
                                      setShowSuggestions(true);
                                      // }
                                    }}
                                  />
                                )}
                                onChange={(event, newValue) => {
                                  setInputValue(newValue);
                                  if (!newValue) {
                                    handleFindEntity("", item?.name);
                                    setShowSuggestions(true);
                                  } else {
                                    handleOptionChange(
                                      newValue?.code,
                                      item,
                                      rowIndex
                                    );
                                  }
                                }}
                                filterOptions={(options, state) =>
                                  showSuggestions ? options : []
                                }
                                onInputChange={(event, newInputValue) => {
                                  // setInputValue(newInputValue);
                                  if (!newInputValue) {
                                    handleFindEntity("", item?.name);
                                    setShowSuggestions(true);
                                  }
                                  setShowSuggestions(newInputValue.length > 0);
                                }}
                              />
                              {!formatItem && item?.mandatory && (
                                <Typography style={{ color: "#C45446" }}>
                                  *
                                </Typography>
                              )}
                            </Box>
                          ) : (
                            <Box display={"flex"} gridGap={2}>
                              <CustomTextfield
                                error={!formatItem && item?.mandatory}
                                variant="outlined"
                                size="small"
                                style={{
                                  minWidth: "300px",
                                  maxWidth: "500px",
                                }}
                                value={formatItem}
                                onChange={(e) =>
                                  handleOptionChange(
                                    e.target.value,
                                    item,
                                    rowIndex
                                  )
                                }
                              />
                              {!formatItem && item?.mandatory && (
                                <Typography style={{ color: "#C45446" }}>
                                  *
                                </Typography>
                              )}
                            </Box>
                          )}
                        </TableCell>
                      </>
                    );
                  }
                )}
              </TableRow>
            );
          })}
        </DynamicTable>
      </Box>

      <Stack
        alignItem="center"
        spacing={10}
        position="absolute"
        right={10}
        bottom={0}
      >
        {[
          // "RT.01.01",
          "RT.01.03",
          "RT.02.03",
          "RT.04.01",
          "RT.05.02",
          "RT.07.01",
          "RT.99.01",
        ].includes(selectedROI?.title) && (
          <Button
            color="primary"
            variant="contained"
            onClick={handleAddRow}
            // disabled={quesAnswer?.length === 0}
          >
            Add Row
          </Button>
        )}
        {/* {selectedROI?.title === "RT.06.01" || (
          <Button
            color="primary"
            onClick={() => setQuesAns([])}
            disabled={quesAnswer?.length === 0}
          >
            Reset
          </Button>
        )} */}
        {/* {[
          "RT.01.01",
          "RT.01.03",
          "RT.02.03",
          "RT.04.01",
          "RT.05.02",
          "RT.07.01",
        ].includes(selectedROI?.title) && ( */}
        <Button
          color="primary"
          variant="contained"
          onClick={handleSave}
          // disabled={roiSectionQuestions[selectedROI?.title]?.title?.some(
          //   (question) => {
          //     // Find the corresponding entry in roiSubmissionData for this question
          //     const matchingEntry = roiSubmissionData.find(
          //       (entry) => entry.sectionName === question.sectionName
          //     );

          //     // If the question is mandatory, check if the corresponding field is filled
          //     return (
          //       question.isMandatory &&
          //       (!matchingEntry || !matchingEntry[question.fieldKey]) // Adjust fieldKey to match the question field name in roiSubmissionData
          //     );
          //   }
          // )}
        >
          Save
        </Button>
        {/* )} */}
      </Stack>
    </Box>
  );
};

export default ROIScreen;
