import React from "react";
import { useState } from "react";
import { Wrapper } from "../selfAssessment/selfAssessmentResponse";
import { useRef } from "react";
import { useEffect } from "react";
import { BlueBackgroundCard } from "../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import CustomTextfield from "./../../UI/textfield/textfield";
import {
  Typography,
  Box,
  Select,
  MenuItem,
  makeStyles,
  Divider,
} from "@material-ui/core";
import Textfield from "../../UI/textfield/textfield";
import Autocomplete from "@material-ui/lab/Autocomplete";
import countries from "utils/countries/countries";
import { useDispatch, useSelector } from "react-redux";
import {
  gqlGetRoiQuestions,
  gqlGetRoiSections,
} from "../../../redux/actions/roiActions/RoiActions";
import {
  gql_create_roi,
  gql_get_linkage_survey_questions,
  gql_get_linkage_survey_sections,
  gql_get_linkage_surveys,
  gql_roi_vendor_registration,
  gql_submit_linkage_survey_questions,
} from "../../../redux/actions/vendorActions/vendorAction";
import CustomButton from "../../UI/button/button";
import {
  SET_ROI_QUESTIONS,
  SET_ROI_RESPONSES,
  SET_ROI_SECTIONS,
} from "../../../constants/roiConstants";
import NoData from "../NoData/NoData";
import {
  SET_LINKAGE_SURVEY_QUESTIONS,
  SET_LINKAGE_SURVEY_SECTIONS,
} from "../../../constants/brmConstants";
import Stack from "components/UI/Stack/Stack";
import { vendorRegistrationQuestions } from "../v2/OrgDetails/questionsData";

export const useROIStyles = makeStyles((theme) => ({
  menuItem: {
    whiteSpace: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 800, // Set the maximum width for the dropdown items
    borderTop: "1px solid rgba(0,0,0,0.03)",
  },
  selectMenu: {
    maxHeight: "400px",
    "& ul": {
      overflowX: "auto", // Enable horizontal scroll
    },
  },
  divider: {
    backgroundColor: theme.palette.grey[300], // Light gray color for the divider
  },
}));

export default function RegisterOfInformation({
  isFrom,
  setAllResponseArray,
  allResponseArray,
  vendorId,
  pointerEvents,
}) {
  const classes = useROIStyles();

  const { bankId } = useSelector((state) => state?.login);

  const [branchCountry, setBranchCountry] = useState("");
  const [responseArray, setResponseArray] = useState({});
  const [finalArray, setFinalArray] = useState([]);
  const [completedScreen, setCompletedScreen] = useState(false);

  const disableSubmit =
    Object.entries(responseArray)?.length !==
      Object.entries(vendorRegistrationQuestions)?.length ||
    !Object.entries(responseArray)?.every(([key, value]) => value);

  // const closeDrawer = () => {
  //   // setEntityData(initialState);

  //   onClose();
  // };

  const handleSubmit = async () => {
    await gql_roi_vendor_registration({ regVendorInput: finalArray });
    setCompletedScreen(true);
  };

  const handleOptionChange = (value, question, index) => {
    setResponseArray((prev) => ({
      ...prev,
      [question?.name?.replaceAll(" ", "_")]: value,
    }));

    setFinalArray((prev) => ({
      ...prev,
      [question?.name?.replaceAll(" ", "_")]: value,
      bankId,
      vendorId,
    }));
  };

  console.log(finalArray);

  return (
    <Stack flexDirection={"column"} spacing={20}>
      {!completedScreen ? (
        <>
          <Box>
            {vendorRegistrationQuestions?.map((data, index) => (
              <Box
                key={index}
                style={{ marginRight: "10px", marginLeft: "15px" }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    marginBottom: "20px",
                  }}
                >
                  <Typography style={{ fontWeight: 500 }} variant="body2">
                    {data?.name}
                  </Typography>
                  {data?.type === "DROPDOWN" ? (
                    <Select
                      fullWidth
                      disabled={
                        data?.linkageAnswer &&
                        (data?.linkageAnswer !== null ? true : false)
                      }
                      value={
                        data?.linkageAnswer ||
                        responseArray[index]?.answer ||
                        data?.answer
                      }
                      // defaultValue={data?.answer}

                      // label="Entity"
                      variant="outlined"
                      size="small"
                      // disabled={isFrom === "createVendor" ? false : !edit}
                      MenuProps={{ classes: { paper: classes.selectMenu } }}
                    >
                      {data?.options?.map((item, i) => (
                        <MenuItem
                          key={i}
                          value={item?.option}
                          className={classes.menuItem}
                          onClick={(e) => {
                            const { _id, ...rest } = item;
                            handleOptionChange(rest, data);
                          }}
                        >
                          {item?.option}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : data?.type === "Country" ? (
                    <Autocomplete
                      getOptionSelected={(option, value) =>
                        option?.id === value?.id
                      }
                      id="country-select-demo"
                      sx={{ width: 300 }}
                      options={countries}
                      disabled={
                        data?.linkageAnswer &&
                        (data?.linkageAnswer !== null ? true : false)
                      }
                      // autoHighlight
                      getOptionLabel={(option) => option.label}
                      renderOption={(option) => option.label}
                      renderInput={(params) => (
                        <CustomTextfield
                          {...params}
                          // label="Country"
                          variant="outlined"
                          value={
                            data?.linkageAnswer || branchCountry || data?.answer
                          }
                          name="Country"
                        />
                      )}
                      onChange={(event, newValue) => {
                        newValue === null
                          ? setBranchCountry("")
                          : setBranchCountry(newValue.label);
                      }}
                      inputValue={
                        responseArray[index]?.answer || data?.answer || ""
                      }
                      onInputChange={(event, newInputValue) =>
                        handleOptionChange(newInputValue, data, index)
                      }
                    />
                  ) : data?.type === "Date" ? (
                    <CustomTextfield
                      style={{ marginTop: "10px" }}
                      name="date"
                      variant="outlined"
                      size="small"
                      fullWidth
                      label="Pick your date"
                      onChange={(e) =>
                        handleOptionChange(e.target.value, data, index)
                      }
                      id="date"
                      type="date"
                      // inputProps={{
                      //   min: minDate,
                      // }}
                      disabled={
                        data?.linkageAnswer &&
                        (data?.linkageAnswer !== null ? true : false)
                      }
                      value={
                        data?.linkageAnswer || responseArray?.length > 0
                          ? responseArray[index]?.answer
                          : data?.answer
                      }
                      // defaultValue="2017-05-24"
                      // className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  ) : data?.type === "NUMBER" ? (
                    <CustomTextfield
                      variant="outlined"
                      type="number"
                      //   value={
                      //     answerFormat(data, responseArray, index)

                      //     // (isFrom === "editVendor"
                      //     //   ? responseArray?.length > 0
                      //     //     ? responseArray[index]?.answer
                      //     //     : data?.answer
                      //     //   : responseArray?.length > 0
                      //     //   ? responseArray[index]?.answer
                      //     //   : data?.answer
                      //     //   ? data?.answer
                      //     //   : "")
                      //   }
                      size="small"
                      fullWidth
                      disabled={
                        data?.linkageAnswer &&
                        (data?.linkageAnswer !== null ? true : false)
                      }
                      onChange={(e) =>
                        handleOptionChange(e.target.value, data, index)
                      }
                    />
                  ) : (
                    <CustomTextfield
                      variant="outlined"
                      //   value={
                      //     answerFormat(data, responseArray, index)

                      //     // (isFrom === "editVendor"
                      //     //   ? responseArray?.length > 0
                      //     //     ? responseArray[index]?.answer
                      //     //     : data?.answer
                      //     //   : responseArray?.length > 0
                      //     //   ? responseArray[index]?.answer
                      //     //   : data?.answer
                      //     //   ? data?.answer
                      //     //   : "")
                      //   }
                      size="small"
                      fullWidth
                      disabled={
                        data?.linkageAnswer &&
                        (data?.linkageAnswer !== null ? true : false)
                      }
                      onChange={(e) =>
                        handleOptionChange(e.target.value, data, index)
                      }
                    />
                  )}

                  <Typography variant="body2" style={{ color: "#999" }}>
                    {data?.instruction}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <Box style={{ display: "flex", justifyContent: "center", gap: 10 }}>
            <CustomButton
              variant="outlined"
              // disabled={

              // }
              color="primary"
              onClick={() => {
                handleSubmit();
              }}
              style={{ textTransform: "none", fontSize: 12 }}
            >
              Save ROI
            </CustomButton>
          </Box>
        </>
      ) : (
        <Box style={{}}>
          <NoData
            message={"RT details are done, please click on create vendor!"}
          />
        </Box>
      )}
    </Stack>
  );
}
