export const roiCountryList = [{
    "_id": {
      "$oid": "677cc6698d94966b664ea4a6"
    },
    "identifier": "eba_GA:AL",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "ALBANIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4a7"
    },
    "identifier": "eba_GA:AT",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "AUSTRIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4a8"
    },
    "identifier": "eba_GA:BE",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "BELGIUM"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4a9"
    },
    "identifier": "eba_GA:BG",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "BULGARIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4aa"
    },
    "identifier": "eba_GA:CY",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "CYPRUS"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4ab"
    },
    "identifier": "eba_GA:CZ",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "CZECH REPUBLIC"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4ac"
    },
    "identifier": "eba_GA:DK",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "DENMARK"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4ad"
    },
    "identifier": "eba_GA:EE",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "ESTONIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4ae"
    },
    "identifier": "eba_GA:FI",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "FINLAND"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4af"
    },
    "identifier": "eba_GA:FR",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "FRANCE"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4b0"
    },
    "identifier": "eba_GA:DE",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "GERMANY"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4b1"
    },
    "identifier": "eba_GA:GR",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "GREECE"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4b2"
    },
    "identifier": "eba_GA:HU",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "HUNGARY"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4b3"
    },
    "identifier": "eba_GA:IE",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "IRELAND"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4b4"
    },
    "identifier": "eba_GA:IT",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "ITALY"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4b5"
    },
    "identifier": "eba_GA:JP",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "JAPAN"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4b6"
    },
    "identifier": "eba_GA:XK",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "KOSOVO"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4b7"
    },
    "identifier": "eba_GA:LV",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "LATVIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4b8"
    },
    "identifier": "eba_GA:LT",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "LITHUANIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4b9"
    },
    "identifier": "eba_GA:LU",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "LUXEMBOURG"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4ba"
    },
    "identifier": "eba_GA:MK",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "NORTH MACEDONIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4bb"
    },
    "identifier": "eba_GA:MT",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "MALTA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4bc"
    },
    "identifier": "eba_GA:NL",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "NETHERLANDS"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4bd"
    },
    "identifier": "eba_GA:NO",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "NORWAY"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4be"
    },
    "identifier": "eba_GA:PL",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "POLAND"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4bf"
    },
    "identifier": "eba_GA:PT",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "PORTUGAL"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4c0"
    },
    "identifier": "eba_GA:RO",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "ROMANIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4c1"
    },
    "identifier": "eba_GA:RU",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "RUSSIAN FEDERATION"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4c2"
    },
    "identifier": "eba_GA:RS",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SERBIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4c3"
    },
    "identifier": "eba_GA:SK",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SLOVAKIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4c4"
    },
    "identifier": "eba_GA:SI",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SLOVENIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4c5"
    },
    "identifier": "eba_GA:ES",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SPAIN"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4c6"
    },
    "identifier": "eba_GA:SE",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SWEDEN"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4c7"
    },
    "identifier": "eba_GA:CH",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SWITZERLAND"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4c8"
    },
    "identifier": "eba_GA:TR",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "TURKEY"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4c9"
    },
    "identifier": "eba_GA:UA",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "UKRAINE"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4ca"
    },
    "identifier": "eba_GA:GB",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "UNITED KINGDOM"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4cb"
    },
    "identifier": "eba_GA:US",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "UNITED STATES"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4cc"
    },
    "identifier": "eba_GA:AF",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "AFGHANISTAN"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4cd"
    },
    "identifier": "eba_GA:AX",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "ÅLAND ISLANDS"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4ce"
    },
    "identifier": "eba_GA:DZ",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "ALGERIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4cf"
    },
    "identifier": "eba_GA:AS",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "AMERICAN SAMOA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4d0"
    },
    "identifier": "eba_GA:AD",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "ANDORRA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4d1"
    },
    "identifier": "eba_GA:AO",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "ANGOLA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4d2"
    },
    "identifier": "eba_GA:AI",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "ANGUILLA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4d3"
    },
    "identifier": "eba_GA:AQ",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "ANTARCTICA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4d4"
    },
    "identifier": "eba_GA:AG",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "ANTIGUA AND BARBUDA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4d5"
    },
    "identifier": "eba_GA:AR",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "ARGENTINA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4d6"
    },
    "identifier": "eba_GA:AM",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "ARMENIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4d7"
    },
    "identifier": "eba_GA:AW",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "ARUBA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4d8"
    },
    "identifier": "eba_GA:AU",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "AUSTRALIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4d9"
    },
    "identifier": "eba_GA:AZ",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "AZERBAIJAN"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4da"
    },
    "identifier": "eba_GA:BS",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "BAHAMAS"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4db"
    },
    "identifier": "eba_GA:BH",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "BAHRAIN"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4dc"
    },
    "identifier": "eba_GA:BD",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "BANGLADESH"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4dd"
    },
    "identifier": "eba_GA:BB",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "BARBADOS"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4de"
    },
    "identifier": "eba_GA:BY",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "BELARUS"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4df"
    },
    "identifier": "eba_GA:BZ",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "BELIZE"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4e0"
    },
    "identifier": "eba_GA:BJ",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "BENIN"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4e1"
    },
    "identifier": "eba_GA:BM",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "BERMUDA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4e2"
    },
    "identifier": "eba_GA:BT",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "BHUTAN"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4e3"
    },
    "identifier": "eba_GA:BO",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "BOLIVIA, PLURINATIONAL STATE OF"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4e4"
    },
    "identifier": "eba_GA:BQ",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "BONAIRE, SINT EUSTATIUS AND SABA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4e5"
    },
    "identifier": "eba_GA:BA",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "BOSNIA AND HERZEGOVINA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4e6"
    },
    "identifier": "eba_GA:BW",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "BOTSWANA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4e7"
    },
    "identifier": "eba_GA:BV",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "BOUVET ISLAND"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4e8"
    },
    "identifier": "eba_GA:BR",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "BRAZIL"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4e9"
    },
    "identifier": "eba_GA:IO",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "BRITISH INDIAN OCEAN TERRITORY"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4ea"
    },
    "identifier": "eba_GA:BN",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "BRUNEI DARUSSALAM"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4eb"
    },
    "identifier": "eba_GA:BF",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "BURKINA FASO"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4ec"
    },
    "identifier": "eba_GA:BI",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "BURUNDI"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4ed"
    },
    "identifier": "eba_GA:KH",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "CAMBODIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4ee"
    },
    "identifier": "eba_GA:CM",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "CAMEROON"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4ef"
    },
    "identifier": "eba_GA:CA",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "CANADA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4f0"
    },
    "identifier": "eba_GA:CV",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "CAPE VERDE"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4f1"
    },
    "identifier": "eba_GA:KY",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "CAYMAN ISLANDS"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4f2"
    },
    "identifier": "eba_GA:CF",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "CENTRAL AFRICAN REPUBLIC"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4f3"
    },
    "identifier": "eba_GA:TD",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "CHAD"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4f4"
    },
    "identifier": "eba_GA:CL",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "CHILE"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4f5"
    },
    "identifier": "eba_GA:CN",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "CHINA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4f6"
    },
    "identifier": "eba_GA:CX",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "CHRISTMAS ISLAND"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4f7"
    },
    "identifier": "eba_GA:CC",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "COCOS (KEELING"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4f8"
    },
    "identifier": "eba_GA:CO",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "COLOMBIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4f9"
    },
    "identifier": "eba_GA:KM",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "COMOROS"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4fa"
    },
    "identifier": "eba_GA:CG",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "CONGO"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4fb"
    },
    "identifier": "eba_GA:CD",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "CONGO, THE DEMOCRATIC REPUBLIC OF THE"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4fc"
    },
    "identifier": "eba_GA:CK",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "COOK ISLANDS"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4fd"
    },
    "identifier": "eba_GA:CR",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "COSTA RICA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4fe"
    },
    "identifier": "eba_GA:CI",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "CÔTE D'IVOIRE"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea4ff"
    },
    "identifier": "eba_GA:HR",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "CROATIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea500"
    },
    "identifier": "eba_GA:CU",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "CUBA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea501"
    },
    "identifier": "eba_GA:CW",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "CURAÇAO"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea502"
    },
    "identifier": "eba_GA:DJ",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "DJIBOUTI"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea503"
    },
    "identifier": "eba_GA:DM",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "DOMINICA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea504"
    },
    "identifier": "eba_GA:DO",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "DOMINICAN REPUBLIC"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea505"
    },
    "identifier": "eba_GA:EC",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "ECUADOR"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea506"
    },
    "identifier": "eba_GA:EG",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "EGYPT"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea507"
    },
    "identifier": "eba_GA:SV",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "EL SALVADOR"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea508"
    },
    "identifier": "eba_GA:GQ",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "EQUATORIAL GUINEA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea509"
    },
    "identifier": "eba_GA:ER",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "ERITREA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea50a"
    },
    "identifier": "eba_GA:ET",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "ETHIOPIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea50b"
    },
    "identifier": "eba_GA:FK",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "FALKLAND ISLANDS (MALVINAS"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea50c"
    },
    "identifier": "eba_GA:FO",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "FAROE ISLANDS"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea50d"
    },
    "identifier": "eba_GA:FJ",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "FIJI"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea50e"
    },
    "identifier": "eba_GA:GF",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "FRENCH GUIANA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea50f"
    },
    "identifier": "eba_GA:PF",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "FRENCH POLYNESIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea510"
    },
    "identifier": "eba_GA:TF",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "FRENCH SOUTHERN TERRITORIES"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea511"
    },
    "identifier": "eba_GA:GA",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "GABON"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea512"
    },
    "identifier": "eba_GA:GM",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "GAMBIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea513"
    },
    "identifier": "eba_GA:GE",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "GEORGIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea514"
    },
    "identifier": "eba_GA:GH",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "GHANA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea515"
    },
    "identifier": "eba_GA:GI",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "GIBRALTAR"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea516"
    },
    "identifier": "eba_GA:GL",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "GREENLAND"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea517"
    },
    "identifier": "eba_GA:GD",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "GRENADA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea518"
    },
    "identifier": "eba_GA:GP",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "GUADELOUPE"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea519"
    },
    "identifier": "eba_GA:GU",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "GUAM"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea51a"
    },
    "identifier": "eba_GA:GT",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "GUATEMALA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea51b"
    },
    "identifier": "eba_GA:GG",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "GUERNSEY"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea51c"
    },
    "identifier": "eba_GA:GN",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "GUINEA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea51d"
    },
    "identifier": "eba_GA:GW",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "GUINEA-BISSAU"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea51e"
    },
    "identifier": "eba_GA:GY",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "GUYANA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea51f"
    },
    "identifier": "eba_GA:HT",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "HAITI"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea520"
    },
    "identifier": "eba_GA:HM",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "HEARD ISLAND AND MCDONALD ISLANDS"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea521"
    },
    "identifier": "eba_GA:VA",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "HOLY SEE (VATICAN CITY STATE"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea522"
    },
    "identifier": "eba_GA:HN",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "HONDURAS"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea523"
    },
    "identifier": "eba_GA:HK",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "HONG KONG"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea524"
    },
    "identifier": "eba_GA:IS",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "ICELAND"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea525"
    },
    "identifier": "eba_GA:IN",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "INDIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea526"
    },
    "identifier": "eba_GA:ID",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "INDONESIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea527"
    },
    "identifier": "eba_GA:IR",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "IRAN, ISLAMIC REPUBLIC OF"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea528"
    },
    "identifier": "eba_GA:IQ",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "IRAQ"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea529"
    },
    "identifier": "eba_GA:IM",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "ISLE OF MAN"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea52a"
    },
    "identifier": "eba_GA:IL",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "ISRAEL"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea52b"
    },
    "identifier": "eba_GA:JM",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "JAMAICA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea52c"
    },
    "identifier": "eba_GA:JE",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "JERSEY"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea52d"
    },
    "identifier": "eba_GA:JO",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "JORDAN"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea52e"
    },
    "identifier": "eba_GA:KZ",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "KAZAKHSTAN"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea52f"
    },
    "identifier": "eba_GA:KE",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "KENYA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea530"
    },
    "identifier": "eba_GA:KI",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "KIRIBATI"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea531"
    },
    "identifier": "eba_GA:KP",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea532"
    },
    "identifier": "eba_GA:KR",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "KOREA, REPUBLIC OF"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea533"
    },
    "identifier": "eba_GA:KW",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "KUWAIT"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea534"
    },
    "identifier": "eba_GA:KG",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "KYRGYZSTAN"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea535"
    },
    "identifier": "eba_GA:LA",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "LAO PEOPLE'S DEMOCRATIC REPUBLIC"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea536"
    },
    "identifier": "eba_GA:LB",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "LEBANON"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea537"
    },
    "identifier": "eba_GA:LS",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "LESOTHO"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea538"
    },
    "identifier": "eba_GA:LR",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "LIBERIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea539"
    },
    "identifier": "eba_GA:LY",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "LIBYA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea53a"
    },
    "identifier": "eba_GA:LI",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "LIECHTENSTEIN"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea53b"
    },
    "identifier": "eba_GA:MO",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "MACAO"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea53c"
    },
    "identifier": "eba_GA:MG",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "MADAGASCAR"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea53d"
    },
    "identifier": "eba_GA:MW",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "MALAWI"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea53e"
    },
    "identifier": "eba_GA:MY",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "MALAYSIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea53f"
    },
    "identifier": "eba_GA:MV",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "MALDIVES"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea540"
    },
    "identifier": "eba_GA:ML",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "MALI"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea541"
    },
    "identifier": "eba_GA:MH",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "MARSHALL ISLANDS"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea542"
    },
    "identifier": "eba_GA:MQ",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "MARTINIQUE"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea543"
    },
    "identifier": "eba_GA:MR",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "MAURITANIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea544"
    },
    "identifier": "eba_GA:MU",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "MAURITIUS"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea545"
    },
    "identifier": "eba_GA:YT",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "MAYOTTE"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea546"
    },
    "identifier": "eba_GA:MX",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "MEXICO"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea547"
    },
    "identifier": "eba_GA:FM",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "MICRONESIA, FEDERATED STATES OF"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea548"
    },
    "identifier": "eba_GA:MD",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "MOLDOVA, REPUBLIC OF"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea549"
    },
    "identifier": "eba_GA:MC",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "MONACO"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea54a"
    },
    "identifier": "eba_GA:MN",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "MONGOLIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea54b"
    },
    "identifier": "eba_GA:ME",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "MONTENEGRO"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea54c"
    },
    "identifier": "eba_GA:MS",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "MONTSERRAT"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea54d"
    },
    "identifier": "eba_GA:MA",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "MOROCCO"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea54e"
    },
    "identifier": "eba_GA:MZ",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "MOZAMBIQUE"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea54f"
    },
    "identifier": "eba_GA:MM",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "MYANMAR"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea550"
    },
    "identifier": "eba_GA:NA",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "NAMIBIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea551"
    },
    "identifier": "eba_GA:NR",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "NAURU"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea552"
    },
    "identifier": "eba_GA:NP",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "NEPAL"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea553"
    },
    "identifier": "eba_GA:NC",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "NEW CALEDONIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea554"
    },
    "identifier": "eba_GA:NZ",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "NEW ZEALAND"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea555"
    },
    "identifier": "eba_GA:NI",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "NICARAGUA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea556"
    },
    "identifier": "eba_GA:NE",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "NIGER"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea557"
    },
    "identifier": "eba_GA:NG",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "NIGERIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea558"
    },
    "identifier": "eba_GA:NU",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "NIUE"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea559"
    },
    "identifier": "eba_GA:NF",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "NORFOLK ISLAND"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea55a"
    },
    "identifier": "eba_GA:MP",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "NORTHERN MARIANA ISLANDS"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea55b"
    },
    "identifier": "eba_GA:OM",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "OMAN"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea55c"
    },
    "identifier": "eba_GA:PK",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "PAKISTAN"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea55d"
    },
    "identifier": "eba_GA:PW",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "PALAU"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea55e"
    },
    "identifier": "eba_GA:PS",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "PALESTINIAN TERRITORY, OCCUPIED"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea55f"
    },
    "identifier": "eba_GA:PA",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "PANAMA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea560"
    },
    "identifier": "eba_GA:PG",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "PAPUA NEW GUINEA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea561"
    },
    "identifier": "eba_GA:PY",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "PARAGUAY"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea562"
    },
    "identifier": "eba_GA:PE",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "PERU"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea563"
    },
    "identifier": "eba_GA:PH",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "PHILIPPINES"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea564"
    },
    "identifier": "eba_GA:PN",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "PITCAIRN"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea565"
    },
    "identifier": "eba_GA:PR",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "PUERTO RICO"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea566"
    },
    "identifier": "eba_GA:QA",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "QATAR"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea567"
    },
    "identifier": "eba_GA:RE",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "RÉUNION"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea568"
    },
    "identifier": "eba_GA:RW",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "RWANDA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea569"
    },
    "identifier": "eba_GA:BL",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SAINT BARTHÉLEMY"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea56a"
    },
    "identifier": "eba_GA:SH",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea56b"
    },
    "identifier": "eba_GA:KN",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SAINT KITTS AND NEVIS"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea56c"
    },
    "identifier": "eba_GA:LC",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SAINT LUCIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea56d"
    },
    "identifier": "eba_GA:MF",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SAINT MARTIN (FRENCH PART"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea56e"
    },
    "identifier": "eba_GA:PM",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SAINT PIERRE AND MIQUELON"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea56f"
    },
    "identifier": "eba_GA:VC",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SAINT VINCENT AND THE GRENADINES"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea570"
    },
    "identifier": "eba_GA:WS",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SAMOA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea571"
    },
    "identifier": "eba_GA:SM",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SAN MARINO"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea572"
    },
    "identifier": "eba_GA:ST",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SAO TOME AND PRINCIPE"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea573"
    },
    "identifier": "eba_GA:SA",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SAUDI ARABIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea574"
    },
    "identifier": "eba_GA:SN",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SENEGAL"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea575"
    },
    "identifier": "eba_GA:SC",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SEYCHELLES"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea576"
    },
    "identifier": "eba_GA:SL",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SIERRA LEONE"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea577"
    },
    "identifier": "eba_GA:SG",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SINGAPORE"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea578"
    },
    "identifier": "eba_GA:SX",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SINT MAARTEN (DUTCH PART"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea579"
    },
    "identifier": "eba_GA:SB",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SOLOMON ISLANDS"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea57a"
    },
    "identifier": "eba_GA:SO",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SOMALIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea57b"
    },
    "identifier": "eba_GA:ZA",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SOUTH AFRICA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea57c"
    },
    "identifier": "eba_GA:GS",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea57d"
    },
    "identifier": "eba_GA:SS",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SOUTH SUDAN"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea57e"
    },
    "identifier": "eba_GA:LK",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SRI LANKA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea57f"
    },
    "identifier": "eba_GA:SD",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SUDAN"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea580"
    },
    "identifier": "eba_GA:SR",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SURINAME"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea581"
    },
    "identifier": "eba_GA:SJ",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SVALBARD AND JAN MAYEN"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea582"
    },
    "identifier": "eba_GA:SZ",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SWAZILAND"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea583"
    },
    "identifier": "eba_GA:SY",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "SYRIAN ARAB REPUBLIC"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea584"
    },
    "identifier": "eba_GA:TW",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "TAIWAN, PROVINCE OF CHINA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea585"
    },
    "identifier": "eba_GA:TJ",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "TAJIKISTAN"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea586"
    },
    "identifier": "eba_GA:TZ",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "TANZANIA, UNITED REPUBLIC OF"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea587"
    },
    "identifier": "eba_GA:TH",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "THAILAND"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea588"
    },
    "identifier": "eba_GA:TL",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "TIMOR-LESTE"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea589"
    },
    "identifier": "eba_GA:TG",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "TOGO"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea58a"
    },
    "identifier": "eba_GA:TK",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "TOKELAU"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea58b"
    },
    "identifier": "eba_GA:TO",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "TONGA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea58c"
    },
    "identifier": "eba_GA:TT",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "TRINIDAD AND TOBAGO"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea58d"
    },
    "identifier": "eba_GA:TN",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "TUNISIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea58e"
    },
    "identifier": "eba_GA:TM",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "TURKMENISTAN"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea58f"
    },
    "identifier": "eba_GA:TC",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "TURKS AND CAICOS ISLANDS"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea590"
    },
    "identifier": "eba_GA:TV",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "TUVALU"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea591"
    },
    "identifier": "eba_GA:UG",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "UGANDA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea592"
    },
    "identifier": "eba_GA:AE",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "UNITED ARAB EMIRATES"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea593"
    },
    "identifier": "eba_GA:UM",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "UNITED STATES MINOR OUTLYING ISLANDS"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea594"
    },
    "identifier": "eba_GA:UY",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "URUGUAY"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea595"
    },
    "identifier": "eba_GA:UZ",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "UZBEKISTAN"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea596"
    },
    "identifier": "eba_GA:VU",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "VANUATU"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea597"
    },
    "identifier": "eba_GA:VE",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "VENEZUELA, BOLIVARIAN REPUBLIC OF"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea598"
    },
    "identifier": "eba_GA:VN",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "VIET NAM"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea599"
    },
    "identifier": "eba_GA:VG",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "VIRGIN ISLANDS, BRITISH"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea59a"
    },
    "identifier": "eba_GA:VI",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "VIRGIN ISLANDS, U.S."
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea59b"
    },
    "identifier": "eba_GA:WF",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "WALLIS AND FUTUNA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea59c"
    },
    "identifier": "eba_GA:EH",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "WESTERN SAHARA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea59d"
    },
    "identifier": "eba_GA:YE",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "YEMEN"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea59e"
    },
    "identifier": "eba_GA:ZM",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "ZAMBIA"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea59f"
    },
    "identifier": "eba_GA:ZW",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "ZIMBABWE"
  },
  {
    "_id": {
      "$oid": "677cc6698d94966b664ea5a0"
    },
    "identifier": "eba_GA:x28",
    "listIdentifier": "LISTCOUNTRY",
    "titleIdentifier": "DESCCOUNTRY",
    "option": "Other Countries"
  }]