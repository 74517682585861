import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Chip, Grid, Typography } from "@material-ui/core";
import { BlueBackgroundCard } from "../../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import { TopicData, TopicHeader } from "../../esgCompanyProfile/esgComponents";

function MonitoringOverallScore({ overallScore, severity }) {
  const defaultData = {
    overall: {
      comment: "Some Comment",
      score: 0,
    },
  };

  const scoresDefaultData = {
    overall: {
      "TPSP Name": "PayWise",
      "Onboarding Start Date": "01-Jan-2023",
      "Onboarding Completed Date": "15-Jan-2023",
      "System Integration Completed": "Yes",
      "Compliance Checks Completed": "Yes",
      "Initial Training Provided": "Yes",
      "Initial Risk Assessment": "Pass",
      "Final Onboarding Approval": "Yes",
    },
  };

  return (
    <Box style={{ width: "100%" }}>
      {defaultData && (
        <Grid md={12}>
          <BlueBackgroundCard heading={"Overall Score"} />
          <Box
            display={"flex"}
            alignItems={"center"}
            gridGap={"15px"}
            marginLeft={"20px"}
          >
            {!!overallScore && (
              <Chip
                label={
                  Math.round(overallScore) <= 33
                    ? "Low"
                    : Math.round(overallScore) > 33 &&
                      Math.round(overallScore) <= 66
                    ? "Mid"
                    : "High"
                }
                style={{
                  color:
                    Math.round(overallScore) <= 33
                      ? severity("text", "red")
                      : Math.round(overallScore) > 33 &&
                        Math.round(overallScore) <= 66
                      ? severity("text", "yellow")
                      : severity("text", "green"),
                  backgroundColor:
                    Math.round(overallScore) <= 33
                      ? severity("bg", "red")
                      : Math.round(overallScore) > 33 &&
                        Math.round(overallScore) <= 66
                      ? severity("bg", "yellow")
                      : severity("bg", "green"),
                }}
                size="small"
              />
            )}{" "}
            <Typography
              style={{
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "40px" }}>
                {Math.round(overallScore) || "-"}
              </span>
              <span style={{ fontSize: "20px" }}>/</span>
              <span>100</span>
            </Typography>
          </Box>
          <Typography
            style={{
              fontSize: "13px",
              padding: "5px",
              // marginBottom: "10px",
            }}
            // component={Paper}
          >
            The score reflects the vendor's performance and can guide them in
            making informed decisions.
          </Typography>
          {/* <Grid justifyContent="space-around">
            <Grid item md={5}>
              <div
                style={{
                  width: "95%",
                  borderRadius: 8,
                  backgroundColor: "#FBFBFB",
                  marginTop: "20px",
                }}
              >
                <TopicHeader
                  topic={"Overall Score"}
                  // score={value?.score}
                  noIcon
                />
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  gridGap={10}
                >
                  <Box flex={1}>
                    {scoresDefaultData &&
                      Object.entries(scoresDefaultData["overall"])
                        .slice(0, 4)
                        ?.map(([section, score], i) => (
                          <TopicData
                            key={i}
                            topic={section}
                            // score={score}
                            textValue={score}
                            marginTop={"10px"}
                          />
                        ))}
                  </Box>
                  <Box flex={1}>
                    {scoresDefaultData &&
                      Object.entries(scoresDefaultData["overall"])
                        .slice(4)
                        ?.map(([section, score], i) => (
                          <TopicData
                            key={i}
                            topic={section}
                            // score={score}
                            textValue={score}
                            marginTop={"10px"}
                          />
                        ))}
                  </Box>
                </Box>
              </div>
            </Grid>
          </Grid> */}
        </Grid>
      )}
    </Box>
  );
}

export default MonitoringOverallScore;
