import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Chip,
  Grid,
  List,
  ListItem,
  Paper,
  Typography,
} from "@material-ui/core";
import { BlueBackgroundCard } from "../../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import { TopicData, TopicHeader } from "../../esgCompanyProfile/esgComponents";
import { gql_get_vendor_Assessment_score } from "redux/actions/esgDiagnosisActions/esgDiagnosisActions";
import "components/widgets/tprmRisk/trpmRiskReport/print.css";

function WazuhData({ severity }) {
  const { vendorAssessmentScore } = useSelector(
    (state) => state.esgDiagnosisReducer
  );

  const { vendorBasicDetails } = useSelector((state) => state.brmData);

  const dispatch = useDispatch();
  const storedWazuhGroup = localStorage.getItem("wazuhGroup");

  useEffect(() => {
    dispatch(
      gql_get_vendor_Assessment_score(
        vendorBasicDetails?.wazuhGroup || storedWazuhGroup
      )
    );
  }, []);

  const scoresDefaultData = [
    {
      topic: "Security Information Management",
      score: 0,
      subTopics: [
        {
          subTopic: "Integrity Monitoring",
          score: 0,
        },
      ],
    },
    {
      topic: "Threat Detection and Response",
      score: 0,
      subTopics: [
        {
          subTopic: "MITRE ATT&CK",
          score: 0,
        },
      ],
    },
    {
      topic: "Audit and Policy Monitoring",
      score: 0,
      subTopics: [
        {
          subTopic: "Policy Monitoring",
          score: 0,
        },
      ],
    },
    {
      topic: "Regulatory Compliance",
      score: 0,
      subTopics: [
        {
          subTopic: "NIST 800-53",
          score: 0,
        },
      ],
    },
  ];

  return (
    <Grid container style={{ width: "100%", pageBreakBefore: "always" }}>
      <Box style={{ width: "100%" }}>
        <BlueBackgroundCard heading={"Wazuh Score"} />
        <Box
          display={"flex"}
          alignItems={"center"}
          gridGap={"15px"}
          marginLeft={"20px"}
        >
          {!!vendorAssessmentScore?.score && (
            <Chip
              label={
                Math.round(vendorAssessmentScore?.score) <= 33
                  ? "Low"
                  : Math.round(vendorAssessmentScore?.score) > 33 &&
                    Math.round(vendorAssessmentScore?.score) <= 66
                  ? "Mid"
                  : "High"
              }
              style={{
                color:
                  Math.round(vendorAssessmentScore?.score) <= 33
                    ? severity("text", "red")
                    : Math.round(vendorAssessmentScore?.score) > 33 &&
                      Math.round(vendorAssessmentScore?.score) <= 66
                    ? severity("text", "yellow")
                    : severity("text", "green"),
                backgroundColor:
                  Math.round(vendorAssessmentScore?.score) <= 33
                    ? severity("bg", "red")
                    : Math.round(vendorAssessmentScore?.score) > 33 &&
                      Math.round(vendorAssessmentScore?.score) <= 66
                    ? severity("bg", "yellow")
                    : severity("bg", "green"),
              }}
              size="small"
            />
          )}
          <Typography
            style={{
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: "40px" }}>
              {Math.round(vendorAssessmentScore?.score) || "-"}
            </span>
            <span style={{ fontSize: "20px" }}>/</span>
            <span>100</span>
          </Typography>
        </Box>
        <Typography
          style={{
            fontSize: "13px",
            padding: "5px",
            // marginBottom: "10px",
          }}
          // component={Paper}
        >
          The score reflects the vendor's performance and can guide them in
          making informed decisions.
        </Typography>
      </Box>
      {(vendorAssessmentScore?.topics?.length > 0
        ? vendorAssessmentScore?.topics
        : scoresDefaultData
      )?.map((item, index) => (
        <Grid
          md={6}
          className="on-print-width-2-column"
          style={{ pageBreakInside: "avoid" }}
        >
          <BlueBackgroundCard heading={item?.topic} />
          <Box
            display={"flex"}
            alignItems={"center"}
            gridGap={"15px"}
            marginLeft={"20px"}
          >
            {!!item?.score && (
              <Chip
                label={
                  Math.round(item?.score) <= 33
                    ? "Low"
                    : Math.round(item?.score) > 33 &&
                      Math.round(item?.score) <= 66
                    ? "Mid"
                    : "High"
                }
                style={{
                  color:
                    Math.round(item?.score) <= 33
                      ? severity("text", "red")
                      : Math.round(item?.score) > 33 &&
                        Math.round(item?.score) <= 66
                      ? severity("text", "yellow")
                      : severity("text", "green"),
                  backgroundColor:
                    Math.round(item?.score) <= 33
                      ? severity("bg", "red")
                      : Math.round(item?.score) > 33 &&
                        Math.round(item?.score) <= 66
                      ? severity("bg", "yellow")
                      : severity("bg", "green"),
                }}
                size="small"
              />
            )}
            <Typography
              style={{
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "40px" }}>
                {Math.round(item?.score) || "-"}
              </span>
              <span style={{ fontSize: "20px" }}>/</span>
              <span>100</span>
            </Typography>
          </Box>
          <Typography
            style={{
              fontSize: "13px",
              padding: "5px",
              marginBottom: "10px",
            }}
            // component={Paper}
          >
            The score reflects the vendor's performance and can guide them in
            making informed decisions.
          </Typography>
          <Grid justifyContent="space-around">
            <Grid item md={5}>
              {/* <CompanyProfile
                    name={nameFormat(key)}
                    scores={{ score: value?.score }}
                    label={value?.comment}
                    customSize={250}
                    hideBottom
                  /> */}
            </Grid>
            <Grid item md={5}>
              <div
                key={index}
                style={{
                  width: "95%",
                  borderRadius: 8,
                  backgroundColor: "#FBFBFB",
                  marginTop: "20px",
                }}
              >
                <TopicHeader
                  topic={item?.topic}
                  // score={value?.score}
                  noIcon
                />
                {item?.subTopics?.length > 0 &&
                  item?.subTopics?.map((subTopic, i) => (
                    <TopicData
                      key={i}
                      topic={subTopic?.subTopic}
                      // score={score}
                      textValue={subTopic?.score}
                      marginTop={"10px"}
                    />
                  ))}
              </div>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default WazuhData;
