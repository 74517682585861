import { useState } from "react";
import { Box, Tabs, Tab, MuiThemeProvider } from "@material-ui/core";
import { appTheme } from "components/widgets/riskAssessment/brmDetaails";
import { appbarStyles } from "components/widgets/surveyQuestions/surveyQuestions";

// components
import OrgStructure from "./OrgStructure";
import EntityList from "./EntityList";
import Stakeholders from "./Stakeholders";
import FunctionRegistration from "./FunctionRegistration";
import ContractRegistration from "./ContractRegistration";

// Array of tab details
const tabs = [
  { label: "Organisation Structure", component: <OrgStructure /> },
  { label: "Entity List", component: <EntityList /> },
  { label: "Stakeholders", component: <Stakeholders /> },
  { label: "Function Registration", component: <FunctionRegistration /> },
  { label: "Contract Registration", component: <ContractRegistration /> },
];

const OrgDetailsScreens = () => {
  const classes = appbarStyles();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box>
      <MuiThemeProvider theme={appTheme}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="org details tabs"
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              label={<span className={classes.tabLabel}>{tab.label}</span>}
            />
          ))}
        </Tabs>
      </MuiThemeProvider>
      <Box mt={2}>{tabs[selectedTab].component}</Box>
    </Box>
  );
};

export default OrgDetailsScreens;
