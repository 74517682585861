import {
  Box,
  Chip,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import DynamicDrawer from "components/UI/dynamicDrawer/DynamicDrawer";
import RequiredField from "components/UI/inputField/requiredShow";
import Stack from "components/UI/Stack/Stack";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_create_roi_functions,
  gql_find_entity,
  gql_get_linkage_survey_questions,
  gql_get_roi_functions,
  gql_submit_roi_section_answers,
} from "redux/actions/vendorActions/vendorAction";
import { gql_add_enitity } from "redux/actions/vendorActions/vendorOrgAction";
import countries from "utils/countries/countries";
import CustomTextfield from "./../../../UI/textfield/textfield";
import { functionRegistrationQuestions } from "./questionsData";
import debounce from "lodash.debounce";

export const useROIStyles = makeStyles((theme) => ({
  menuItem: {
    whiteSpace: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 800, // Set the maximum width for the dropdown items
    borderTop: "1px solid rgba(0,0,0,0.03)",
  },
  selectMenu: {
    maxHeight: "400px",
    "& ul": {
      overflowX: "auto", // Enable horizontal scroll
    },
  },
  divider: {
    backgroundColor: theme.palette.grey[300], // Light gray color for the divider
  },
}));

const FunctionRegistrationDrawer = ({ open, onClose }) => {
  const { action } = useTheme().palette;

  const classes = useROIStyles();

  const initialState = {
    name: "",
    entityType: "",
    userDefinedId: "",
    streetName: "",
    state: "",
  };

  const { bankId } = useSelector((state) => state?.login);

  const [branchCountry, setBranchCountry] = useState("");
  const [responseArray, setResponseArray] = useState({});
  const [finalArray, setFinalArray] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState([]);
  const [searchedResults, setSearchedResults] = useState([]);

  const disableSubmit =
    Object.entries(responseArray)?.length !==
      Object.entries(functionRegistrationQuestions)?.length ||
    !Object.entries(responseArray)?.every(([key, value]) => value);

  const closeDrawer = () => {
    // setEntityData(initialState);

    onClose();
  };

  const handleSubmit = async () => {
    await gql_create_roi_functions({
      createROIFunction: finalArray,
      bankId,
    });
    closeDrawer();
  };

  const handleOptionChange = (value, question, index) => {
    setResponseArray((prev) => ({
      ...prev,
      [question?.name?.replaceAll(" ", "_")]: value,
    }));

    setFinalArray((prev) => ({
      ...prev,
      [question?.name?.replaceAll(" ", "_")]: value,
      bankId,
    }));
  };

  const fetchEntityResults = useCallback(
    debounce((searchQuery) => {
      gql_find_entity(bankId, setSearchedResults, searchQuery);
    }, 500),
    []
  );

  const handleFindEntity = (entityValue) => {
    fetchEntityResults(entityValue);
  };

  console.log(finalArray);

  return (
    <DynamicDrawer
      hasDrawerOpen={open}
      handleClose={onClose}
      heading={"Add Function"}
      buttonText={"Add Function"}
      disableSubmit={disableSubmit}
      submitClick={handleSubmit}
      style={{ padding: 10 }}
      dynamicWidth={"800px"}
    >
      <Stack flexDirection={"column"} spacing={20}>
        <Box>
          <>
            {functionRegistrationQuestions?.map((data, index) => (
              <Box
                key={index}
                style={{ marginRight: "10px", marginLeft: "15px" }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    marginBottom: "20px",
                  }}
                >
                  <Typography style={{ fontWeight: 500 }} variant="body2">
                    {data?.name}
                  </Typography>
                  {data?.type === "DROPDOWN" ? (
                    <Select
                      fullWidth
                      disabled={
                        data?.linkageAnswer &&
                        (data?.linkageAnswer !== null ? true : false)
                      }
                      value={
                        data?.linkageAnswer ||
                        responseArray[index]?.answer ||
                        data?.answer
                      }
                      // defaultValue={data?.answer}

                      // label="Entity"
                      variant="outlined"
                      size="small"
                      // disabled={isFrom === "createVendor" ? false : !edit}
                      MenuProps={{ classes: { paper: classes.selectMenu } }}
                    >
                      {data?.options?.map((item, i) => (
                        <MenuItem
                          key={i}
                          value={item?.option}
                          className={classes.menuItem}
                          onClick={(e) => {
                            const { _id, ...rest } = item;
                            handleOptionChange(rest, data);
                          }}
                        >
                          {item?.option}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : data?.type === "Country" ? (
                    <Autocomplete
                      getOptionSelected={(option, value) =>
                        option?.id === value?.id
                      }
                      id="country-select-demo"
                      sx={{ width: 300 }}
                      options={countries}
                      disabled={
                        data?.linkageAnswer &&
                        (data?.linkageAnswer !== null ? true : false)
                      }
                      // autoHighlight
                      getOptionLabel={(option) => option.label}
                      renderOption={(option) => option.label}
                      renderInput={(params) => (
                        <CustomTextfield
                          {...params}
                          // label="Country"
                          variant="outlined"
                          value={
                            data?.linkageAnswer || branchCountry || data?.answer
                          }
                          name="Country"
                        />
                      )}
                      onChange={(event, newValue) => {
                        newValue === null
                          ? setBranchCountry("")
                          : setBranchCountry(newValue.label);
                      }}
                      inputValue={
                        responseArray[index]?.answer || data?.answer || ""
                      }
                      onInputChange={(event, newInputValue) =>
                        handleOptionChange(newInputValue, data, index)
                      }
                    />
                  ) : data?.type === "Date" ? (
                    <CustomTextfield
                      style={{ marginTop: "10px" }}
                      name="date"
                      variant="outlined"
                      size="small"
                      fullWidth
                      label="Pick your date"
                      onChange={(e) =>
                        handleOptionChange(e.target.value, data, index)
                      }
                      id="date"
                      type="date"
                      // inputProps={{
                      //   min: minDate,
                      // }}
                      disabled={
                        data?.linkageAnswer &&
                        (data?.linkageAnswer !== null ? true : false)
                      }
                      value={
                        data?.linkageAnswer || responseArray?.length > 0
                          ? responseArray[index]?.answer
                          : data?.answer
                      }
                      // defaultValue="2017-05-24"
                      // className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  ) : data?.type === "NUMBER" ? (
                    <CustomTextfield
                      variant="outlined"
                      type="number"
                      size="small"
                      fullWidth
                      disabled={
                        data?.linkageAnswer &&
                        (data?.linkageAnswer !== null ? true : false)
                      }
                      onChange={(e) =>
                        handleOptionChange(
                          parseInt(e.target.value),
                          data,
                          index
                        )
                      }
                    />
                  ) : data?.type === "SEARCH" ? (
                    <Autocomplete
                      options={searchedResults?.map((result) => result)}
                      getOptionLabel={(option) => option?.code || ""}
                      renderOption={(option) => (
                        <Typography
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Typography>{option?.code}</Typography>
                          <Typography
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "20px",
                            }}
                          >
                            {option?.name ? (
                              <>
                                <Typography
                                  variant="body2"
                                  style={{ marginLeft: "20px", color: "#999" }}
                                >
                                  {option?.name}
                                </Typography>
                                {/* {option?.logo?.url && (
                                  <img
                                    src={option?.logo?.url}
                                    alt={option?.name}
                                    style={{ height: 20, width: 20 }}
                                  />
                                )} */}
                              </>
                            ) : (
                              <Typography
                                variant="body2"
                                style={{ marginLeft: "20px", color: "#999" }}
                              >
                                Does not exist
                              </Typography>
                            )}
                          </Typography>
                        </Typography>
                      )}
                      size="small"
                      // defaultValue={vendorDetails?.isin?.toUpperCase()}
                      // value={vendorDetails?.isin}
                      renderInput={(params) => (
                        <CustomTextfield
                          {...params}
                          variant="outlined"
                          onChange={(e) => handleFindEntity(e.target.value)}
                          // value={vendorDetails?.isin?.toUpperCase()}
                          onFocus={(e) => {
                            handleFindEntity(e.target.value);
                            setShowSuggestions(true);
                          }}
                        />
                      )}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          handleOptionChange(newValue?.code, data, index);
                        }
                      }}
                      filterOptions={(options, state) =>
                        showSuggestions ? options : []
                      }
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                        setShowSuggestions(newInputValue.length > 0);
                      }}
                    />
                  ) : (
                    <CustomTextfield
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled={
                        data?.linkageAnswer &&
                        (data?.linkageAnswer !== null ? true : false)
                      }
                      onChange={(e) =>
                        handleOptionChange(e.target.value, data, index)
                      }
                    />
                  )}

                  <Typography variant="body2" style={{ color: "#999" }}>
                    {data?.instruction}
                  </Typography>
                </Box>
              </Box>
            ))}
          </>
        </Box>
      </Stack>
    </DynamicDrawer>
  );
};
export default FunctionRegistrationDrawer;
