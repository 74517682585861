import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Chip, Grid, Typography } from "@material-ui/core";
import { BlueBackgroundCard } from "../../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import {
  gql_get_default_surveys,
  gql_get_tprm_score,
} from "../../../../redux/actions/esgDiagnosisActions/esgDiagnosisActions";
import { SET_TPRM_SCORE } from "../../../../constants/esgDiagnosisConstant";

const tprmScore = {
  company_profile_score: {
    score: 0.2,
    categories: {
      "Company Profile": 0.4,
    },
    submitted_on: "2024-09-03T13:21:23.105Z",
  },
  information_security_score: {
    score: 4.666666666666667,
    categories: {
      "Information Security": 0.9333333333333333,
    },
    submitted_on: "2024-09-03T13:21:23.139Z",
  },
  data_privacy_score: {
    score: 4,
    categories: {
      "Data Privacy": 0.8,
    },
    submitted_on: "2024-09-03T13:21:23.172Z",
  },
  "third_party/subcontractors_score": {
    score: 4,
    categories: {
      "Third Party/Subcontractors": 0.8,
    },
    submitted_on: "2024-09-03T13:21:23.206Z",
  },
  business_and_ethical_conduct_score: {
    score: 9.2,
    categories: {
      "Business and Ethical Conduct": 1.8399999999999999,
    },
    submitted_on: "2024-09-03T13:21:23.241Z",
  },
  environment_score: {
    score: 8.333333333333332,
    categories: {
      Environment: 1.6666666666666665,
    },
    submitted_on: "2024-09-03T13:21:23.275Z",
  },
  labour_standards_score: {
    score: 10,
    categories: {
      "Labour Standards": 2,
    },
    submitted_on: "2024-09-03T13:21:23.309Z",
  },
  human_rights_score: {
    score: 7.333333333333333,
    categories: {
      "Human Rights": 1.4666666666666668,
    },
    submitted_on: "2024-09-03T13:21:23.342Z",
  },
  compliance_compliance_score: {
    score: 8.5,
    categories: {
      "Compliance Compliance": 1.7000000000000002,
    },
    submitted_on: "2024-09-03T13:21:23.376Z",
  },
  risk_management_score: {
    score: 10,
    categories: {
      "Risk Management": 2,
    },
    submitted_on: "2024-09-03T13:21:23.410Z",
  },
  organizational_security_score: {
    score: 10,
    categories: {
      "Organizational Security": 2,
    },
    submitted_on: "2024-09-03T13:21:23.448Z",
  },
  physical_and_environmental_security_score: {
    score: 9.5,
    categories: {
      "Physical and Environmental Security": 1.9,
    },
    submitted_on: "2024-09-03T13:21:23.481Z",
  },
  asset_management_score: {
    score: 10,
    categories: {
      "Asset Management": 2,
    },
    submitted_on: "2024-09-03T13:21:23.515Z",
  },
  access_control_score: {
    score: 10,
    categories: {
      "Access Control": 2,
    },
    submitted_on: "2024-09-03T13:21:23.548Z",
  },
  vulnerability_and_threat_management_score: {
    score: 9.428571428571429,
    categories: {
      "Vulnerability and Threat Management": 1.885714285714286,
    },
    submitted_on: "2024-09-03T13:21:23.582Z",
  },
  business_continuity_management_score: {
    score: 9.058823529411764,
    categories: {
      "Business Continuity Management": 1.811764705882353,
    },
    submitted_on: "2024-09-03T13:21:23.616Z",
  },
  cloud_specific_requirements_score: {
    score: 7.333333333333333,
    categories: {
      "Cloud Specific Requirements": 1.4666666666666668,
    },
    submitted_on: "2024-09-03T13:21:23.652Z",
  },
};

function OverallScore({ calculateScores, defaultScores }) {
  const { overallAverage } = calculateScores();

  const severity = (type, color) => {
    if (type === "bg") {
      if (color === "yellow") {
        return "#ffeeba";
      } else if (color === "green") {
        return "#BFE2CD";
      } else {
        return "#EDCAC6";
      }
    } else {
      if (color === "yellow") {
        return "#856404";
      } else if (color === "green") {
        return "#154328";
      } else {
        return "#52231D";
      }
    }
  };

  return (
    <Box style={{ width: "100%", pageBreakInside: "avoid" }}>
      <Grid md={12}>
        <BlueBackgroundCard heading={"Overall Score"} />
        <Box
          display={"flex"}
          alignItems={"center"}
          gridGap={"15px"}
          marginLeft={"20px"}
        >
          {!!parseFloat(overallAverage) && (
            <Chip
              label={
                parseFloat(overallAverage) <= 33
                  ? "Low"
                  : parseFloat(overallAverage) > 33 &&
                    parseFloat(overallAverage) <= 66
                  ? "Mid"
                  : "High"
              }
              size="small"
              style={{
                color:
                  parseFloat(overallAverage) <= 33
                    ? severity("text", "green")
                    : parseFloat(overallAverage) > 33 &&
                      parseFloat(overallAverage) <= 66
                    ? severity("text", "yellow")
                    : severity("text", "red"),
                backgroundColor:
                  parseFloat(overallAverage) <= 33
                    ? severity("bg", "green")
                    : parseFloat(overallAverage) > 33 &&
                      parseFloat(overallAverage) <= 66
                    ? severity("bg", "yellow")
                    : severity("bg", "red"),
              }}
            />
          )}
          <Typography
            style={{
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: "40px" }}>
              {Math.round(overallAverage) || "-"}
            </span>
            <span style={{ fontSize: "20px" }}>/</span>
            <span>100</span>
          </Typography>
        </Box>
        <Typography
          style={{
            fontSize: "13px",
            padding: "5px",
            marginBottom: "10px",
          }}
          // component={Paper}
        >
          The score reflects the vendor's performance and can guide them in
          making informed decisions.
        </Typography>
      </Grid>
      {/* ))} */}
    </Box>
  );
}

export default OverallScore;
