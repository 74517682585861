import { gql } from "@apollo/client";

export const GQL_GET_ENTITY_LIST = gql`
  query BankEntityList($rootNodeId: String!) {
    bankEntityList(rootNodeId: $rootNodeId) {
      _id
      name
      userDefinedId
      state
      entityType
      handledBy {
        _id
        name
      }
    }
  }
`;

export const GQL_ADD_ENTITY = gql`
  mutation AddBankEntity(
    $name: String!
    $entityType: String
    $userDefinedId: String
    $streetName: String
    $state: String
    $parentId: String
    $rootNodeId: String
  ) {
    addBankEntity(
      createBankInput: {
        name: $name
        entityType: $entityType
        userDefinedId: $userDefinedId
        streetName: $streetName
        state: $state
        parentId: $parentId
        rootNodeId: $rootNodeId
      }
    ) {
      _id
    }
  }
`;

export const GQL_GET_BANK_STRUCTURE = gql`
  query BankStructure($rootNodeId: String!) {
    bankStructure(rootNodeId: $rootNodeId) {
      name
      state
      stakeholders {
        _id
        profileImg {
          name
          url
          type
        }
      }
      vendors
      children {
        name
        state
        entityType
        vendors
        stakeholders {
          _id
          profileImg {
            name
            type
            url
          }
        }
      }
    }
  }
`;
