export const SET_BRM_MAIN_TAB_NO = "SET_BRM_MAIN_TAB_NO";
export const SET_BRM_SUB_TAB_NO = "SET_BRM_SUB_TAB_NO";
export const SET_SECTOR_INDUSTRIES = "SET_SECTOR_INDUSTRIES";
export const SET_SELECTED_SECTOR_INDUSTRIES = "SET_SELECTED_SECTOR_INDUSTRIES";
export const SET_ORGANIZATION_DETAILS = "SET_ORGANIZATION_DETAILS";
export const SET_ORGANIZATIONS_LIST = "SET_ORGANIZATIONS_LIST";
export const SET_VENDOR_DASHBOARD_DATA = "SET_VENDOR_DASHBOARD_DATA";
export const SET_ADD_VENDOR = "SET_ADD_VENDOR";
export const SET_MEMBERS = "SET_MEMBERS";
export const SET_ADD_STAKEHOLDER = "SET_ADD_STAKEHOLDER";
export const SET_VENDOR_BASIC_DETAILS = "SET_VENDOR_BASIC_DETAILS";
export const SET_VENDORS_BY_ISIN = "SET_VENDORS_BY_ISIN";
export const SET_SEARCH_SURVEYS_BY_NAME = "SET_SEARCH_SURVEYS_BY_NAME";
export const SET_SEARCH_VENDORS_BY_NAME = "SET_SEARCH_VENDORS_BY_NAME";
export const SET_SHOW_SURVEYS = "SET_SHOW_SURVEYS";
export const SET_SHOW_SELECTED_SURVEY = "SET_SHOW_SELECTED_SURVEY";
export const SET_LINKAGE_SURVEYS = "SET_LINKAGE_SURVEYS";
export const SET_LINKAGE_SURVEY_SECTIONS = "SET_LINKAGE_SURVEY_SECTIONS";
export const SET_LINKAGE_SURVEY_QUESTIONS = "SET_LINKAGE_SURVEY_QUESTIONS";
export const SET_VENDOR_MATERIALITY_SCORES = "SET_VENDOR_MATERIALITY_SCORES";
export const SET_VENDOR_IQ_SEARCH_COMPANIES = "SET_VENDOR_IQ_SEARCH_COMPANIES";
export const SET_VENDOR_IQ_DATA = "SET_VENDOR_IQ_DATA";

export const SET_KYP_COMPANIES = "SET_KYP_COMPANIES";
export const SET_KYP_DATA = "SET_KYP_DATA";

// -------------------- Intrim Built ---------------------------

export const SET_API_INTEGRATION = "SET_API_INTEGRATION";
export const SET_SURVEY_LIST_DATAFORM = "SET_SURVEY_LIST_DATAFORM";

export const SET_SURVEY_RESPONSE_FREQUENCIES =
  "SET_SURVEY_RESPONSE_FREQUENCIES";
export const SET_RISK_REGISTER = "SET_RISK_REGISTER";
export const SET_SURVEY_SCORES = "SET_SURVEY_SCORES";
export const SET_SURVEY_SCORES2 = "SET_SURVEY_SCORES2";
export const SET_RISK_AUTO_POPULATE = "SET_RISK_AUTO_POPULATE";
export const SET_SUBMITTED_SURVEY_RESPONSES = "SET_SUBMITTED_SURVEY_RESPONSES";
export const SET_HISTORIC_DATA = "SET_HISTORIC_DATA";

// Vendor Admin
export const SET_VA_BANKS_LIST = "SET_VA_BANKS_LIST";
export const SET_BANK_SHARED_SURVEY = "SET_BANK_SHARED_SURVEY";
export const SET_SELECTED_BANK_DETAILS_FOR_VA =
  "SET_SELECTED_BANK_DETAILS_FOR_VA";
export const SET_REQUESTED_FILES = "SET_REQUESTED_FILES";
export const SET_ALL_SUBMISSIONS = "SET_ALL_SUBMISSIONS";

export const SET_DECLARATION_TAB_NO = "SET_DECLARATION_TAB_NO ";
export const SET_CONTROVERSY_TAB_NO = "SET_CONTROVERSY_TAB_NO ";
export const SET_PROFILE_TAB_NO = "SET_PROFILE_TAB_NO ";
export const SET_COUNTRIES = "SET_COUNTRIES ";

export const SET_COMPANY_DOCUMENTS = "SET_COMPANY_DOCUMENTS ";
export const SET_UPDATE_ORGS_LIST = "SET_UPDATE_ORGS_LIST ";
export const SET_ORGANIZATION_DATA = "SET_ORGANIZATION_DATA";

export const SET_ROI_DATA_TABLE = "SET_ROI_DATA_TABLE";
export const SET_ROI_FUNCTIONS = "SET_ROI_FUNCTIONS";
export const SET_ROI_CONTRACTS = "SET_ROI_CONTRACTS";
export const SET_ROI_SECTION_DATA = "SET_ROI_SECTION_DATA";
