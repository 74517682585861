import { options } from "components/widgets/esgGpt/esgGptCompanyProfile/EsgGptCompany/SampleCharts";
import {
  criticalityOrImportanceAssessment,
  hierarchyOfTheEntity,
  impactOfDiscontinuingTheFunction,
  levelOfReliance,
  licensedActivity,
  natureOfTheEntity,
  possibilityOfReintegration,
  reasonIfTheICTNotSubstitutable,
  reasonOfTermination,
  sensitivenessOfTheDataStored,
  storageOfData,
  substitutabilityOfTheICT,
  typeOfCA,
  typeOfEntity,
  typeOfICTServices,
  typeOfPersonOfTheThirdPartyServiceProvider,
} from "components/widgets/v2/OrgDetails/dropdownTypes";
import { roiCountryList } from "components/widgets/v2/OrgDetails/roiCountryList";
import { roiCurrencyList } from "components/widgets/v2/OrgDetails/roiCurrencyList";

export const roiSectionQuestions = {
  // Manual Questions
  "RT.01.01": {
    title: [
      {
        name: "LEI_of_the_entity_maintaining_the_register_of_information",
        questionType: "Alphanumerical",
        mandatory: true,
      },
      { name: "Name_of_the_entity", questionType: "Alphanumerical" },
      {
        name: "Country_of_the_entity",
        questionType: "DROPDOWN",
        options: roiCountryList,
      },
      {
        name: "Type_of_entity",
        questionType: "DROPDOWN",
        options: typeOfEntity,
      },
      { name: "Competent_Authority", questionType: "Alphanumerical" },
      { name: "Date_of_the_reporting", questionType: "Date" },
    ],
    type: "manual",
  },
  "RT.01.03": {
    title: [
      {
        name: "Identification_code_of_the_branch",
        questionType: "Alphanumerical",
        mandatory: true,
      },
      {
        name: "LEI_of_the_financial_entity_head_office_of_the_branch",
        questionType: "SEARCH",
        mandatory: true,
      },
      { name: "Name_of_the_branch", questionType: "Alphanumerical" },
      {
        name: "Country_of_the_branch",
        questionType: "DROPDOWN",
        options: roiCountryList,
      },
    ],

    type: "manual",
  },
  "RT.02.03": {
    title: [
      {
        name: "Contractual_arrangement_with_ICT_intra_group_service_provider",
        questionType: "Alphanumerical",
        mandatory: true,
      },
      {
        name: "Linked_contractual_arrangement_with_ICT_third_party_service_provider",
        questionType: "Alphanumerical",
        mandatory: true,
      },
    ],
    type: "manual",
  },
  "RT.04.01": {
    title: [
      {
        name: "Contractual_arrangement_reference_number",
        questionType: "SEARCH",
        mandatory: true,
      },
      {
        name: "Identification_code_of_the_branch",
        questionType: "Alphanumerical",
        mandatory: true,
      },
      {
        name: "LEI_of_the_financial_entity",
        questionType: "SEARCH",
        mandatory: true,
      },
      {
        name: "Nature_of_the_entity_making_use_of_the_ICT_service",
        questionType: "DROPDOWN",
        options: natureOfTheEntity,
      },
    ],
    type: "manual",
  },
  "RT.05.02": {
    title: [
      {
        name: "Contractual_arrangement_reference_number",
        questionType: "SEARCH",
        mandatory: true,
      },
      {
        name: "Identification_code_of_the_recipient_of_sub_contracted_ICT_services",
        questionType: "Alphanumerical",
      },
      {
        name: "Identification_code_of_the_third_party_service_provider",
        questionType: "SEARCH",
        mandatory: true,
      },
      { name: "Rank", questionType: "NUMBER" },
      {
        name: "Type_of_ICT_services",
        questionType: "DROPDOWN",
        options: typeOfICTServices,
        mandatory: true,
      },
      {
        name: "Type_of_code_of_the_recipient_of_sub_contracted_ICT_services",
        questionType: "Alphanumerical",
      },
      {
        name: "Type_of_code_of_the_third_party_service_provider",
        questionType: "Alphanumerical",
      },
    ],
    type: "manual",
  },
  "RT.07.01": {
    title: [
      {
        name: "Are_there_alternative_ICT_third_party_service_providers_identified",
        questionType: "DROPDOWN",
        options: criticalityOrImportanceAssessment,
      },
      {
        name: "Contractual_arrangement_reference_number",
        questionType: "SEARCH",
        mandatory: true,
      },
      {
        name: "Date_of_the_last_audit_on_the_ICT_third_party_service_provider",
        questionType: "Date",
      },
      {
        name: "Existence_of_an_exit_plan",
        questionType: "DROPDOWN",
        options: storageOfData,
      },
      {
        name: "Identification_code_of_the_third_party_service_provider",
        questionType: "SEARCH",
        mandatory: true,
      },
      {
        name: "Identification_of_alternative_ICT_TPP",
        questionType: "Alphanumerical",
      },
      {
        name: "Impact_of_discontinuing_the_ICT_services",
        questionType: "DROPDOWN",
        options: impactOfDiscontinuingTheFunction,
      },
      {
        name: "Possibility_of_reintegration_of_the_contracted_ICT_service",
        questionType: "DROPDOWN",
        options: possibilityOfReintegration,
      },
      {
        name: "Reason_if_the_ICT_third_party_service_provider_is_considered_not_substitutable_or_difficult_to_be_substitutable",
        questionType: "DROPDOWN",
        options: reasonIfTheICTNotSubstitutable,
      },
      {
        name: "Substitutability_of_the_ICT_third_party_service_provider",
        questionType: "DROPDOWN",
        options: substitutabilityOfTheICT,
      },
      {
        name: "Type_of_ICT_services",
        questionType: "DROPDOWN",
        options: typeOfICTServices,
        mandatory: true,
      },
      {
        name: "Type_of_code_of_the_third_party_service_provider",
        questionType: "Alphanumerical",
      },
    ],
    type: "manual",
  },

  //   Linkage Questions
  "RT.01.02": {
    title: [
      { name: "LEI_of_the_entity", questionType: "Alphanumerical" },
      { name: "Name_of_the_entity", questionType: "Alphanumerical" },
      {
        name: "Country_of_the_entity",
        questionType: "DROPDOWN",
        options: roiCountryList,
      },
      {
        name: "Type_of_entity",
        questionType: "DROPDOWN",
        options: typeOfEntity,
      },
      {
        name: "Hierarchy_of_the_entity_within_the_group_where_applicable",
        questionType: "DROPDOWN",
        options: hierarchyOfTheEntity,
      },
      {
        name: "LEI_of_the_direct_parent_undertaking_of_the_financial_entity",
        questionType: "Alphanumerical",
      },
      { name: "Date_of_last_update", questionType: "Date" },
      {
        name: "Date_of_integration_in_the_Register_of_information",
        questionType: "Date",
      },
      {
        name: "Date_of_deletion_in_the_Register_of_information",
        questionType: "Date",
      },
      { name: "Value_of_total_assets", questionType: "NUMBER" },
      { name: "Currency", questionType: "DROPDOWN", options: roiCurrencyList },
    ],
    type: "linkage",
  },
  "RT.02.01": {
    title: [
      {
        name: "Contractual_arrangement_reference_number",
        questionType: "Alphanumerical",
      },
      {
        name: "Type_of_contractual_arrangement",
        questionType: "DROPDOWN",
        options: typeOfCA,
      },
      {
        name: "Overarching_contractual_arrangement_reference_number",
        questionType: "Alphanumerical",
      },
      {
        name: "Annual_expense_or_estimated_cost_of_the_contractual_arrangement_for_the_past_year",
        questionType: "Alphanumerical",
      },
      {
        name: "Currency_of_the_amount_reported",
        questionType: "DROPDOWN",
        options: roiCurrencyList,
      },
    ],
    type: "linkage",
  },
  "RT.02.02": {
    title: [
      {
        name: "Contractual_arrangement_reference_number",
        questionType: "Alphanumerical",
        mandatory: true,
      },
      {
        name: "Identification_code_of_the_third_party_service_provider",
        questionType: "Alphanumerical",
        mandatory: true,
      },
      {
        name: "Type_of_code_to_identify_the_third_party_service_provider",
        questionType: "Alphanumerical",
      },
      {
        name: "LEI_of_the_financial_entity_making_use_of_the_ICT_service",
        questionType: "Alphanumerical",
        mandatory: true,
      },
      { name: "Function_identifier", questionType: "SEARCH", mandatory: true },
      {
        name: "Type_of_ICT_services",
        questionType: "Alphanumerical",
        mandatory: true,
      },
      {
        name: "Start_date_of_the_contractual_arrangement",
        questionType: "Date",
      },
      {
        name: "End_date_of_the_contractual_arrangement",
        questionType: "Date",
      },
      {
        name: "Reason_of_the_termination_or_ending_of_the_contractual_arrangement",
        questionType: "DROPDOWN",
        options: reasonOfTermination,
      },
      {
        name: "Notice_period_for_the_financial_entity",
        questionType: "Alphanumerical",
      },
      {
        name: "Country_of_the_governing_law_of_the_contractual_arrangement",
        questionType: "DROPDOWN",
        options: roiCountryList,
      },
      {
        name: "Country_of_provision_of_the_ICT_services",
        questionType: "DROPDOWN",
        options: roiCountryList,
      },
      {
        name: "Storage_of_data",
        questionType: "DROPDOWN",
        options: storageOfData,
      },
      {
        name: "Location_of_the_data_at_rest_storage",
        questionType: "DROPDOWN",
        options: roiCountryList,
      },
      {
        name: "Location_of_management_of_the_data_processing",
        questionType: "DROPDOWN",
        options: roiCountryList,
      },
      {
        name: "Sensitiveness_of_the_data_stored_by_the_ICT_third_party_service_provider",
        questionType: "DROPDOWN",
        options: sensitivenessOfTheDataStored,
      },
      {
        name: "Level_of_reliance_on_the_ICT_service_supporting_the_critical_or_important_function",
        questionType: "DROPDOWN",
        options: levelOfReliance,
      },
    ],
    type: "linkage",
  },
  "RT.03.01": {
    title: [
      {
        name: "Contractual_arrangement_reference_number",
        questionType: "Alphanumerical",
        mandatory: true,
      },
      {
        name: "LEI_of_the_entity_signing_the_contractual_arrangement",
        questionType: "Alphanumerical",
        mandatory: true,
      },
    ],
    type: "linkage",
  },
  "RT.03.02": {
    title: [
      {
        name: "Contractual_arrangement_reference_number",
        questionType: "Alphanumerical",
        mandatory: true,
      },
      {
        name: "Identification_code_of_the_third_party_service_provider",
        questionType: "Alphanumerical",
        mandatory: true,
      },
      {
        name: "Type_of_code_of_the_third_party_service_provider",
        questionType: "Alphanumerical",
      },
    ],
    type: "linkage",
  },
  "RT.03.03": {
    title: [
      {
        name: "Contractual_arrangement_reference_number",
        questionType: "Alphanumerical",
      },
      {
        name: "LEI_of_the_intra_group_entity_providing_ICT_service",
        questionType: "Alphanumerical",
      },
    ],
    type: "linkage",
  },
  "RT.05.01": {
    title: [
      {
        name: "Identification_code_of_the_third_party_service_provider",
        questionType: "Alphanumerical",
        mandatory: true,
      },
      {
        name: "Type_of_code_of_the_third_party_service_provider",
        questionType: "Alphanumerical",
      },
      {
        name: "Additional_identification_code_of_the_third_party_service_provider",
        questionType: "NUMBER",
      },
      {
        name: "Type_of_additional_identification_code_of_the_third_party_service_provider",
        questionType: "Alphanumerical",
      },
      {
        name: "Legal_name_of_the_third_party_service_provider",
        questionType: "Alphanumerical",
      },
      {
        name: "Name_of_the_ICT_third_party_service_provider_in_Latin_alphabet",
        questionType: "Alphanumerical",
      },
      {
        name: "Type_of_person_of_the_third_party_service_provider",
        questionType: "DROPDOWN",
        options: typeOfPersonOfTheThirdPartyServiceProvider,
      },
      {
        name: "Country_of_the_third_party_service_providers_headquarters",
        questionType: "DROPDOWN",
        options: roiCountryList,
      },
      {
        name: "Total_annual_expense_or_estimated_cost_of_the_third_party_service_provider",
        questionType: "NUMBER",
      },
      {
        name: "Currency_of_the_amount_reported",
        questionType: "DROPDOWN",
        options: roiCurrencyList,
      },
      {
        name: "Identification_code_of_the_third_party_service_providers_ultimate_parent_undertaking",
        questionType: "Alphanumerical",
      },
      {
        name: "Type_of_code_of_the_third_party_service_providers_ultimate_parent_undertaking",
        questionType: "Alphanumerical",
      },
    ],
    type: "linkage",
  },
  "RT.06.01": {
    title: [
      {
        name: "LEI_of_the_financial_entity",
        questionType: "Alphanumerical",
        mandatory: true,
      },
      {
        name: "Function_identifier",
        questionType: "Alphanumerical",
        mandatory: true,
      },
      {
        name: "Licensed_activity",
        questionType: "DROPDOWN",
        options: licensedActivity,
      },
      { name: "Function_name", questionType: "Alphanumerical" },
      {
        name: "Criticality_or_importance_assessment",
        questionType: "DROPDOWN",
        options: criticalityOrImportanceAssessment,
      },
      {
        name: "Reasons_for_criticality_or_importance",
        questionType: "Alphanumerical",
      },
      {
        name: "Date_of_the_last_assessment_of_criticality_or_importance",
        questionType: "Date",
      },
      {
        name: "Recovery_time_objective_of_the_function",
        questionType: "NUMBER",
      },
      {
        name: "Recovery_point_objective_of_the_function",
        questionType: "NUMBER",
      },
      {
        name: "Impact_of_discontinuing_the_function",
        questionType: "DROPDOWN",
        options: impactOfDiscontinuingTheFunction,
      },
    ],
    type: "linkage",
  },

  "RT.99.01": {
    title: [
      {
        name: "Type_of_contractual_arrangement",
        questionType: "DROPDOWN",
        options: typeOfCA,
      },
      {
        name: "Sensitiveness_of_the_data_stored_by_the_ICT_third_party_service_provider",
        questionType: "DROPDOWN",
        options: sensitivenessOfTheDataStored,
      },
      {
        name: "Impact_of_discontinuing_the_function",
        questionType: "DROPDOWN",
        options: impactOfDiscontinuingTheFunction,
      },
      {
        name: "Substitutability_of_the_ICT_third_party_service_provider",
        questionType: "DROPDOWN",
        options: substitutabilityOfTheICT,
      },
      {
        name: "Possibility_of_reintegration_of_the_contracted_ICT_service",
        questionType: "DROPDOWN",
        options: possibilityOfReintegration,
      },
      {
        name: "Impact_of_discontinuing_the_ICT_services",
        questionType: "DROPDOWN",
        options: impactOfDiscontinuingTheFunction,
      },
    ],
    type: "linkage",
  },
};
