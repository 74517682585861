import { Box } from "@material-ui/core";
import "../../../widgets/tprmRisk/trpmRiskReport/print.css";
import { Bar } from "react-chartjs-2";

const KeyFinancials = ({ data, y_text, x_text, heading }) => {
  const chartData = {
    labels: data?.map((item) => item?.date?.split("T")[0]),
    datasets: [
      {
        label: heading,
        data: data?.map((item) => item.value),
        backgroundColor: "#A1BFDF",
        borderWidth: 1,
        barThickness: 30,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: false,
        text: heading,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: x_text, // X-axis label
        },
      },
      y: {
        title: {
          display: true,
          text: y_text, // Y-axis label
        },
        ticks: {
          callback: function (value) {
            if (value >= 1000) {
              return value / 1000 + "k";
            } else {
              return value;
            }
          },
        },
      },
    },
  };

  return (
    <Box style={{ height: "100%" }}>
      <Bar data={chartData} options={options} height={200} />
    </Box>
  );
};

export default KeyFinancials;
