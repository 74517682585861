import {
  Box,
  Chip,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { BlueBackgroundCard } from "../../esgGpt/esgGptCompanyProfile/EsgGptCompanyRight";
import DimentionCharts from "components/widgets/esgDeclaration/dimentionChart/dimentionCharts";
import CreditScores from "components/widgets/esgCompanyProfile/kyp/CreditScores";
import CompanyComplianceRisk from "components/widgets/esgCompanyProfile/kyp/CompanyComplianceRisk";
import KeyFinancials from "components/widgets/esgCompanyProfile/kyp/KeyFinancials";
import DarkWeb from "components/widgets/esgCompanyProfile/kyp/DarkWeb";
import CyberRisk from "components/widgets/esgCompanyProfile/kyp/CyberRisk";
import { gql_get_kyp_data } from "redux/actions/vendorActions/vendorAction";
import { useDispatch, useSelector } from "react-redux";
import Shareholders from "components/widgets/esgCompanyProfile/kyp/Shareholders";
import StakeholdersRisk from "components/widgets/esgCompanyProfile/kyp/StakeholdersRisk";
import PreviousStakeholdersRisk from "components/widgets/esgCompanyProfile/kyp/PreviousStakeholdersRisk";
import GroupStructure from "components/widgets/esgCompanyProfile/kyp/GroupStructure";
import LegalEvents from "components/widgets/esgCompanyProfile/kyp/LegalEvents";
import moment from "moment";
import "components/widgets/tprmRisk/trpmRiskReport/print.css";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    border: "1px solid #A3A8AF",
    marginTop: "5px",
    borderRadius: "8px",
  },

  fontSize: {
    fontSize: "16px",
    // display: "flex",
    // justifyContent: "space-between",
  },
  fontBold: {
    fontWeight: 600,
    // marginRight: "10px",
  },
}));

function KypData({ severity }) {
  const { vendorBasicDetails, kypData } = useSelector((state) => state.brmData);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(gql_get_kyp_data(vendorBasicDetails?.kypId));
  }, []);

  const riskAnalysis =
    kypData?.risks &&
    Object?.entries(kypData?.risks)?.map(([key, value]) => value || 0);
  return (
    <Box style={{ pageBreakBefore: "always" }}>
      <Box>
        <BlueBackgroundCard heading={"KYP Score"} />
        <Box
          display={"flex"}
          alignItems={"center"}
          gridGap={"15px"}
          marginLeft={"20px"}
        >
          {!!kypData?.risks?.companyRisk && (
            <Chip
              label={
                Math.round(kypData?.risks?.companyRisk) <= 33
                  ? "Low"
                  : Math.round(kypData?.risks?.companyRisk) > 33 &&
                    Math.round(kypData?.risks?.companyRisk) <= 66
                  ? "Mid"
                  : "High"
              }
              style={{
                color:
                  Math.round(kypData?.risks?.companyRisk) <= 33
                    ? severity("text", "red")
                    : Math.round(kypData?.risks?.companyRisk) > 33 &&
                      Math.round(kypData?.risks?.companyRisk) <= 66
                    ? severity("text", "yellow")
                    : severity("text", "green"),
                backgroundColor:
                  Math.round(kypData?.risks?.companyRisk) <= 33
                    ? severity("bg", "red")
                    : Math.round(kypData?.risks?.companyRisk) > 33 &&
                      Math.round(kypData?.risks?.companyRisk) <= 66
                    ? severity("bg", "yellow")
                    : severity("bg", "green"),
              }}
              size="small"
            />
          )}
          <Typography
            style={{
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: "40px" }}>
              {Math.round(kypData?.risks?.companyRisk) || "-"}
            </span>
            <span style={{ fontSize: "20px" }}>/</span>
            <span>100</span>
          </Typography>
        </Box>
        <Typography
          style={{
            fontSize: "13px",
            padding: "5px",
            marginBottom: "10px",
          }}
          // component={Paper}
        >
          The score reflects the vendor's performance and can guide them in
          making informed decisions.
        </Typography>
      </Box>

      <Grid>
        {kypData?.companyDetails && (
          <>
            <BlueBackgroundCard heading={"Organization"} />
            <Paper style={{ padding: "10px", marginBottom: "20px" }}>
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "#3374B9",
                }}
              >
                {kypData?.companyDetails?.legalName}
              </Typography>

              <Grid style={{ marginTop: "10px" }}>
                <Typography className={classes.fontSize}>
                  <span className={classes.fontBold}> Regestration No.:</span>
                  {"  "}
                  {kypData?.companyDetails?.registrationNumber || "N/A"}
                </Typography>
                <Typography className={classes.fontSize}>
                  <span className={classes.fontBold}> Trading Status:</span>
                  {"  "}
                  {kypData?.companyDetails?.tradingStatus || "N/A"}
                </Typography>
                <Typography className={classes.fontSize}>
                  <span className={classes.fontBold}>
                    {" "}
                    Trading Status Description:
                  </span>
                  {"  "}
                  {kypData?.companyDetails?.tradingStatusDescription || "N/A"}
                </Typography>
                <Typography className={classes.fontSize}>
                  <span className={classes.fontBold}> Type:</span>
                  {"  "}
                  {kypData?.companyDetails?.companyType || "N/A"}
                </Typography>
                <Typography className={classes.fontSize}>
                  <span className={classes.fontBold}> VAT No.:</span>
                  {"  "}
                  {kypData?.companyDetails?.vatNumber || "N/A"}
                </Typography>
                <Typography className={classes.fontSize}>
                  <span className={classes.fontBold}> Registration Date:</span>
                  {"  "}
                  {kypData?.companyDetails?.registrationDate
                    ? moment(kypData?.companyDetails?.registrationDate).format(
                        "Do MMM, YYYY"
                      )
                    : "-"}
                </Typography>
                <Typography className={classes.fontSize}>
                  <span className={classes.fontBold}> Website:</span>
                  {"  "}
                  {kypData?.companyDetails?.websites[0] || "N/A"}
                </Typography>
                <br></br>

                <Typography className={classes.fontSize}>
                  <span className={classes.fontBold}> Main Address:</span>
                  {"  "}
                  {kypData?.companyDetails?.mainAddress || "N/A"}
                </Typography>
                <Typography className={classes.fontSize}>
                  <span className={classes.fontBold}> Telephone No.:</span>
                  {"  "}
                  {kypData?.companyDetails?.telephone || "N/A"}
                </Typography>
                <Typography className={classes.fontSize}>
                  <span className={classes.fontBold}> Trading Address:</span>
                  {"  "}
                  {kypData?.companyDetails?.tradingAddress || "N/A"}
                </Typography>
                <br></br>

                <Typography className={classes.fontSize}>
                  <span className={classes.fontBold}> Industry Code:</span>
                  {"  "}
                  {kypData?.companyDetails?.industryCode || "N/A"}
                </Typography>
                <Typography className={classes.fontSize}>
                  <span className={classes.fontBold}>
                    Industry Code Description:
                  </span>
                  {"  "}
                  {kypData?.companyDetails?.industryCodeDescription || "N/A"}
                </Typography>
                <Typography className={classes.fontSize}>
                  <span className={classes.fontBold}> Principal Activity:</span>
                  {"  "}
                  {kypData?.companyDetails?.principalActivity || "N/A"}
                </Typography>
                <Typography className={classes.fontSize}>
                  <span className={classes.fontBold}>
                    {" "}
                    Accounts Filing Date:
                  </span>
                  {"  "}

                  {kypData?.companyDetails?.accountsFilingDate
                    ? moment(
                        kypData?.companyDetails?.accountsFilingDate
                      ).format("Do MMM, YYYY")
                    : "-"}
                </Typography>
                <Typography className={classes.fontSize}>
                  <span className={classes.fontBold}> Trade Debtors:</span>
                  {"  "}
                  {kypData?.companyDetails?.tradeDebtors || "N/A"}
                </Typography>
              </Grid>
            </Paper>

            <Box style={{ pageBreakBefore: "always" }}>
              <BlueBackgroundCard heading={"Risk Analysis"} />
              {kypData?.risks && (
                <Box style={{ width: "100%" }}>
                  <DimentionCharts
                    scores={riskAnalysis}
                    labels={[
                      "AML Risk",
                      "Company Risk",
                      "Credit Risk",
                      "Dark Web Risk",
                      "Legal Risk",
                      "Stakeholder Risk",
                    ]}
                  />
                </Box>
              )}
            </Box>
            {kypData?.keyFinancials?.totalAssets && (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box style={{ width: "49%" }}>
                  <BlueBackgroundCard heading={"Credit Scores"} />
                  <CreditScores
                    scores={kypData?.creditScore}
                    classes={classes}
                  />
                </Box>
                <Box style={{ width: "49%" }}>
                  <BlueBackgroundCard heading={"Company Compliance Risks"} />
                  <CompanyComplianceRisk
                    data={kypData?.companyComplianceRisks}
                    classes={classes}
                  />
                </Box>
              </Box>
            )}

            {(kypData?.keyFinancials?.totalLiabilities?.length > 0 ||
              kypData?.keyFinancials?.totalAssets?.length > 0) && (
              <BlueBackgroundCard heading={"Key Financials"} />
            )}
            {kypData?.keyFinancials?.totalAssets && (
              <Box style={{ height: "300px" }}>
                <KeyFinancials
                  data={kypData?.keyFinancials?.totalAssets}
                  currency={kypData?.keyFinancials?.totalAssetsCurrency}
                  x_text={""}
                  y_text={`Assets in ${kypData?.keyFinancials?.totalAssetsCurrency} currency`}
                  heading={"Total Assets"}
                />
              </Box>
            )}
            {kypData?.keyFinancials?.totalLiabilities && (
              <Box style={{ height: "300px" }}>
                <KeyFinancials
                  data={kypData?.keyFinancials?.totalLiabilities}
                  currency={kypData?.keyFinancials?.totalLiabilitiesCurrency}
                  x_text={""}
                  y_text={`Liabilities in ${kypData?.keyFinancials?.totalLiabilitiesCurrency} currency`}
                  heading={"Total Liabilities"}
                />
              </Box>
            )}

            {kypData?.darkWeb?.length > 0 && (
              <BlueBackgroundCard heading={"Dark Web"} />
            )}

            {kypData?.darkWeb?.length > 0 && (
              <Box>
                <DarkWeb data={kypData?.darkWeb} />
              </Box>
            )}

            {kypData?.shareholders?.length > 0 && (
              <BlueBackgroundCard heading={"Ownership Details (Shareholder)"} />
            )}

            {kypData?.shareholders?.length > 0 && (
              <Box>
                <Shareholders data={kypData?.shareholders} />
              </Box>
            )}

            {kypData?.stakeholderRisks?.length > 0 && (
              <BlueBackgroundCard heading={"Stakeholder Risks"} />
            )}

            {kypData?.stakeholderRisks?.length > 0 && (
              <Box>
                <StakeholdersRisk data={kypData?.stakeholderRisks} />
              </Box>
            )}

            {kypData?.previousStakeholderRisks?.length > 0 && (
              <BlueBackgroundCard heading={" Previous Stakeholder Risks"} />
            )}

            {kypData?.previousStakeholderRisks?.length > 0 && (
              <Box>
                <PreviousStakeholdersRisk
                  data={kypData?.previousStakeholderRisks}
                />
              </Box>
            )}

            <Box style={{ pageBreakInside: "avoid" }}>
              {kypData?.cyberRisks?.analysisDate && (
                <BlueBackgroundCard heading={"Cyber Risk"} />
              )}

              {kypData?.cyberRisks?.analysisDate && (
                <Box>
                  <CyberRisk cyberRisks={kypData?.cyberRisks} />
                </Box>
              )}
            </Box>

            {kypData?.groupStructure && (
              <Box>
                <GroupStructure
                  data={kypData?.groupStructure}
                  classes={classes}
                />
              </Box>
            )}

            {kypData?.legalEvents?.length > 0 && (
              <BlueBackgroundCard heading={"Legal Events"} />
            )}

            {kypData?.legalEvents?.length > 0 && (
              <Box>
                <LegalEvents data={kypData?.legalEvents} />
              </Box>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
}

export default KypData;
