import {
  Box,
  Chip,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import DynamicDrawer from "components/UI/dynamicDrawer/DynamicDrawer";
import RequiredField from "components/UI/inputField/requiredShow";
import Stack from "components/UI/Stack/Stack";
import { useCallback, useState } from "react";
import { gql_add_enitity } from "redux/actions/vendorActions/vendorOrgAction";
import countries from "utils/countries/countries";
import CustomTextfield from "./../../../UI/textfield/textfield";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  gql_find_entity,
  gql_submit_linkage_survey_questions,
  gql_submit_roi_reg_entity,
} from "redux/actions/vendorActions/vendorAction";
import { entityRegistrationQuestions } from "./questionsData";
import debounce from "lodash.debounce";

export const useROIStyles = makeStyles((theme) => ({
  menuItem: {
    whiteSpace: "wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 800, // Set the maximum width for the dropdown items
    borderTop: "1px solid rgba(0,0,0,0.03)",
  },
  selectMenu: {
    maxHeight: "400px",
    "& ul": {
      overflowX: "auto", // Enable horizontal scroll
    },
  },
  divider: {
    backgroundColor: theme.palette.grey[300], // Light gray color for the divider
  },
}));

const EntityDrawer = ({ open, onClose }) => {
  const { action } = useTheme().palette;

  const classes = useROIStyles();

  const initialState = {
    name: "",
    entityType: "",
    userDefinedId: "",
    streetName: "",
    state: "",
  };
  const dispatch = useDispatch();
  const { bankId } = useSelector((state) => state?.login);
  const [branchCountry, setBranchCountry] = useState("");
  const [responseArray, setResponseArray] = useState([]);
  const [finalArray, setFinalArray] = useState([]);

  const [entityData, setEntityData] = useState(initialState);
  const [entityId, setEntityId] = useState("");

  const [showSuggestions, setShowSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState([]);
  const [searchedResults, setSearchedResults] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setEntityData((prev) => ({ ...prev, [name]: value }));
  };

  const handleChipClick = (entityType) => {
    setEntityData((prev) => ({ ...prev, entityType }));
  };

  const disableSubmit = Object.values(entityData).some((value) => !value);

  const closeDrawer = () => {
    setEntityData(initialState);

    onClose();
  };

  const submitLinkageQuestions = useCallback(
    (entityId) => {
      dispatch(
        gql_submit_roi_reg_entity({
          regEntityInput: { ...finalArray, bankId, entityId },
        })
      );
    },
    [finalArray]
  );

  console.log(finalArray);

  const handleSubmit = async () => {
    await gql_add_enitity({
      ...entityData,
      closeDrawer,
      submitLinkageQuestions,
    });
  };

  const handleOptionChange = (value, question, index) => {
    setResponseArray((prev) => ({
      ...prev,
      [question?.name
        ?.replaceAll(" ", "_")
        ?.replaceAll("(", "")
        ?.replaceAll(")", "")]: value,
    }));

    setFinalArray((prev) => ({
      ...prev,
      [question?.name
        ?.replaceAll(" ", "_")
        ?.replaceAll("(", "")
        ?.replaceAll(")", "")]: value,
    }));
  };

  const fetchEntityResults = useCallback(
    debounce((searchQuery) => {
      gql_find_entity(bankId, setSearchedResults, searchQuery);
    }, 500),
    []
  );

  const handleFindEntity = (entityValue) => {
    fetchEntityResults(entityValue);
  };

  return (
    <DynamicDrawer
      hasDrawerOpen={open}
      handleClose={onClose}
      heading={"Add Entity"}
      buttonText={"Add Entity"}
      disableSubmit={disableSubmit}
      submitClick={handleSubmit}
      style={{ padding: 10 }}
      dynamicWidth={"800px"}
    >
      <Stack flexDirection={"column"} spacing={20}>
        {/* Entity Name */}
        <Box>
          <Typography
            variant="body1"
            style={{ color: action.active }}
            gutterBottom
          >
            Entity Name {RequiredField(!entityData.name)}
          </Typography>
          <TextField
            name="name"
            variant="outlined"
            placeholder="Enter Name"
            value={entityData.name}
            onChange={handleChange}
            required
            fullWidth
          />
        </Box>

        {/* Entity Type */}
        <Box>
          <Typography
            variant="body1"
            style={{ color: action.active }}
            gutterBottom
          >
            Entity Type {RequiredField(!entityData.entityType)}
          </Typography>
          <Stack spacing={10}>
            <Chip
              label="Subsidary"
              variant="outlined"
              onClick={() => handleChipClick("Subsidary")}
              color={
                entityData.entityType === "Subsidary" ? "primary" : "default"
              }
            />
            <Chip
              label="Joint Venture"
              variant="outlined"
              onClick={() => handleChipClick("Joint Venture")}
              color={
                entityData.entityType === "Joint Venture"
                  ? "primary"
                  : "default"
              }
            />
          </Stack>
        </Box>

        {/* CIN No. */}
        <Box>
          <Typography
            variant="body1"
            style={{ color: action.active }}
            gutterBottom
          >
            CIN No. {RequiredField(!entityData.userDefinedId)}
          </Typography>
          <TextField
            name="userDefinedId"
            variant="outlined"
            placeholder="CIN No."
            value={entityData.userDefinedId}
            onChange={handleChange}
            required
            fullWidth
          />
        </Box>

        {/* Location */}
        <Box>
          <Typography
            variant="body1"
            style={{ color: action.active }}
            gutterBottom
          >
            Location{" "}
            {RequiredField(!entityData.streetName || !entityData.state)}
          </Typography>
          <TextField
            name="streetName"
            variant="outlined"
            placeholder="Street name, building name"
            value={entityData.streetName}
            onChange={handleChange}
            style={{ marginBottom: 10 }}
            required
            fullWidth
          />
          <TextField
            name="state"
            variant="outlined"
            placeholder="State"
            value={entityData.state}
            onChange={handleChange}
            required
            fullWidth
          />
        </Box>

        <Box>
          <>
            {entityRegistrationQuestions?.map((data, index) => (
              <Box key={index}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    marginBottom: "20px",
                  }}
                >
                  <Typography style={{ fontWeight: 500 }} variant="body2">
                    {data?.name}
                  </Typography>
                  {data?.type === "DROPDOWN" ? (
                    <Select
                      fullWidth
                      disabled={
                        data?.linkageAnswer &&
                        (data?.linkageAnswer !== null ? true : false)
                      }
                      value={
                        data?.linkageAnswer ||
                        responseArray[index]?.answer ||
                        data?.answer
                      }
                      // defaultValue={data?.answer}

                      // label="Entity"
                      variant="outlined"
                      // size="small"
                      // disabled={isFrom === "createVendor" ? false : !edit}
                      MenuProps={{ classes: { paper: classes.selectMenu } }}
                    >
                      {data?.options?.map((item, i) => (
                        <MenuItem
                          key={i}
                          value={item?.option}
                          className={classes.menuItem}
                          onClick={(e) => {
                            const { _id, ...rest } = item;
                            handleOptionChange(rest, data, index);
                          }}
                        >
                          {item?.option}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : data?.type === "Country" ? (
                    <Autocomplete
                      getOptionSelected={(option, value) =>
                        option?.id === value?.id
                      }
                      id="country-select-demo"
                      sx={{ width: 300 }}
                      options={countries}
                      disabled={
                        data?.linkageAnswer &&
                        (data?.linkageAnswer !== null ? true : false)
                      }
                      // autoHighlight
                      getOptionLabel={(option) => option.label}
                      renderOption={(option) => option.label}
                      renderInput={(params) => (
                        <CustomTextfield
                          {...params}
                          // label="Country"
                          variant="outlined"
                          value={
                            data?.linkageAnswer || branchCountry || data?.answer
                          }
                          name="Country"
                        />
                      )}
                      onChange={(event, newValue) => {
                        newValue === null
                          ? setBranchCountry("")
                          : setBranchCountry(newValue.label);
                      }}
                      inputValue={
                        responseArray[index]?.answer || data?.answer || ""
                      }
                      onInputChange={(event, newInputValue) =>
                        handleOptionChange(newInputValue, data, index)
                      }
                    />
                  ) : data?.type === "Date" ? (
                    <CustomTextfield
                      style={{ marginTop: "10px" }}
                      name="date"
                      variant="outlined"
                      // size="small"
                      fullWidth
                      label="Pick your date"
                      onChange={(e) =>
                        handleOptionChange(e.target.value, data, index)
                      }
                      id="date"
                      type="date"
                      // inputProps={{
                      //   min: minDate,
                      // }}
                      disabled={
                        data?.linkageAnswer &&
                        (data?.linkageAnswer !== null ? true : false)
                      }
                      value={
                        data?.linkageAnswer || responseArray?.length > 0
                          ? responseArray[index]?.answer
                          : data?.answer
                      }
                      // defaultValue="2017-05-24"
                      // className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  ) : data?.type === "NUMBER" ? (
                    <CustomTextfield
                      variant="outlined"
                      type="number"
                      //   value={
                      //     answerFormat(data, responseArray, index)

                      //     // (isFrom === "editVendor"
                      //     //   ? responseArray?.length > 0
                      //     //     ? responseArray[index]?.answer
                      //     //     : data?.answer
                      //     //   : responseArray?.length > 0
                      //     //   ? responseArray[index]?.answer
                      //     //   : data?.answer
                      //     //   ? data?.answer
                      //     //   : "")
                      //   }
                      size="small"
                      fullWidth
                      disabled={
                        data?.linkageAnswer &&
                        (data?.linkageAnswer !== null ? true : false)
                      }
                      onChange={(e) =>
                        handleOptionChange(
                          parseInt(e.target.value),
                          data,
                          index
                        )
                      }
                    />
                  ) : data?.type === "SEARCH" ? (
                    <Autocomplete
                      options={searchedResults?.map((result) => result)}
                      getOptionLabel={(option) => option?.name || ""}
                      renderOption={(option) => (
                        <Typography
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Typography>{option?.code}</Typography>
                          <Typography
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "20px",
                            }}
                          >
                            {option?.name ? (
                              <>
                                <Typography
                                  variant="body2"
                                  style={{ marginLeft: "20px", color: "#999" }}
                                >
                                  {option?.name}
                                </Typography>
                                {/* {option?.logo?.url && (
                                  <img
                                    src={option?.logo?.url}
                                    alt={option?.name}
                                    style={{ height: 20, width: 20 }}
                                  />
                                )} */}
                              </>
                            ) : (
                              <Typography
                                variant="body2"
                                style={{ marginLeft: "20px", color: "#999" }}
                              >
                                Does not exist
                              </Typography>
                            )}
                          </Typography>
                        </Typography>
                      )}
                      size="small"
                      // defaultValue={vendorDetails?.isin?.toUpperCase()}
                      // value={vendorDetails?.isin}
                      renderInput={(params) => (
                        <CustomTextfield
                          {...params}
                          variant="outlined"
                          onChange={(e) => handleFindEntity(e.target.value)}
                          // value={vendorDetails?.isin?.toUpperCase()}
                          onFocus={(e) => {
                            handleFindEntity(e.target.value);
                            setShowSuggestions(true);
                          }}
                        />
                      )}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          handleOptionChange(newValue?.name, data, index);
                        }
                      }}
                      filterOptions={(options, state) =>
                        showSuggestions ? options : []
                      }
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                        setShowSuggestions(newInputValue.length > 0);
                      }}
                    />
                  ) : (
                    <CustomTextfield
                      variant="outlined"
                      //   value={
                      //     answerFormat(data, responseArray, index)

                      //     // (isFrom === "editVendor"
                      //     //   ? responseArray?.length > 0
                      //     //     ? responseArray[index]?.answer
                      //     //     : data?.answer
                      //     //   : responseArray?.length > 0
                      //     //   ? responseArray[index]?.answer
                      //     //   : data?.answer
                      //     //   ? data?.answer
                      //     //   : "")
                      //   }
                      size="small"
                      fullWidth
                      disabled={
                        data?.linkageAnswer &&
                        (data?.linkageAnswer !== null ? true : false)
                      }
                      onChange={(e) =>
                        handleOptionChange(e.target.value, data, index)
                      }
                    />
                  )}

                  <Typography variant="body2" style={{ color: "#999" }}>
                    {data?.instruction}
                  </Typography>
                </Box>
              </Box>
            ))}
          </>
        </Box>
      </Stack>
    </DynamicDrawer>
  );
};
export default EntityDrawer;
