import { Box, Button, IconButton } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import DynamicTableFull from "components/UI/dynamicTable/DynamicTableFull";
import Stack from "components/UI/Stack/Stack";
import SearchTextField from "components/UI/textfield/searchTextField";
import ContractRegistrationDrawer from "components/widgets/v2/OrgDetails/ContractRegistrationDrawer";
import EntityDrawer from "components/widgets/v2/OrgDetails/EntityDrawer";
import FunctionRegistrationDrawer from "components/widgets/v2/OrgDetails/FunctionRegistrationDrawer";
import { usePaginationSearch } from "customHooks/usePaginationSearch";
import { useEffect } from "react";
import { FiDownload, FiPlus, FiUpload } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";
import { useSelector } from "react-redux";
import {
  gql_get_roi_contracts,
  gql_get_roi_functions,
} from "redux/actions/vendorActions/vendorAction";
import { gql_get_entity_list } from "redux/actions/vendorActions/vendorOrgAction";

const listHead = [
  { name: "Contractual arrangement reference number" },
  { name: "Type of contractual arrangement" },
  { name: "Overarching contractual arrangement reference number" },
  { name: "Currency of the amount reported" },
  {
    name: "Annual expense or estimated cost of the Contractual arrangement for the past year",
  },
  { name: "LEI of the financial entity making use of the ICT service" },
  { name: "Identification code of the third-party service provider" },
  { name: "Type of code to identify the third-party service provider" },
  { name: "Function identifier" },
  { name: "Type of ICT services" },
  { name: "Start date of the contractual arrangement" },
  { name: "End date of the contractual arrangement" },
  {
    name: "Reason of the termination or ending of the contractual arrangement",
  },
  { name: "Notice period for the financial entity" },
  { name: "Notice period for the ICT third-party service provider" },
  { name: "Country of the governing law of the contractual arrangement" },
  { name: "Country of provision of the ICT services" },
  { name: "Storage of data" },
  { name: "Location of the data at rest (storage)" },
  { name: "Location of management of the data (processing)" },
  {
    name: "Sensitiveness of the data stored by the ICT third-party service provider",
  },
  {
    name: "Level of reliance on the ICT service supporting the critical or important function",
  },
  // { name: "LEI of the entity signing the CA" },
  // { name: "Type of code of the third-party service provider" },
  {
    name: "LEI of the intra-group entity providing ICT service",
  },
];

const rowOrder = [
  { type: "raw", data: "Contractual_arrangement_reference_number" },
  { type: "raw", data: "Type_of_contractual_arrangement.option" },
  { type: "raw", data: "Overarching_contractual_arrangement_reference_number" },
  {
    type: "raw",
    data: "Currency_of_the_amount_reported.option",
  },
  {
    type: "raw",
    data: "Annual_expense_or_estimated_cost_of_the_contractual_arrangement_for_the_past_year",
  },
  {
    type: "raw",
    data: "LEI_of_the_financial_entity_making_use_of_the_ICT_service",
  },
  {
    type: "raw",
    data: "Identification_code_of_the_third_party_service_provider",
  },
  {
    type: "raw",
    data: "Type_of_code_to_identify_the_third_party_service_provider",
  },
  { type: "raw", data: "Function_identifier" },
  { type: "raw", data: "Type_of_ICT_services.option" },
  { type: "raw", data: "Start_date_of_the_contractual_arrangement" },
  { type: "raw", data: "End_date_of_the_contractual_arrangement" },
  {
    type: "raw",
    data: "Reason_of_the_termination_or_ending_of_the_contractual_arrangement.option",
  },
  { type: "raw", data: "Notice_period_for_the_financial_entity" },
  {
    type: "raw",
    data: "Notice_period_for_the_ICT_third_party_service_provider",
  },
  {
    type: "raw",
    data: "Country_of_the_governing_law_of_the_contractual_arrangement.option",
  },
  { type: "raw", data: "Country_of_provision_of_the_ICT_services.option" },
  { type: "raw", data: "Storage_of_data.option" },
  { type: "raw", data: "Location_of_the_data_at_rest_storage.option" },
  { type: "raw", data: "Location_of_management_of_the_data_processing.option" },
  {
    type: "raw",
    data: "Sensitiveness_of_the_data_stored_by_the_ICT_third_party_service_provider.option",
  },
  {
    type: "raw",
    data: "Level_of_reliance_on_the_ICT_service_supporting_the_critical_or_important_function.option",
  },
  { type: "raw", data: "LEI_of_the_intra_group_entity_providing_ICT_service" },
  //   {
  //     type: "comp",
  //     data: (
  //       <IconButton
  //         aria-label="CustomAction"
  //         size="small"
  //         color="primary"
  //         onClick={(row) => console.log("Row data:", row)}
  //       >
  //         <IoIosArrowForward />
  //       </IconButton>
  //     ),
  //   },
];

const ContractRegistration = () => {
  const {
    // states and data
    openDrawer,
    keyword,
    page,
    limit,
    // funcs
    toggleDrawer,
    handleSearch,
    handlePageChange,
  } = usePaginationSearch();

  const { roiContracts } = useSelector((state) => state.brmData);
  const { bankId } = useSelector((state) => state?.login);

  console.log(roiContracts);

  useEffect(() => {
    gql_get_roi_contracts(bankId);
  }, []);

  return (
    <Box position={"relative"} height={"70vh"}>
      {/* Search and Buttons */}
      <Stack direction="row" spacing={10} justifyContent="flex-end" mb={2}>
        <SearchTextField
          searchClick={handleSearch}
          style={{ minWidth: "30em" }}
        />
        {/* <Button color="primary" startIcon={<FiDownload />}>
          Download Sheet
        </Button>
        <Button color="primary" startIcon={<FiUpload />}>
          Upload Sheet
        </Button> */}
        <Button
          variant="contained"
          color="primary"
          startIcon={<FiPlus />}
          onClick={toggleDrawer}
        >
          Add Contract
        </Button>
      </Stack>

      {/* Entry List Table */}
      <DynamicTableFull
        tableHead={listHead}
        tableData={roiContracts}
        tableRowOrder={rowOrder}
        maxHeight="50vh"
      />

      <Stack mt={5} justifyContent="flex-end">
        <Pagination
          // count={Math.ceil(entity_list?.total_count / limit)}
          count={1}
          variant="outlined"
          color="primary"
          page={page}
          onChange={handlePageChange}
        />
      </Stack>

      {/* Drawer */}

      <ContractRegistrationDrawer open={openDrawer} onClose={toggleDrawer} />
    </Box>
  );
};
export default ContractRegistration;
