import { Box, Button, IconButton } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import DynamicTableFull from "components/UI/dynamicTable/DynamicTableFull";
import Stack from "components/UI/Stack/Stack";
import SearchTextField from "components/UI/textfield/searchTextField";
import StakeholdersDrawer from "components/widgets/v2/OrgDetails/StakeholdersDrawer";
import { usePaginationSearch } from "customHooks/usePaginationSearch";
import { useEffect } from "react";
import { FiDownload, FiPlus, FiUpload } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { gql_get_users } from "redux/actions/generalSettings/generalSettingsAction";

const listHead = [
  { name: "Stakeholder Name" },
  { name: "Unique No." },
  { name: "Role" },
  { name: "Entity Id" },
  { name: "Entity Name" },
  { name: "View" },
];

const rowOrder = [
  { type: "raw", data: "name" },
  { type: "raw", data: "userDefinedId" },
  { type: "raw", data: "designation" },
  { type: "raw", data: "bank.userDefinedId" },
  { type: "raw", data: "bank.name" },
  {
    type: "comp",
    data: (
      <IconButton
        aria-label="CustomAction"
        size="small"
        color="primary"
        onClick={(row) => console.log("Row data:", row)}
      >
        <IoIosArrowForward />
      </IconButton>
    ),
  },
];

const Stakeholders = () => {
  const {
    // states and data
    openDrawer,
    keyword,
    page,
    limit,
    // funcs
    toggleDrawer,
    handleSearch,
    handlePageChange,
  } = usePaginationSearch();

  const dispatch = useDispatch();

  const { users } = useSelector((state) => state.generalSettings);

  useEffect(() => {
    dispatch(gql_get_users(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box position={"relative"} height={"70vh"}>
      {/* Search and Buttons */}
      <Stack direction="row" spacing={10} justifyContent="flex-end" mb={2}>
        <SearchTextField
          searchClick={handleSearch}
          style={{ minWidth: "30em" }}
        />
        {/* <Button color="primary" startIcon={<FiDownload />}>
          Download Sheet
        </Button>
        <Button color="primary" startIcon={<FiUpload />}>
          Upload Sheet
        </Button> */}
        <Button
          variant="contained"
          color="primary"
          startIcon={<FiPlus />}
          onClick={toggleDrawer}
        >
          Add Stakeholder
        </Button>
      </Stack>

      {/* List Table */}
      <DynamicTableFull
        tableHead={listHead}
        tableData={users}
        tableRowOrder={rowOrder}
        maxHeight="50vh"
      />

      <Stack mt={5} justifyContent="flex-end">
        <Pagination
          // count={Math.ceil(entity_list?.total_count / limit)}
          count={1}
          variant="outlined"
          color="primary"
          page={page}
          onChange={handlePageChange}
        />
      </Stack>

      {/* Drawer */}
      <StakeholdersDrawer open={openDrawer} onClose={toggleDrawer} />
    </Box>
  );
};
export default Stakeholders;
