import React from "react";
import { Box, Typography } from "@material-ui/core";

function TprmRiskContent({ showReport }) {
  //   console.log(showReport);

  //   const indexCount = Object.entries(showReport).map(([key, value])=> )
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100vh"}
    >
      <Typography
        align="center"
        variant={"h4"}
        style={{ marginBottom: "50px" }}
      >
        TPRM Report
      </Typography>
      <Box display={"flex"} flexDirection={"column"} gridGap={30}>
        {!!showReport &&
          Object.entries(showReport)
            .filter(([key, value]) => value !== false)
            .map(([key, value], index) => (
              <Typography variant="h5">
                <span
                  style={{
                    padding: "3px 12px",
                    // width: "20px",
                    // height: "20px",
                    backgroundColor: "#3374B9",
                    borderRadius: "150px",
                    color: "#fff",
                    marginRight: "10px",
                    fontSize: "20px",
                    fontWeight: 600,
                  }}
                >
                  {index + 1}
                </span>{" "}
                {key}
              </Typography>
            ))}
      </Box>
      {/* {!!showReport && showReport?.["Vendor Intake/Scoping"] && (
        <Typography>1. Vendor Intake/Scoping</Typography>
      )}

      {!!showReport && showReport?.["Risk Assessment"] && (
        <Typography>2. Risk Assessment</Typography>
      )}

      {!!showReport && showReport?.["Due Diligence"] && (
        <Typography>3. Due Diligence</Typography>
      )}

      {!!showReport && showReport?.["Contracting"] && (
        <Typography>4. Contracting</Typography>
      )}

      {!!showReport && showReport?.["Monitoring"] && (
        <Typography>5. Monitoring</Typography>
      )}

      {!!showReport && showReport?.["Termination And Offboarding"] && (
        <Typography>6. Termination and Offboarding</Typography>
      )} */}
    </Box>
  );
}

export default TprmRiskContent;
