import { Box, Button, IconButton } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import DynamicTableFull from "components/UI/dynamicTable/DynamicTableFull";
import Stack from "components/UI/Stack/Stack";
import SearchTextField from "components/UI/textfield/searchTextField";
import EntityDrawer from "components/widgets/v2/OrgDetails/EntityDrawer";
import { usePaginationSearch } from "customHooks/usePaginationSearch";
import { useEffect } from "react";
import { FiDownload, FiPlus, FiUpload } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";
import { useSelector } from "react-redux";
import { gql_get_entity_list } from "redux/actions/vendorActions/vendorOrgAction";

const listHead = [
  { name: "Entity Name" },
  { name: "GIN No." },
  { name: "Location" },
  { name: "Entity Type" },
  { name: "Handling Stakeholder" },
  { name: "View" },
];

const rowOrder = [
  { type: "raw", data: "name" },
  { type: "raw", data: "userDefinedId" },
  { type: "raw", data: "state" },
  { type: "raw", data: "entityType" },
  { type: "raw", data: "handledBy" },
  {
    type: "comp",
    data: (
      <IconButton
        aria-label="CustomAction"
        size="small"
        color="primary"
        onClick={(row) => console.log("Row data:", row)}
      >
        <IoIosArrowForward />
      </IconButton>
    ),
  },
];

const EntityList = () => {
  const {
    // states and data
    openDrawer,
    keyword,
    page,
    limit,
    // funcs
    toggleDrawer,
    handleSearch,
    handlePageChange,
  } = usePaginationSearch();

  const { entity_list } = useSelector((state) => state.vendorOrg);

  useEffect(() => {
    gql_get_entity_list();
  }, []);

  return (
    <Box position={"relative"} height={"70vh"}>
      {/* Search and Buttons */}
      <Stack direction="row" spacing={10} justifyContent="flex-end" mb={2}>
        <SearchTextField
          searchClick={handleSearch}
          style={{ minWidth: "30em" }}
        />
        {/* <Button color="primary" startIcon={<FiDownload />}>
          Download Sheet
        </Button>
        <Button color="primary" startIcon={<FiUpload />}>
          Upload Sheet
        </Button> */}
        <Button
          variant="contained"
          color="primary"
          startIcon={<FiPlus />}
          onClick={toggleDrawer}
        >
          Add Entity
        </Button>
      </Stack>

      {/* Entry List Table */}
      <DynamicTableFull
        tableHead={listHead}
        tableData={entity_list}
        tableRowOrder={rowOrder}
        maxHeight="50vh"
      />

      <Stack mt={5} justifyContent="flex-end">
        <Pagination
          // count={Math.ceil(entity_list?.total_count / limit)}
          count={1}
          variant="outlined"
          color="primary"
          page={page}
          onChange={handlePageChange}
        />
      </Stack>

      {/* Drawer */}
      <EntityDrawer open={openDrawer} onClose={toggleDrawer} />
    </Box>
  );
};
export default EntityList;
