import {
  criticalityOrImportanceAssessment,
  hierarchyOfTheEntity,
  impactOfDiscontinuingTheFunction,
  licensedActivity,
  reasonOfTermination,
  sensitivenessOfTheDataStored,
  storageOfData,
  typeOfCA,
  typeOfEntity,
  typeOfICTServices,
  typeOfPersonOfTheThirdPartyServiceProvider,
} from "./dropdownTypes";
import { roiCountryList } from "./roiCountryList";
import { roiCurrencyList } from "./roiCurrencyList";

export const contractRegistrationQuestions = [
  {
    // code: "RT.06.01.0010",
    instruction:
      "Identify the contractual arrangement between the financial entity or, in case of a group, the group subsidiary and the direct ICT third-party service provider. \n The contractual arrangement reference number is the internal reference number of the contractual arrangement assigned by the financial entity. \n The contractual arrangement reference number shall be unique and consistent over time at entity, sub-consolidated and consolidated level, where applicable. \n The contractual arrangement reference number shall be used consistently across all templates of the register of information when referring to the same contractual arrangement.",
    name: "Contractual arrangement reference number",
    type: "Alphanumerical",
    // sectionId: "667e95ccf9a19ac2927e30ea",
  },
  {
    // code: "RT.06.01.0020",
    instruction:
      "Identify the type of contractual arrangement by using one of the options in the corresponding dropdown list",
    name: "Type of contractual arrangement",
    type: "DROPDOWN",
    options: typeOfCA,

    // sectionId: "667e95ccf9a19ac2927e30ea",
  },
  {
    // code: "RT.06.01.0030",
    instruction:
      "Not applicable if the contractual arrangement is the ‘overarching contractual arrangement’ or a ‘standalone arrangement’. In the other cases, report the contractual arrangement reference number of the overarching arrangement, which shall be equal to value as reported in the column b_02.01.0010 when reporting the overarching contractual arrangement.",
    name: "Overarching contractual arrangement reference number",
    type: "Alphanumerical",
    // sectionId: "667e95ccf9a19ac2927e30ea",
  },
  {
    // code: "RT.06.01.0040",
    instruction:
      "Identify the ISO 4217 alphabetic code of the currency used to express the amount in b_02.01.0050",
    name: "Currency of the amount reported",
    type: "DROPDOWN",
    options: roiCurrencyList,
    // sectionId: "667e95ccf9a19ac2927e30ea",
  },
  {
    // code: "RT.06.01.0060",
    instruction:
      "Annual expense or estimated cost (or intragroup transfer) of the ICT service arrangement for the past year. \n The annual expense or estimated cost shall be expressed in the currency reported in b_01.02.0040. \n In case of an overarching arrangement with subsequent or associated arrangements, the sum of the annual expenses or estimated costs reported for the overarching arrangement and the subsequent or associated arrangements shall be equal to the total expenses or estimated costs for the overall contractual arrangement. This means, there should be no repetition or duplication of annual expenses or estimated costs. The following cases should be reflected: \n (a) if the annual expenses or estimate costs are not determined at the level of the overarching arrangement (i.e. they are 0), the annual expenses or estimated costs should be reported at the level of each subsequent or associated arrangements.\n (b) if the annual expenses or estimated costs cannot be reported for each of the subsequent or associated arrangements, the total annual expense or estimated cost should be reported at the level of the overarching arrangement. \n (c) if there are annual expenses or estimated costs related to each level of the arrangement, i.e. overarching and subsequent or associated, and this information is available, the annual expenses or estimated costs shall be reported without duplication at each level of the contractual arrangement.",
    name: "Annual expense or estimated cost of the contractual arrangement for the past year",
    // sectionId: "667e95ccf9a19ac2927e30ea",
    type: "Monetary",
  },
  {
    // code: "RT.06.01.0070",
    instruction:
      "As reported in b_04.01.0020 \n Identify the entity making use of the ICT service(s) using the LEI, 20-character, alpha-numeric code based on the ISO 17442 standard",
    name: "LEI of the financial entity making use of the ICT service",
    type: "SEARCH",
    // sectionId: "667e95ccf9a19ac2927e30ea",
  },
  {
    // code: "RT.06.01.0080",
    instruction:
      "As reported in b_05.01.0010 \n Code to identify the ICT third-party service provider",
    name: "Identification code of the third party service provider",
    type: "SEARCH",
    // sectionId: "667e95ccf9a19ac2927e30ea",
  },
  {
    // code: "RT.06.01.0090",
    instruction:
      "As reported in b_05.01.0020 \n Identify the type of code to identify the ICT third-party service provider in b_02.02.0030 \n1.	‘LEI’ for LEI \n 2.	‘Country Code’+Underscore+’Type of Code’ for non LEI code \n Country Code: Identify the ISO 3166–1 alpha–2 code of the country of issuance of the other code to identify the ICT third-party service provider \nType of Code: \n 1.	CRN for Corporate registration number \n 2.	VAT for VAT number \n 3.	PNR for Passport Number \n 4.	NIN for National Identity Number",
    name: "Type of code to identify the third party service provider",
    type: "Alphanumerical",
    // sectionId: "667e95ccf9a19ac2927e30ea",
  },
  {
    // code: "RT.06.01.0100",
    instruction: "As defined by the financial entity in b_06.01.0010",
    name: "Function identifier",
    type: "SEARCH",
    // sectionId: "667e95ccf9a19ac2927e30ea",
  },
  {
    // code: "RT.06.01.0110",
    instruction: "One of the types of ICT services referred to in Annex III",
    name: "Type of ICT services",
    // sectionId: "667e95ccf9a19ac2927e30ea",
    type: "DROPDOWN",
    options: typeOfICTServices,
  },
  {
    // code: "RT.06.01.0110",
    instruction:
      "Identify the date of entry into force of the contractual arrangement as stipulated in the contractual arrangement using the ISO 8601 (yyyy–mm–dd) code",
    name: "Start date of the contractual arrangement",
    // sectionId: "667e95ccf9a19ac2927e30ea",
    type: "Date",
  },
  {
    // code: "RT.06.01.0110",
    instruction:
      "Identify the end date as stipulated in the contractual arrangement using the ISO 8601 (yyyy–mm–dd) code. If the contractual arrangement is indefinite, it shall be filled in with ‘9999-12-31’. If the contractual arrangement has been terminated on a date different than the end date, this shall be filled in with the termination date. \n In case the contractual arrangement foresees a renewal, this shall be filled in with the date of the contract renewal as stipulated in the contractual arrangement.",
    name: "End date of the contractual arrangement",
    // sectionId: "667e95ccf9a19ac2927e30ea",
    type: "Date",
  },
  {
    // code: "RT.06.01.0110",
    instruction:
      "In case the contractual arrangement has been terminated or it is ended, identify the reason of the termination or ending of the contractual arrangements using one of the options in the corresponding dropdown list",
    name: "Reason of the termination or ending of the contractual arrangement",
    // sectionId: "667e95ccf9a19ac2927e30ea",
    type: "DROPDOWN",
    options: reasonOfTermination,
  },
  {
    // code: "RT.06.01.0110",
    instruction:
      "Identify the notice period for terminating the contractual arrangement by the financial entity in a business-as-usual case. The notice period shall be expressed as number of calendar days from the receipt of the counterparty of the request to terminate the ICT service.",
    name: "Notice period for the financial entity",
    // sectionId: "667e95ccf9a19ac2927e30ea",
    type: "NUMBER",
  },
  {
    // code: "RT.06.01.0110",
    instruction:
      "Identify the notice period for terminating contractual arrangement by the direct ICT third-party service provider in a business-as-usual case. The notice period shall be expressed as number of calendar days from the receipt of the counterparty of the request to terminate the ICT service.",
    name: "Notice period for the ICT third party service provider",
    // sectionId: "667e95ccf9a19ac2927e30ea",
    type: "NUMBER",
  },
  {
    // code: "RT.06.01.0110",
    instruction:
      "Identify the country of the governing law of the contractual arrangement using the ISO 3166–1 alpha–2 code.",
    name: "Country of the governing law of the contractual arrangement",
    // sectionId: "667e95ccf9a19ac2927e30ea",
    type: "DROPDOWN",
    options: roiCountryList,
  },
  {
    // code: "RT.06.01.0110",
    instruction:
      "Identify the country of provision of the ICT services using the ISO 3166–1 alpha–2 code.",
    name: "Country of provision of the ICT services",
    // sectionId: "667e95ccf9a19ac2927e30ea",
    type: "DROPDOWN",
    options: roiCountryList,
  },
  {
    // code: "RT.06.01.0110",
    instruction:
      "Is the ICT service related to (or foresees) storage of data? \n Choose one of the options in the corresponding dropdown list",
    name: "Storage of data",
    // sectionId: "667e95ccf9a19ac2927e30ea",
    type: "DROPDOWN",
    options: storageOfData,
  },
  {
    // code: "RT.06.01.0110",
    instruction:
      "Identify the country of location of the data at rest (storage) using the ISO 3166–1 alpha–2 code.",
    name: "Location of the data at rest storage",
    // sectionId: "667e95ccf9a19ac2927e30ea",
    type: "DROPDOWN",
    options: roiCountryList,
  },
  {
    // code: "RT.06.01.0110",
    instruction:
      "Identify the country of location of management of the data (processing) using the ISO 3166–1 alpha–2 code.",
    name: "Location of management of the data processing",
    // sectionId: "667e95ccf9a19ac2927e30ea",
    type: "DROPDOWN",
    options: roiCountryList,
  },
  {
    // code: "RT.06.01.0110",
    instruction:
      "Identify the level of sensitiveness of the data stored or processed by the ICT third-party service provider using one of the options in the corresponding dropdown list \n The most sensitive data take precedence: e.g. if both ‘Medium’ and ‘High’ apply, then ‘High’ shall be selected.",
    name: "Sensitiveness of the data stored by the ICT third party service provider",
    // sectionId: "667e95ccf9a19ac2927e30ea",
    type: "DROPDOWN",
    options: sensitivenessOfTheDataStored,
  },
  {
    // code: "RT.06.01.0110",
    // sectionId: "667e95ccf9a19ac2927e30ea",
    instruction:
      "One of the options in the corresponding dropdown list shall be used:",
    name: "Level of reliance on the ICT service supporting the critical or important function",
    type: "DROPDOWN",
    options: [
      {
        identifier: "eba_ZZ:x794",
        listIdentifier: "LIST0202180",
        titleIdentifier: "DESC02020180",
        option: "Not significant",
      },
      {
        identifier: "eba_ZZ:x795",
        listIdentifier: "LIST0202180",
        titleIdentifier: "DESC02020180",
        option: "Low reliance",
      },
      {
        identifier: "eba_ZZ:x796",
        listIdentifier: "LIST0202180",
        titleIdentifier: "DESC02020180",
        option: "Material reliance",
      },
      {
        identifier: "eba_ZZ:x797",
        listIdentifier: "LIST0202180",
        titleIdentifier: "DESC02020180",
        option: "Full reliance",
      },
    ],
  },

  // {
  //   // code: "RT.06.01.0110",
  //   instruction:
  //     "Identify the entity signing the contractual arrangement using the LEI, 20-character, alpha-numeric code based on the ISO 17442 standard",
  //   name: "LEI of the entity signing the contractual arrangement",
  //   // sectionId: "667e95ccf9a19ac2927e30ea",
  //   type: "Alphanumerical",
  // },
  // {
  //   // code: "RT.06.01.0110",
  //   instruction:
  //     "As reported in b_05.01.0010 \n Code to identify the ICT third-party service provider",
  //   name: "Identification code of ICT third party service provider",
  //   // sectionId: "667e95ccf9a19ac2927e30ea",
  //   type: "Alphanumerical",
  // },
  {
    // code: "RT.06.01.0110",
    // instruction:
    //   "As reported in b_01.02.0010 \n Identify the entity providing ICT services using LEI, 20-character, alpha-numeric code based on the ISO 17442 standard",
    name: "LEI of the intra group entity providing ICT service",
    // sectionId: "667e95ccf9a19ac2927e30ea",
    type: "SEARCH",
  },
];

export const functionRegistrationQuestions = [
  {
    code: "RT.06.01.0010",
    instruction:
      "The function identifier shall be composed by the letter F (capital letter) followed by an natural number (e.g. “F1” for the 1st function identifier and “Fn” for the nth function identifier with “n” being an natural number). Each combination between ‘LEI of the financial entity making use of the ICT service(s)’ (RT.06.01.0040), ‘Function name’ (RT.06.01.0030) and ‘Licenced activity’ (RT.06.01.0020) shall have a unique function identifier. Example: a financial entity which operates under two licensed activities (say, activity A and activity B) will identify two unique. ‘function identifiers’ for the same function X (e.g. Sales) performed for activity A and activity B.",
    name: "Function identifier",
    type: "Pattern",
    sectionId: "667e95ccf9a19ac2927e30ea",
  },
  {
    code: "RT.06.01.0020",
    instruction:
      "One of the licenced activities referred to in Annex II for the different type of financial entities. In case the function is not linked to a registered or licenced activity, ‘support functions’ shall be reported.",
    name: "Licensed activity",
    type: "DROPDOWN",
    options: licensedActivity,
    sectionId: "667e95ccf9a19ac2927e30ea",
  },
  {
    code: "RT.06.01.0030",
    instruction:
      "Function name according to the financial entity’s internal organisation.",
    name: "Function name",
    type: "Alphanumerical",
    sectionId: "667e95ccf9a19ac2927e30ea",
  },
  {
    code: "RT.06.01.0040",
    instruction:
      "As reported in RT.04.01.0020; Identify the financial entity using the LEI, 20-character, alphanumeric code based on the ISO 17442 standard",
    name: "LEI of the financial entity",
    type: "SEARCH",
    sectionId: "667e95ccf9a19ac2927e30ea",
  },
  {
    code: "RT.06.01.0060",
    instruction:
      "Use this column to indicate whether the function is critical or important according to the financial entity’s assessment. One of the options in the following closed list shall be used:",
    name: "Criticality or importance assessment",
    options: criticalityOrImportanceAssessment,
    sectionId: "667e95ccf9a19ac2927e30ea",
    type: "DROPDOWN",
  },
  {
    code: "RT.06.01.0070",
    instruction:
      "Brief explanation on the reasons to classify the function as critical or important (300 characters maximum)",
    name: "Reasons for criticality or importance",
    type: "Alphanumerical",
    sectionId: "667e95ccf9a19ac2927e30ea",
  },
  {
    code: "RT.06.01.0080",
    instruction:
      "Identify the ISO 8601 (yyyy–mm–dd) code of the date of the last assessment of criticality or importance in case the function is supported by ICT services provided by ICT third-party service providers.; In case the function’s assessment of criticality or importance is not performed, it shall be filled in with ‘9999-12-31’",
    name: "Date of the last assessment of criticality or importance",
    type: "Date",
    sectionId: "667e95ccf9a19ac2927e30ea",
  },
  {
    code: "RT.06.01.0090",
    instruction:
      "In number of hours. If the recovery time objective is less than 1 hour, ‘1’ shall be reported. In case the recovery time objective of the function is not defined ‘0’ shall be reported.",
    name: "Recovery time objective of the function",
    type: "NUMBER",
    sectionId: "667e95ccf9a19ac2927e30ea",
  },
  {
    code: "RT.06.01.0100",
    instruction:
      "In number of hours. If the recovery point objective is less than 1 hour, ‘1’ shall be reported. In case the recovery time objective of the function is not defined ‘0’ shall be reported.",
    name: "Recovery point objective of the function",
    type: "NUMBER",
    sectionId: "667e95ccf9a19ac2927e30ea",
  },
  {
    code: "RT.06.01.0110",
    instruction:
      "Use this column to indicate the impact of discontinuing the function according to the financial entity’s assessment. One of the options in the following closed list shall be used",
    name: "Impact of discontinuing the function",
    options: impactOfDiscontinuingTheFunction,
    sectionId: "667e95ccf9a19ac2927e30ea",
    type: "DROPDOWN",
  },
];

export const entityRegistrationQuestions = [
  {
    _id: "667e9ba9d04b02c7661d80e5",
    instruction:
      "Identify the entity maintaining and updating the register of information using the LEI, 20-character, alpha-numeric code based on the ISO 17442 standard",

    name: "LEI of the entity",
    sectionId: "667e955ef9a19ac2927e30d8",
    type: "Alphanumerical",
  },
  {
    _id: "667e9bdad04b02c7661d80e6",

    instruction:
      "Legal name of the entity maintaining and updating the register of information",

    name: "Name of the entity",
    sectionId: "667e955ef9a19ac2927e30d8",
    type: "Alphanumerical",
  },
  {
    _id: "667e9c02d04b02c7661d80e7",
    instruction:
      "Identify the ISO 3166–1 alpha–2 code of the country where the license or the registration of the entity reported in the Register on Information has been issued",

    name: "Country of the entity",
    sectionId: "667e955ef9a19ac2927e30d8",
    type: "DROPDOWN",
    options: roiCountryList,
  },
  {
    _id: "667e9c19d04b02c7661d80e8",
    instruction:
      "Identify the ISO 3166–1 alpha–2 code of the country where the license or the registration of the entity reported in the Register on Information has been issued",
    sectionId: "667e955ef9a19ac2927e30d8",
    name: "Type of entity",
    options: typeOfEntity,
    type: "DROPDOWN",
  },
  {
    _id: "667e9ccaf9a19ac2927e30fe",

    instruction:
      "Identify the hierarchy of the entity within the scope of consolidation using one of the options in the following closed list:",

    name: "Hierarchy of the entity within the group (where applicable)",
    options: hierarchyOfTheEntity,
    sectionId: "667e955ef9a19ac2927e30d8",
    type: "DROPDOWN",
  },
  {
    _id: "667e9d58f9a19ac2927e3100",
    instruction:
      "Identify the direct parent undertaking of the entity reported in the Register on Information using the LEI, 20-character, alpha-numeric code based on the ISO 17442 standard",

    name: "LEI of the direct parent undertaking of the financial entity",
    sectionId: "667e955ef9a19ac2927e30d8",
    type: "Alphanumerical",
  },
  {
    _id: "667e9dd6f9a19ac2927e3102",
    instruction:
      "Identify the ISO 8601 (yyyy–mm–dd) code of the date of the last update made on the Register of information in relation to the entity.",

    name: "Date of last update",
    sectionId: "667e955ef9a19ac2927e30d8",
    type: "Date",
  },
  {
    _id: "667e9e2df9a19ac2927e3104",
    code: "RT.01.02.0080",
    instruction:
      "Identify the ISO 8601 (yyyy–mm–dd) code of the date of integration in the Register of information",

    name: "Date of integration in the Register of information",
    sectionId: "667e955ef9a19ac2927e30d8",
    type: "Date",
  },
  {
    _id: "667e9e69f9a19ac2927e3106",
    instruction:
      "Identify the ISO 8601 (yyyy–mm–dd) code of the date of deletion in the Register of information. If the entity has not been deleted, ‘9999-12-31’ shall be reported",

    name: "Date of deletion in the Register of information",
    sectionId: "667e955ef9a19ac2927e30d8",
    type: "Date",
  },
  {
    _id: "667e9e91f9a19ac2927e3108",
    code: "RT.01.02.0100",
    instruction:
      "Identify the ISO 4217 alphabetic code of the currency used for the preparation of the financial entity’s financial statements.",

    name: "Currency",
    sectionId: "667e955ef9a19ac2927e30d8",
    type: "DROPDOWN",
    options: roiCurrencyList,
  },
  {
    _id: "667e9ed6f9a19ac2927e310a",
    instruction:
      "Monetary value of total assets of the entity making use of the ICT services as reported in the entity’s annual financial statement of the year before the date of the last update of the register of information. Refer to Annex IV for the approach to be followed when filling in this column.",

    name: "Value of total assets",
    sectionId: "667e955ef9a19ac2927e30d8",
    type: "NUMBER",
  },
  // {
  //   _id: "667ea16ef9a19ac2927e310c",
  //   instruction:
  //     "Identify a branch of a financial entity located outside its home country using a unique code for each branch. One of the options in the following closed list shall be used:; 1. LEI of the branch if unique for this branch and different from RT.01.03.0020; 2. Other identification code used by the financial entity to identify the branch (if the LEI of the branch is equivalent to the one in RT.01.03.0020 or equivalent to the LEI of another branch).",

  //   name: "Identification code of the branch",
  //   sectionId: "667e9561f9a19ac2927e30db",
  //   type: "Alphanumerical",
  // },
  // {
  //   _id: "667ea1c1f9a19ac2927e310e",

  //   instruction:
  //     "As referred to in RT.01.02.0010; Identify the financial entity head office of the branch, using the LEI, 20-character, alpha-numeric code based on the ISO 17442 standard.",

  //   name: "LEI of the financial entity head office of the branch",
  //   sectionId: "667e9561f9a19ac2927e30db",
  //   type: "Alphanumerical",
  // },
  // {
  //   _id: "667ea200f9a19ac2927e3110",

  //   instruction: "Identify the name of the branch",

  //   name: "Name of the branch",
  //   sectionId: "667e9561f9a19ac2927e30db",
  //   type: "Alphanumerical",
  // },
  // {
  //   _id: "667ea232f9a19ac2927e3112",
  //   instruction:
  //     "Identify the ISO 3166–1 alpha–2 code of the country where the branch is located.",

  //   name: "Country of the branch",
  //   sectionId: "667e9561f9a19ac2927e30db",
  //   type: "DROPDOWN",
  //   options: roiCountryList,
  // },
];

export const vendorRegistrationQuestions = [
  {
    instruction: "Code to identify the ICT third-party service provider",
    name: "Identification code of the third party service provider",
    type: "Alphanumerical",
  },
  {
    instruction:
      "Identify the type of code to identify the ICT third-party service provider in b_05.01.0010 \n 1.	‘LEI’ for LEI \n2.	‘Country Code’+Underscore+’Type of Code’ for non LEI code \n Country Code: Identify the ISO 3166–1 alpha–2 code of the country of issuance of the other code to identify the ICT third-party service provider \n Type of Code: \n 1.	CRN for Corporate registration number \n 2.	VAT for VAT number \n 3.	PNR for Passport Number \n 4.	NIN for National Identity Number",
    name: "Type of code of the third party service provider",
    type: "Alphanumerical",
  },
  {
    instruction: "",
    name: "Additional identification code of the third party service provider",
    type: "NUMBER",
  },
  {
    instruction: "",
    name: "Type of additional identification code of the third party service provider",
    type: "Alphanumerical",
  },
  {
    instruction: "",
    name: "Legal name of the third party service provider",
    type: "Alphanumerical",
  },
  {
    instruction: "",
    name: "Name of the ICT third party service provider in Latin alphabet",
    type: "Alphanumerical",
  },
  {
    instruction:
      "One of the  options in the corresponding dropdown list shall be used.Providing the LEI is mandatory for legal person including natural persons acting in a business capacity",
    name: "Type of person of the third party service provider",
    type: "DROPDOWN",
    options: typeOfPersonOfTheThirdPartyServiceProvider,
  },
  {
    instruction:
      "Identify the ISO 3166–1 alpha–2 code of the country in which the global operating headquarters of ICT third-party service provider are located.",
    name: "Country of the third party service providers headquarters",
    type: "DROPDOWN",
    options: roiCountryList,
  },
  {
    instruction:
      "Annual expense or estimated cost for using the ICT services provided by the ICT third-party service provider to the entities making use of the ICT services",
    name: "Total annual expense or estimated cost of the third party service provider",
    type: "NUMBER",
  },
  {
    instruction:
      "Identify the ISO 4217 alphabetic code of the currency used to express the amount in b_05.01.0070",
    name: "Currency of the amount reported",
    type: "DROPDOWN",
    options: roiCurrencyList,
  },
  {
    instruction:
      "Code to identify the ICT third-party service provider’s ultimate parent undertaking",
    name: "Identification code of the third party service providers ultimate parent undertaking",
    type: "Alphanumerical",
  },
  {
    instruction:
      "Identify the type of code to identify the ICT third-party service provider’s ultimate parent undertaking in b_05.01.0080 \n 1.	‘LEI’ for LEI \n 2.	‘Country Code’+Underscore+’Type of Code’ for non LEI code \n Country Code: Identify the ISO 3166–1 alpha–2 code of the country of issuance of the other code to identify the ICT third-party service provider \n Type of Code: \n 1.	CRN for Corporate registration number \n 2.	VAT for VAT number \n 3.	PNR for Passport Number \n 4.	NIN for National Identity Number",
    name: "Type of code of the third party service providers ultimate parent undertaking",
    type: "Alphanumerical",
  },
];
