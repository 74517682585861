import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Switch,
  Typography,
} from "@material-ui/core";
import React from "react";
import CustomButton from "../../../UI/button/button";
import { Edit } from "@material-ui/icons";
import { useSelector } from "react-redux";

function VendorBackground({ classes }) {
  const { vendorBasicDetails } = useSelector((state) => state?.brmData);

  return (
    <CardContent
      style={{ display: "flex", flexDirection: "column", gap: "5px" }}
    >
      <Typography variant="body2">
        <strong>Vendor Name:</strong> {vendorBasicDetails?.name}
      </Typography>
      <Typography variant="body2">
        <strong>Service Provided:</strong>{" "}
        {vendorBasicDetails?.sector?.industry?.industry}
      </Typography>
      {/* <Typography variant="body2">
          <strong>Service Provided:</strong> Payment gateway, point-of-sale
          (POS) solutions, and merchant acquiring services
        </Typography> */}
      <Typography variant="body2">
        <strong>Headquarters:</strong> {vendorBasicDetails?.address?.state},{" "}
        {vendorBasicDetails?.address?.country}
      </Typography>
      <Typography variant="body2">
        <strong>Operational Regions:</strong>{" "}
        {vendorBasicDetails?.address?.state},{" "}
        {vendorBasicDetails?.address?.country}
      </Typography>
      <Typography variant="body2">
        <strong>Engagement Start Date:</strong> 01-Dec-2022
      </Typography>
      <Typography variant="body2">
        <strong>Department Engaged:</strong>{" "}
        {vendorBasicDetails?.sector?.sector?.sector}
      </Typography>
      <Typography variant="body2">
        <strong>Service Criticality:</strong> High (Payment processing and
        merchant services are integral to banking operations)
      </Typography>
      <Typography variant="body2">
        {vendorBasicDetails?.name} is a payment solutions provider headquartered
        in Noida, India, with a team of over{" "}
        {vendorBasicDetails?.employeesCount} employees. In addition to its
        operations in India, {vendorBasicDetails?.name} has expanded into the
        United States, the United Kingdom, Singapore, and Malaysia. The vendor
        serves the retail banking sector, delivering essential payment gateway
        services, POS solutions, and merchant acquiring services to clients
        across multiple regions.
      </Typography>
    </CardContent>
  );
}

export default VendorBackground;
