import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Icon from "@material-ui/core/Icon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Drawer from "@material-ui/core/Drawer";
import DrawerListItem from "../listItem/drawerListItem";
import "../../../styles/drawer.css";
import logo from "../../../images/logo.svg";
import { Link, useHistory, useParams } from "react-router-dom";
import DrawerUseStyles from "./drawerStyles";
import vector from "../../../images/vector.png";
import settings from "../../../images/settings.png";
import reportListing from "../../../images/reportListing.png";
import surveyListing from "../../../images/surveyListing.png";
import getStarted from "../../../images/getStarted.png";
import multichat from "../../../images/multichat.png";
import { BsFillBriefcaseFill } from "react-icons/bs";
import { PiListPlusFill } from "react-icons/pi";
import { TiHome } from "react-icons/ti";

//removed.
function NavCustomDrawer({
  isOpen,
  setIsOpen,
  logOut,
  token,
  redirect,
  setRedirectValue,
  login,
}) {
  const history = useHistory();
  const params = useParams();

  // console.log(login);

  const classes = DrawerUseStyles();
  const [open, setOpen] = React.useState(false);
  const [render, setRender] = useState(false);
  const handleDrawerToggle = () => {
    setOpen(!open);
    setIsOpen(!isOpen);
  };
  const [tnc, setTnc] = useState(false);
  const [selectedIndex, setSelectedId] = React.useState(null);
  const setSelectedIndex = (index) => {
    setSelectedId(index);
  };
  useEffect(() => {
    setRender(!render);
    setSelectedId(null);
  }, []);
  // const userType = 'RA';
  const userType = login?.user_type || localStorage.getItem("user_type");
  // console.log(userType);

  useEffect(() => {
    if (
      login?.isLoggedIn &&
      localStorage.getItem("lastPath") === "/vendor_survey"
    ) {
      history.push("/vendor_survey");
    } else if (
      login?.isLoggedIn &&
      localStorage.getItem("lastPath") === "/vendor"
    ) {
      history.push("/vendor");
    } else if (
      login?.isLoggedIn &&
      localStorage.getItem("lastPath") === "/vendor/:companyName"
    ) {
      history.push(`/vendor/${localStorage.getItem("companyName")}`);
    } else if (
      login?.isLoggedIn &&
      localStorage.getItem("lastPath") === "/general_settings"
    ) {
      history.push("/general_settings");
    } else if (
      login?.isLoggedIn &&
      localStorage.getItem("lastPath") === "/vendor_organiasation"
    ) {
      history.push("/vendor_organiasation");
    } else if (
      login?.isLoggedIn &&
      localStorage.getItem("lastPath") === "/vendor_roi"
    ) {
      history.push("/vendor_roi");
    }
  }, [history]);

  useEffect(() => {
    if (history.location.pathname === "/corporate_questions") {
      setSelectedId(0);
    }
    if (history.location.pathname === "/risk_evaluator") {
      setSelectedId(1);
    }
    if (history.location.pathname === "/risk_analysis") {
      setSelectedId(2);
    }
    if (history.location.pathname === "/risk_admin") {
      setSelectedId(3);
    }
    if (history.location.pathname === "/vendor") {
      setSelectedId(4);
    }
    if (history.location.pathname === "/vendor_admin") {
      setSelectedId(4);
    }
    if (history.location.pathname === "/survey_admin") {
      setSelectedId(4);
    }
    if (history.location.pathname === "/task") {
      setSelectedId(5);
    }

    if (history.location.pathname === "/general_settings") {
      setSelectedId(6);
    }

    if (history.location.pathname === "/vendor_organiasation") {
      setSelectedId(7);
    }

    if (history.location.pathname === "/vendor_roi") {
      setSelectedId(8);
    }
  }, [history.location.pathname]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      {!tnc && (
        <Drawer
          onClose={handleDrawerToggle}
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <List className={classes.head}>
            <ListItem className={classes.head}>
              <ListItemIcon>
                {open ? (
                  <IconButton
                    className={classes.iconButton}
                    onClick={handleDrawerToggle}
                  >
                    <Icon>close</Icon>
                  </IconButton>
                ) : (
                  <IconButton name="expandPanel" onClick={handleDrawerToggle}>
                    <Icon className={classes.iconButton}>menu</Icon>
                  </IconButton>
                )}
              </ListItemIcon>
            </ListItem>
          </List>
          <List>
            <ListItem>
              <ListItemIcon>
                {open &&
                  isOpen &&
                  login.selectedOrganization &&
                  login.selectedOrganization.domain_logo &&
                  login.selectedOrganization.domain_logo.after_login.logo && (
                    <div
                      style={
                        login.selectedOrganization &&
                        login.selectedOrganization.domain_logo.after_login.logo
                          ? login.selectedOrganization.domain_logo.after_login
                              .logo_styles
                          : JSON.parse(localStorage.getItem("logo_styles"))
                          ? JSON.parse(localStorage.getItem("logo_styles"))
                          : {}
                      }
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                        src={
                          login.selectedOrganization &&
                          login.selectedOrganization.domain_logo.after_login
                            .logo
                            ? login.selectedOrganization.domain_logo.after_login
                                .logo
                            : localStorage.getItem("logo") &&
                              localStorage.getItem("logo")
                        }
                        alt="logo"
                      />
                    </div>
                  )}
                {open &&
                  isOpen &&
                  login.selectedOrganization &&
                  login.selectedOrganization.domain_logo &&
                  !login.selectedOrganization.domain_logo.after_login.logo && (
                    <div>
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                        src={logo}
                        alt="logo"
                      />
                    </div>
                  )}
                {/* {open && isOpen && (
                <img src={localStorage.getItem('logo') && localStorage.getItem('logo')!== 'undefined' && localStorage.getItem('logo')!== 'null' ? localStorage.getItem('logo') : login.selectedOrganization && login.selectedOrganization.domain_logo.after_login.logo ? login.selectedOrganization.domain_logo.after_login.logo : logo } className={classes.img} alt="log" />
              )} */}
              </ListItemIcon>
            </ListItem>
          </List>
          <List className={classes.list2}>
            {userType === "corp" && (
              <Link to="/corporate_questions" className={classes.link}>
                <DrawerListItem
                  selected={selectedIndex === 0}
                  className={classes.listitems}
                  onClick={() => setSelectedIndex(0)}
                  button
                  data-tut="reactour__get_started"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <Icon
                        component={"img"}
                        fontSize="small"
                        src={getStarted}
                      ></Icon>
                    </>
                  </ListItemIcon>
                  <ListItemText secondary={"Get Started"} />
                </DrawerListItem>
              </Link>
            )}

            {userType === "RA" && (
              <Link to="/risk_admin" className={classes.link}>
                <DrawerListItem
                  selected={selectedIndex === 3}
                  className={classes.listitems}
                  onClick={() => setSelectedIndex(1)}
                  button
                  data-tut="reactour__report_listing"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <Icon
                        component={"img"}
                        fontSize="small"
                        src={vector}
                      ></Icon>
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="Dashboard" />
                </DrawerListItem>
              </Link>
            )}
            {userType === "RE" && (
              <>
                <Link to="/risk_evaluator" className={classes.link}>
                  <DrawerListItem
                    selected={selectedIndex === 1}
                    className={classes.listitems}
                    onClick={() => setSelectedIndex(1)}
                    button
                    data-tut="reactour__report_listing"
                  >
                    <ListItemIcon>
                      <>
                        {open && <Icon fontSize="small">chevron_right</Icon>}
                        <Icon
                          component={"img"}
                          fontSize="small"
                          src={surveyListing}
                        ></Icon>
                      </>
                    </ListItemIcon>
                    <ListItemText secondary="Dashboard" />
                  </DrawerListItem>
                </Link>
                <Link to="/risk_survey" className={classes.link}>
                  <DrawerListItem
                    selected={selectedIndex === 2}
                    className={classes.listitems}
                    onClick={() => setSelectedIndex(2)}
                    button
                    data-tut="reactour__report_listing"
                  >
                    <ListItemIcon>
                      <>
                        {open && <Icon fontSize="small">chevron_right</Icon>}
                        <Icon
                          component={"img"}
                          fontSize="small"
                          src={multichat}
                        ></Icon>
                      </>
                    </ListItemIcon>
                    <ListItemText secondary="Dashboard" />
                  </DrawerListItem>
                </Link>
              </>
            )}
            {userType === "RM" && (
              <Link to="/risk_analysis" className={classes.link}>
                <DrawerListItem
                  selected={selectedIndex === 2}
                  className={classes.listitems}
                  button
                  data-tut="reactour__survery_listing"
                  onClick={() => {
                    setSelectedIndex(2);
                    // if (redirect) {
                    //   setRedirectValue(false);
                    // }
                  }}
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <Icon
                        component={"img"}
                        fontSize="small"
                        src={surveyListing}
                      ></Icon>
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="Assessments" />
                </DrawerListItem>
              </Link>
            )}
            {(userType === "A" || userType === "SA") && (
              <Link to="/report_listing" className={classes.link}>
                <DrawerListItem
                  selected={selectedIndex === 3}
                  className={classes.listitems}
                  onClick={() => setSelectedIndex(3)}
                  button
                  data-tut="reactour__report_listing"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <Icon
                        component={"img"}
                        fontSize="small"
                        src={reportListing}
                      ></Icon>
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="Tasks" />
                </DrawerListItem>
              </Link>
            )}
            {userType === "SA" && (
              <Link to="/data_hub/survey" className={classes.link}>
                <DrawerListItem
                  selected={selectedIndex === 4}
                  className={classes.listitems}
                  onClick={() => setSelectedIndex(4)}
                  button
                  data-tut="reactour__report_listing"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <Icon
                        component={"img"}
                        fontSize="small"
                        src={vector}
                      ></Icon>
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="Data Hub" />
                </DrawerListItem>
              </Link>
            )}

            {userType === "BA" && (
              <Link to="/vendor_dashboard" className={classes.link}>
                <DrawerListItem
                  selected={selectedIndex === 3}
                  className={classes.listitems}
                  onClick={() => {
                    setSelectedIndex(3);
                    localStorage.setItem("lastPath", "/vendor_dashboard");
                  }}
                  button
                  data-tut="reactour__report_listing"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <TiHome size={20} />
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="Dashboard" />
                </DrawerListItem>
              </Link>
            )}
            {userType === "BA" && (
              <Link to="/vendor_organiasation" className={classes.link}>
                <DrawerListItem
                  selected={
                    selectedIndex === 7 ||
                    localStorage.getItem("lastPath") === "/vendor_organiasation"
                  }
                  className={classes.listitems}
                  onClick={() => {
                    setSelectedIndex(7);
                    localStorage.setItem("lastPath", "/vendor_organiasation");
                  }}
                  button
                  data-tut="reactour__report_listing"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <BsFillBriefcaseFill size={19} />
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="Vendor Organisation" />
                </DrawerListItem>
              </Link>
            )}
            {userType === "BA" && (
              <Link to="/vendor" className={classes.link}>
                <DrawerListItem
                  selected={
                    selectedIndex === 4 ||
                    localStorage.getItem("lastPath") === "/vendor" ||
                    localStorage.getItem("lastPath") === "/vendor/:companyName"
                  }
                  className={classes.listitems}
                  onClick={() => {
                    setSelectedIndex(4);
                    localStorage.setItem("lastPath", "/vendor");
                    localStorage.setItem(
                      "selectedTab",
                      "/vendor/basic_details"
                    );
                  }}
                  button
                  data-tut="reactour__report_listing"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <Icon
                        component={"img"}
                        fontSize="small"
                        src={surveyListing}
                      ></Icon>
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="Vendors" />
                </DrawerListItem>
              </Link>
            )}
            {userType === "BA" && (
              <Link to="/vendor_survey" className={classes.link}>
                <DrawerListItem
                  selected={
                    selectedIndex === 5 ||
                    localStorage.getItem("lastPath") === "/vendor_survey"
                  }
                  className={classes.listitems}
                  onClick={() => {
                    setSelectedIndex(5);
                    localStorage.setItem("lastPath", "/vendor_survey");
                  }}
                  button
                  data-tut="reactour__report_listing"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <Icon
                        component={"img"}
                        fontSize="small"
                        src={reportListing}
                      ></Icon>
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="Survey Creation" />
                </DrawerListItem>
              </Link>
            )}
            {(userType === "VA" || userType === "BSA") && (
              <>
                <Link
                  to={userType === "BSA" ? "/survey_admin" : "/vendor_admin"}
                  className={classes.link}
                >
                  <DrawerListItem
                    selected={selectedIndex === 4}
                    className={classes.listitems}
                    onClick={() =>
                      setSelectedIndex(4) || userType === "BSA"
                        ? localStorage.setItem("lastPath", "/survey_admin")
                        : localStorage.setItem("lastPath", "/vendor_admin")
                    }
                    button
                    data-tut="reactour__report_listing"
                  >
                    <ListItemIcon>
                      <>
                        {open && <Icon fontSize="small">chevron_right</Icon>}
                        <Icon
                          component={"img"}
                          fontSize="small"
                          src={surveyListing}
                        ></Icon>
                      </>
                    </ListItemIcon>
                    <ListItemText secondary="Survey" />
                  </DrawerListItem>
                </Link>

                <Link to="/task" className={classes.link}>
                  <DrawerListItem
                    selected={
                      selectedIndex === 5 ||
                      localStorage.getItem("lastPath") === "/task"
                    }
                    className={classes.listitems}
                    onClick={() =>
                      setSelectedIndex(5) ||
                      localStorage.setItem("lastPath", "/task")
                    }
                    button
                    data-tut="reactour__report_listing"
                  >
                    <ListItemIcon>
                      <>
                        {open && <Icon fontSize="small">chevron_right</Icon>}
                        <Icon
                          component={"img"}
                          fontSize="small"
                          src={reportListing}
                        ></Icon>
                      </>
                    </ListItemIcon>
                    <ListItemText secondary="Task" />
                  </DrawerListItem>
                </Link>
              </>
            )}

            {userType === "BG" && (
              <Link to="/task" className={classes.link}>
                <DrawerListItem
                  selected={
                    selectedIndex === 5 ||
                    localStorage.getItem("lastPath") === "/task"
                  }
                  className={classes.listitems}
                  onClick={() =>
                    setSelectedIndex(5) ||
                    localStorage.setItem("lastPath", "/vendor")
                  }
                  button
                  data-tut="reactour__report_listing"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <Icon
                        component={"img"}
                        fontSize="small"
                        src={reportListing}
                      ></Icon>
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="Task" />
                </DrawerListItem>
              </Link>
            )}

            {userType === "VG" && (
              <Link to="/task" className={classes.link}>
                <DrawerListItem
                  selected={
                    selectedIndex === 5 ||
                    localStorage.getItem("lastPath") === "/task"
                  }
                  className={classes.listitems}
                  onClick={() =>
                    setSelectedIndex(5) ||
                    localStorage.setItem("lastPath", "/task")
                  }
                  button
                  data-tut="reactour__report_listing"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <Icon
                        component={"img"}
                        fontSize="small"
                        src={reportListing}
                      ></Icon>
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="Task" />
                </DrawerListItem>
              </Link>
            )}

            {userType === "BA" && (
              <Link to="/vendor_roi" className={classes.link}>
                <DrawerListItem
                  selected={
                    selectedIndex === 8 ||
                    localStorage.getItem("lastPath") === "/vendor_roi"
                  }
                  className={classes.listitems}
                  onClick={() => {
                    setSelectedIndex(8);
                    localStorage.setItem("lastPath", "/vendor_roi");
                  }}
                  button
                  data-tut="reactour__report_listing"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <PiListPlusFill size={19} />
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="Vendor ROI" />
                </DrawerListItem>
              </Link>
            )}

            {
              <Link to="/general_settings" className={classes.link}>
                <DrawerListItem
                  selected={
                    selectedIndex === 6 ||
                    localStorage.getItem("lastPath") === "/general_settings"
                  }
                  className={classes.listitems}
                  onClick={() => {
                    setSelectedIndex(6);
                    localStorage.setItem("lastPath", "/general_settings");
                  }}
                  button
                  data-tut="reactour__general_settings"
                >
                  <ListItemIcon>
                    <>
                      {open && <Icon fontSize="small">chevron_right</Icon>}
                      <Icon
                        component={"img"}
                        fontSize="small"
                        src={settings}
                      ></Icon>
                    </>
                  </ListItemIcon>
                  <ListItemText secondary="General Settings" />
                </DrawerListItem>
              </Link>
            }

            {/* <Link to="/vendor" className={classes.link}>
              <DrawerListItem
                selected={selectedIndex === 5}
                className={classes.listitems}
                onClick={() => setSelectedIndex(5)}
                button
                data-tut="reactour__general_settings"
              >
                <ListItemIcon>
                  <>
                    {open && <Icon fontSize="small">chevron_right</Icon>}
                    <Icon
                      component={"img"}
                      fontSize="small"
                      src={settings}
                    ></Icon>
                  </>
                </ListItemIcon>
                <ListItemText secondary="Vendor page" />
              </DrawerListItem>
            </Link> */}
          </List>
          <Divider style={{ marginTop: "auto" }} />
          <List className={classes.list}>
            <Link to="/login" className={classes.link}>
              <DrawerListItem
                selected={selectedIndex === 7}
                className={classes.listitems}
                onClick={() => {
                  setSelectedId(null);
                  setIsOpen(false);
                  logOut(
                    token,
                    JSON.parse(localStorage.getItem("isDisclaimer"))
                  );
                }}
                button
                name="logoutBtn"
              >
                <ListItemIcon>
                  <>
                    {open && <Icon fontSize="small">chevron_right</Icon>}
                    <Icon fontSize="small">logout</Icon>
                  </>
                </ListItemIcon>
                <ListItemText secondary="Logout" />
              </DrawerListItem>
            </Link>
          </List>
        </Drawer>
      )}
    </div>
  );
}
export default React.memo(NavCustomDrawer);
