import { gql } from "@apollo/client";

export const GQL_GET_ROI_SECTIONS = gql`
  query ROISections($create: Boolean) {
    ROISections(create: $create) {
      _id
      title
    }
  }
`;
